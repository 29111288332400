<template>
  <div class="home">
    <LoaderComponent v-show="loading" />
    <HeaderView />
    <section class="order-page-main-sec agency-design-grid-right">
      <div class="order-list-tabs">
        <div class="links-tabs">
          <h2 class="text-heading">Place your Order</h2>
          <ul>
            <li>
              <router-link to="/"
                >1. Place Order <i class="fas fa-caret-right"></i>
              </router-link>
            </li>
            <li>
              <router-link
                to="/order-summary"
                class="router-link-active router-link-exact-active"
                >2. Payment
                <span class="bottom-bar"></span>
              </router-link>
            </li>
            <!-- <li>
        <router-link to="\">3. Confirmation</router-link>
      </li> -->
          </ul>
        </div>
      </div>

      <div class="row">
        <!-- Billing Detail and Payment Options Section -->
        <div
          class="col-md-8"
          v-if="
            userData.role == 'Agency' ||
            (userData.role == 'Associate' &&
              (userData.is_wallet == 1 ||
                userData.is_save_card == 1 ||
                userData.is_add_card == 1))
          "
        >
          <div class="payment-detail-box">
            <form>
              <b-row>
                <div
                  class="detail-box"
                  :class="{ active: !detailBox }"
                  v-if="
                    userData.role == 'Agency' ||
                    (userData.role == 'Associate' &&
                      (userData.is_save_card == 1 || userData.is_add_card == 1))
                  "
                >
                  <b-row>
                    <b-col md="12">
                      <div class="payment-heading">Billing Details</div>

                      <div class="form-group">
                        <b-form-input
                          id="input-1"
                          v-model="form.address"
                          type="text"
                          placeholder="Address*"
                          required
                        ></b-form-input>
                        <span
                          v-if="errors.address"
                          class="invalid-feedback"
                          style="display: block"
                          role="alert"
                        >
                          <strong
                            >{{
                              form.address.length > 0 ? "" : errors.address[0]
                            }}
                          </strong>
                        </span>
                      </div>
                    </b-col>
                    <b-col md="6">
                      <div class="form-group">
                        <b-form-select
                          id="input-3"
                          v-model="form.country"
                          @change="getStates($event)"
                          required
                        >
                          <option value="null" disabled hidden>
                            Select Country*
                          </option>
                          <option
                            v-for="(pd, k) in countries"
                            :key="k"
                            :value="pd.id"
                          >
                            {{ pd.name }}
                          </option>
                        </b-form-select>
                        <span
                          v-if="errors.country"
                          class="invalid-feedback"
                          style="display: block"
                          role="alert"
                        >
                          <strong>
                            {{
                              form.country != null ? "" : errors.country[0]
                            }}</strong
                          >
                        </span>
                      </div>
                    </b-col>
                    <b-col md="6">
                      <div class="form-group">
                        <b-form-select
                          id="input-3"
                          v-model="form.state"
                          required
                        >
                          <option value="null" disabled hidden>
                            Select States*
                          </option>
                          <option
                            v-for="(pd, k) in states"
                            :key="k"
                            :value="pd.id"
                          >
                            {{ pd.name }}
                          </option>
                        </b-form-select>
                        <span
                          v-if="errors.state"
                          class="invalid-feedback"
                          style="display: block"
                          role="alert"
                        >
                          <strong>
                            {{
                              form.state != null ? "" : errors.state[0]
                            }}</strong
                          >
                        </span>
                      </div>
                    </b-col>
                    <b-col md="6">
                      <div class="form-group">
                        <b-form-input
                          id="input-1"
                          v-model="form.city"
                          type="text"
                          placeholder="City*"
                          required
                        ></b-form-input>
                        <span
                          v-if="errors.city"
                          class="invalid-feedback"
                          style="display: block"
                          role="alert"
                        >
                          <strong>
                            {{
                              form.city.length > 0 ? "" : errors.city[0]
                            }}</strong
                          >
                        </span>
                      </div>
                    </b-col>
                    <b-col md="6">
                      <div class="form-group">
                        <b-form-input
                          id="input-1"
                          v-model="form.zipCode"
                          type="tel"
                          placeholder="Zip Code*"
                          required
                        ></b-form-input>
                        <span
                          v-if="errors.zip_code"
                          class="invalid-feedback"
                          style="display: block"
                          role="alert"
                        >
                          <strong>
                            {{
                              form.zipCode.length > 0 ? "" : errors.zip_code[0]
                            }}</strong
                          >
                        </span>
                      </div>
                    </b-col>
                    <b-col md="12" v-if="method == 'new_card'">
                      <div class="check-box-text">
                        <b-form-checkbox
                          id="checkbox-2"
                          v-model="useProfileAddressChecked"
                          name="checkbox-2"
                          @change="getProfileAddress"
                        >
                          Use Profile Address
                        </b-form-checkbox>
                      </div>
                    </b-col>
                  </b-row>
                </div>
                <b-col md="12">
                  <div class="method-heading">Payment Method</div>
                  <div class="card-list">
                    <ul>
                      <li
                        v-if="
                          userData.role == 'Agency' ||
                          (userData.role == 'Associate' &&
                            (userData.is_save_card == 1 ||
                              userData.is_add_card == 1))
                        "
                      >
                        <input
                          type="radio"
                          id="1"
                          name="radio-group"
                          checked
                          v-model="paymentOption"
                          value="card"
                          @click="cardListFunction(1)"
                          :class="{ active: cardText == 1 }"
                        />
                        <label
                          for="1"
                          @click="cardListFunction(1)"
                          :class="{ active: cardText == 1 }"
                          ><img src="@/assets/common/images/cards.png"
                        /></label>
                      </li>

                      <!-- <li>
                      <span class="save-text">Save 1%</span>
                      <input type="radio" id="2" name="radio-group" />
                      <label for="2"
                        ><img src="@/assets/common/images/ACH.png"
                      /></label>
                    </li> -->
                      <li
                        v-if="
                          userData.role == 'Agency' ||
                          (userData.role == 'Associate' &&
                            userData.is_wallet == 1)
                        "
                      >
                        <input
                          type="radio"
                          id="3"
                          name="radio-group"
                          v-model="paymentOption"
                          value="balance"
                          @click="cardListFunction(3)"
                          :class="{ active: cardText == 3 }"
                        />
                        <label
                          for="3"
                          @click="cardListFunction(3)"
                          :class="{ active: cardText == 3 }"
                          ><img src="@/assets/common/images/wallet.png" />
                          <span>Wallet</span></label
                        >
                      </li>
                      <li v-if="userData.role == 'Agency'">
                        <input
                          type="radio"
                          id="4"
                          name="radio-group"
                          v-model="paymentOption"
                          value="paypal"
                          @click="cardListFunction(4)"
                          :class="{ active: cardText == 4 }"
                        />
                        <label
                          for="4"
                          @click="cardListFunction(4)"
                          :class="{ active: cardText == 4 }"
                          ><img src="@/assets/common/images/pp-logo-150px.png"
                        /></label>
                      </li>
                    </ul>
                  </div>
                </b-col>
                <b-col md="12">
                  <div class="balance-box-sec" :class="{ active: walletBox }">
                    <div class="available-balance-text">
                      <div class="text-1">
                        Available Balance:
                        <b>{{ numberFormat(userData.balance, "currency") }}</b>
                      </div>
                      <div
                        class="addBalance-box"
                        v-if="userData.role == 'Agency' && showAddBalanceDiv"
                      >
                        Insufficient Balance.
                        <span v-b-modal.modal-box-4>Add Balance</span>
                      </div>
                    </div>
                  </div>
                  <div
                    class="balance-box-sec balance-btn-box"
                    :class="{ active: walletBox }"
                    v-if="!showAddBalanceDiv"
                  >
                    <button type="button" class="btn-1" @click="addOrder()">
                      Use Balance And Checkout
                    </button>
                  </div>
                </b-col>
                <b-col md="12 mt-3">
                  <div class="paypal-box-sec" :class="{ active: !payPalBox }">
                    <div class="detail-box" :class="{ active: detailBox }">
                      <div
                        id="paypal-button-container"
                        :class="{ active: paypalShow == true }"
                      ></div>
                    </div>
                  </div>
                  <div class="paypal-box-sec" :class="{ active: payPalBox }">
                    <div class="detail-box" :class="{ active: !detailBox }">
                      <b-row>
                        <b-col md="12">
                          <div
                            class="save-card-btns"
                            v-if="
                              userData.role == 'Agency' ||
                              (userData.role == 'Associate' &&
                                (userData.is_save_card == 1 ||
                                  userData.is_add_card == 1))
                            "
                          >
                            <div class="toggle-box">
                              <div
                                class="card-text"
                                @click="cardFunction(1)"
                                :class="{ active: cardText == 1 }"
                                v-if="
                                  userData.role == 'Agency' ||
                                  (userData.role == 'Associate' &&
                                    userData.is_add_card == 1)
                                "
                              >
                                New Card
                              </div>
                              <div
                                class="card-text"
                                @click="cardFunction(2)"
                                :class="{ active: cardText == 2 }"
                                v-if="
                                  userData.role == 'Agency' ||
                                  (userData.role == 'Associate' &&
                                    userData.is_save_card == 1)
                                "
                              >
                                Saved Card
                              </div>
                            </div>
                            <div
                              class="check-box-text"
                              @click="cardFunction(1)"
                              :class="{ active: cardText == 1 }"
                              v-if="
                                userData.role == 'Agency' ||
                                (userData.role == 'Associate' &&
                                  userData.is_add_card == 1)
                              "
                            >
                              <!-- <b-form-checkbox id="checkbox-1" v-model="saveCheck" name="checkbox-1" value="save_card"
                                checked="save_card" unchecked="no">
                                <span> Save this card </span>
                              </b-form-checkbox> -->
                              <b-form-checkbox
                                v-if="userData.role == 'Agency'"
                                id="checkbox-1"
                                v-model="saveCheck"
                                name="checkbox-1"
                                value="save_card"
                                unchecked-value="no"
                              >
                                Save this card
                              </b-form-checkbox>
                            </div>
                          </div>
                          <span
                            v-if="errors.card"
                            class="invalid-feedback"
                            style="display: block"
                            role="alert"
                          >
                            <strong> {{ errors.card[0] }}</strong>
                          </span>
                        </b-col>
                        <b-col
                          md="12"
                          v-if="
                            userData.role == 'Agency' ||
                            (userData.role == 'Associate' &&
                              (userData.is_add_card == 1 ||
                                userData.is_save_card == 1))
                          "
                        >
                          <div
                            class="card-sec-1"
                            @click="cardFunction(1)"
                            :class="{ active: cardText == 1 }"
                            v-if="
                              userData.role == 'Agency' ||
                              (userData.role == 'Associate' &&
                                userData.is_add_card == 1)
                            "
                          >
                            <b-row class="card-field-1">
                              <b-col md="1" class="card-icon">
                                <i class="fa fa-credit-card"></i>
                              </b-col>
                              <b-col md="9">
                                <div class="form-group">
                                  <b-form-input
                                    id="input-1"
                                    v-model="form.cardNumber"
                                    type="text"
                                    maxlength="19"
                                    placeholder="Card number"
                                    required
                                  ></b-form-input>
                                </div>
                              </b-col>
                              <b-col md="2">
                                <div class="form-group">
                                  <b-form-input
                                    id="input-1"
                                    v-model="form.cardMM"
                                    type="text"
                                    maxlength="2"
                                    placeholder="MM /"
                                    @keypress="filterNumeric"
                                    required
                                  ></b-form-input>
                                  <!-- <span
                                    v-if="errors.expiration_month"
                                    class="invalid-feedback"
                                    style="display: block"
                                    role="alert"
                                  >
                                    <strong>{{
                                      errors.expiration_month[0]
                                    }}</strong>
                                  </span> -->
                                </div>
                                <div class="form-group">
                                  <b-form-input
                                    id="input-1"
                                    v-model="form.cardYY"
                                    type="text"
                                    maxlength="2"
                                    placeholder="YY /"
                                    @keypress="filterNumeric"
                                    required
                                  ></b-form-input>
                                  <!-- <span
                                    v-if="errors.expiration_year"
                                    class="invalid-feedback"
                                    style="display: block"
                                    role="alert"
                                  >
                                    <strong>{{
                                      errors.expiration_year[0]
                                    }}</strong>
                                  </span> -->
                                </div>
                                <div class="form-group">
                                  <b-form-input
                                    id="input-1"
                                    v-model="form.cardCVC"
                                    type="text"
                                    maxlength="4"
                                    placeholder="CVV"
                                    @keypress="filterNumeric"
                                    required
                                  ></b-form-input>
                                  <!-- <span
                                    v-if="errors.cvv"
                                    class="invalid-feedback"
                                    style="display: block"
                                    role="alert"
                                  >
                                    <strong>{{ errors.cvv[0] }}</strong>
                                  </span> -->
                                </div>
                              </b-col>
                            </b-row>
                            <b-col md="12" v-if="errors">
                              <b-row>
                                <b-col md="9">
                                  <span
                                    v-if="errors.card_number"
                                    class="invalid-feedback"
                                    style="display: block"
                                    role="alert"
                                  >
                                    <strong>
                                      {{ errors.card_number[0] }}
                                    </strong>
                                  </span>
                                </b-col>
                                <b-col md="3">
                                  <span
                                    v-if="errors.expiration_month"
                                    class="invalid-feedback"
                                    style="display: block"
                                    role="alert"
                                  >
                                    <strong>
                                      {{ errors.expiration_month[0] }}
                                    </strong>
                                  </span>
                                  <span
                                    v-if="errors.expiration_year"
                                    class="invalid-feedback"
                                    style="display: block"
                                    role="alert"
                                  >
                                    <strong>
                                      {{ errors.expiration_year[0] }}
                                    </strong>
                                  </span>
                                  <span
                                    v-if="errors.cvv"
                                    class="invalid-feedback"
                                    style="display: block"
                                    role="alert"
                                  >
                                    <strong>
                                      {{ errors.cvv[0] }}
                                    </strong>
                                  </span>
                                </b-col>
                              </b-row>
                            </b-col>
                            <b-col md="12" class="card-field-2">
                              <b-row>
                                <b-col md="6">
                                  <div class="form-group">
                                    <b-form-input
                                      id="input-1"
                                      v-model="form.first_name"
                                      type="text"
                                      placeholder="First Name"
                                      required
                                    ></b-form-input>
                                    <span
                                      v-if="errors.first_name"
                                      class="invalid-feedback"
                                      style="display: block"
                                      role="alert"
                                    >
                                      <strong>
                                        {{ errors.first_name[0] }}
                                      </strong>
                                    </span>
                                  </div>
                                </b-col>
                                <b-col md="6">
                                  <div class="form-group">
                                    <b-form-input
                                      id="input-1"
                                      v-model="form.last_name"
                                      type="text"
                                      placeholder="Last Name"
                                      required
                                    ></b-form-input>
                                    <span
                                      v-if="errors.last_name"
                                      class="invalid-feedback"
                                      style="display: block"
                                      role="alert"
                                    >
                                      <strong>
                                        {{ errors.last_name[0] }}
                                      </strong>
                                    </span>
                                  </div>
                                </b-col>
                              </b-row>
                            </b-col>

                            <button
                              type="button"
                              class="btn-1"
                              @click="addOrder()"
                            >
                              <i class="fas fa-lock"></i> Pay with Credit Card
                            </button>
                          </div>
                          <div
                            class="card-sec-1"
                            :class="{ active: cardText == 2 }"
                            v-if="
                              userData.role == 'Agency' ||
                              (userData.role == 'Associate' &&
                                userData.is_save_card == 1)
                            "
                          >
                            <b-row>
                              <b-col md="12">
                                <div class="save-card-box">
                                  <template
                                    v-for="existingCard in existingCardList"
                                    :key="existingCard.id"
                                  >
                                    <div
                                      class="card-1"
                                      @click="
                                        getSelectedCardDetail(existingCard.id)
                                      "
                                    >
                                      <input
                                        type="radio"
                                        :id="'test' + existingCard.id"
                                        v-model="card"
                                        name="chip-group"
                                        :value="existingCard.id"
                                      />
                                      <label :for="'test' + existingCard.id">
                                        <img
                                          src="@/assets/common/images/chip.svg"
                                        />
                                        <div class="stars">
                                          <i class="fas fa-star-of-life"></i>
                                          <i class="fas fa-star-of-life"></i>
                                          <i class="fas fa-star-of-life"></i>
                                          <i
                                            class="fas fa-star-of-life mr-2"
                                          ></i>
                                          <i class="fas fa-star-of-life"></i>
                                          <i class="fas fa-star-of-life"></i>
                                          <i class="fas fa-star-of-life"></i>
                                          <i
                                            class="fas fa-star-of-life mr-2"
                                          ></i>
                                          <i class="fas fa-star-of-life"></i>
                                          <i class="fas fa-star-of-life"></i>
                                          <i class="fas fa-star-of-life"></i>
                                          <i
                                            class="fas fa-star-of-life mr-2"
                                          ></i>
                                          {{ existingCard.last4 }}
                                        </div>
                                      </label>
                                    </div>
                                  </template>
                                  <!-- <div class="card-1">
                                    <input
                                      type="radio"
                                      id="test2"
                                      name="chip-group"
                                    />
                                    <label for="test2">
                                      <img
                                        src="@/assets/common/images/chip.svg"
                                      />
                                      <div class="stars">
                                        <i class="fas fa-star-of-life"></i>
                                        <i class="fas fa-star-of-life"></i>
                                        <i class="fas fa-star-of-life"></i>
                                        <i class="fas fa-star-of-life"></i>
                                        <i class="fas fa-star-of-life"></i>
                                        <i class="fas fa-star-of-life"></i>
                                      </div>
                                    </label>
                                  </div> -->
                                </div>
                              </b-col>
                            </b-row>

                            <button
                              type="button"
                              class="btn-1"
                              @click="addOrder()"
                            >
                              <i class="fas fa-lock"></i> Pay with Credit Card
                            </button>
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </form>
          </div>
        </div>

        <!-- Order Summary Section -->
        <div
          class="col-md-4"
          v-if="
            userData.role == 'Agency' ||
            (userData.role == 'Associate' &&
              (userData.is_wallet == 1 ||
                userData.is_save_card == 1 ||
                userData.is_add_card == 1))
          "
        >
          <div class="total-summary-right-box">
            <div class="total-price-table-box">
              <div class="text-heading">Order Summary</div>

              <!-- Missed Opportunity Order Summary  -->
              <div class="summary-box" v-if="opportunityOrder">
                <table style="width: 100%" >
                  <tr class="bg-text">
                    <td colspan="2"></td>
                    <td>Qty</td>
                    <td>Price</td>
                  </tr>
                  <tr v-for="(summaryData) in summaryPaymentData"
                      :key="summaryData.placement_url">
                    <td colspan="2"><span>{{ summaryData.placement_url }}</span></td>
                    <td>{{ summaryData.total_links }}</td>
                    <td>
                      {{ numberFormat(summaryData.total_cost, "currency") }}
                    </td>
                  </tr>
                  <!-- <tr>
                    <td colspan="2">Addons:</td>
                    <td>{{ addon_count }}</td>
                    <td>
                      {{ numberFormat(addon_total, "currency") }}
                    </td>
                  </tr> -->
                  <tr>
                    <td colspan="2">Subtotal:</td>
                    <td colspan="2">
                      USD
                      {{ numberFormat(sub_total, "currency") }}
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">Discount:</td>
                    <td colspan="2">
                      (USD
                      {{ numberFormat(discount, "currency") }})
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">Total:</td>
                    <td colspan="2">
                      <b>USD {{ numberFormat(order_total, "currency") }}</b>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="4" class="summary-btn" @click="openSidebar">
                      View Summary <i class="fas fa-arrow-right"></i>
                    </td>
                  </tr>
                </table>
              </div>

              <!-- Normal Order Summary  -->
              <div class="summary-box" v-else>
                <table style="width: 100%">
                  <tr class="bg-text">
                    <td colspan="2"></td>
                    <td>Qty</td>
                    <td>Price</td>
                  </tr>
                  <tr v-if="da_20_count > 0 && !packageOrder">
                    <td colspan="2">DA 20 or Below</td>
                    <td>{{ da_20_count }}</td>
                    <td>
                      {{ numberFormat(da_20_total, "currency") }}
                    </td>
                  </tr>
                  <tr v-if="da_30_count > 0 && !packageOrder">
                    <td colspan="2">DA 30+</td>
                    <td>{{ da_30_count }}</td>
                    <td>
                      {{ numberFormat(da_30_total, "currency") }}
                    </td>
                  </tr>
                  <tr v-if="da_40_count > 0 && !packageOrder">
                    <td colspan="2">DA 40+</td>
                    <td>{{ da_40_count }}</td>
                    <td>
                      {{ numberFormat(da_40_total, "currency") }}
                    </td>
                  </tr>
                  <tr v-if="da_50_count > 0 && !packageOrder">
                    <td colspan="2">DA 50+</td>
                    <td>{{ da_50_count }}</td>
                    <td>
                      {{ numberFormat(da_50_total, "currency") }}
                    </td>
                  </tr>
                  <tr v-if="da_60_count > 0 && !packageOrder">
                    <td colspan="2">DA 60+</td>
                    <td>{{ da_60_count }}</td>
                    <td>
                      {{ numberFormat(da_60_total, "currency") }}
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">Total Links:</td>
                    <td>{{ links.length }}</td>
                    <td>
                      {{ numberFormat(link_total, "currency") }}
                    </td>
                  </tr>
                  <tr v-if="!packageOrder">
                    <td colspan="2">Addons:</td>
                    <td>{{ addon_count }}</td>
                    <td>
                      {{ numberFormat(addon_total, "currency") }}
                    </td>
                  </tr>
                  <tr v-if="!packageOrder">
                    <td colspan="2">Subtotal:</td>
                    <td colspan="2">
                      USD
                      {{ numberFormat(sub_total, "currency") }}
                    </td>
                  </tr>
                  <tr v-if="!packageOrder">
                    <td colspan="2">Discount:</td>
                    <td colspan="2">
                      (USD
                      {{ numberFormat(discount, "currency") }})
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">Total:</td>
                    <td colspan="2">
                      <b>USD {{ numberFormat(order_total, "currency") }}</b>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="4" class="summary-btn" @click="openSidebar">
                      View Summary <i class="fas fa-arrow-right"></i>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="copone-code-box" v-if="!packageOrder">
              <b-form-input
                id="input-1"
                v-model="coupon"
                type="text"
                placeholder="Coupon code"
                required
              ></b-form-input>
              <button @click="applyCoupon">Apply</button>
            </div>
            <div v-if="!packageOrder">
              <span
                v-if="errors.coupon"
                class="invalid-feedback"
                style="display: block"
                role="alert"
              >
                <strong>{{ errors.coupon }}</strong>
              </span>
            </div>
            <div class="secure-icon">
              <ul>
                <li>
                  <div class="icon-box"><i class="fas fa-lock"></i></div>
                  <div class="text-box">
                    <div class="text-1">Secure</div>
                    <div class="text-2">Checkout</div>
                  </div>
                </li>
                <li>
                  <div class="icon-box"><i class="fas fa-shield-alt"></i></div>
                  <div class="text-box">
                    <div class="text-1">Privacy</div>
                    <div class="text-2">Guarantee</div>
                  </div>
                </li>
              </ul>
            </div>
            <div
              class="total-summary-sideabar"
              :class="{ opensideBar: isSidebarOpen }"
            >
              <div class="order-summary-box">
                <div class="top-bar">
                  <div class="text-heading"></div>
                  <div class="icon-box">
                    <i class="fas fa-times" @click="closeSidebar"></i>
                  </div>
                </div>

                <div class="table-responsive">
                  <table class="addon-table">
                    <tr class="editor-article-field-1 order-links-text">
                      <th colspan="2">
                        <span>Order Title:</span>
                        {{ summary.order_title }}
                      </th>
                      <th v-if="packageOrder" colspan="2">
                        <span>Package:</span>
                        {{ packageName }}
                      </th>
                    </tr>
                    <tr v-if="packageOrder" class="editor-article-field-1 order-links-text">
                      <th colspan="2">
                        <span>Product:</span>
                        {{ this.links[0].product.name }}
                      </th>
                      <th v-if="packageOrder" colspan="2">
                        <span>Category:</span>
                        {{ this.links[0].primary_category.name }}
                      </th>
                    </tr>
                    <template v-if="packageOrder">
                      <tr class="editor-article-field-1">
                        <th>#</th>
                        <th>Anchor Text</th>
                        <th>Destination URL</th>
                      </tr>
                      <tr v-for="(link, key) in links" :key="link.id">
                        <td>{{ (key + 1) }}</td>
                        <td><span class="link-text">{{ this.links[key].text }}</span></td>
                        <td><span class="link-text">{{ this.links[key].url }}</span></td>
                      </tr>
                    </template>
                    <template v-else>
                      <tr class="editor-article-field-1">
                        <th v-if="opportunityOrder">Placement URL</th>
                        <th>DA</th>
                        <th>Category</th>
                        <th v-if="opportunityOrder">
                          Traffic
                        </th>
                        <th v-else>
                          <span>Addon</span>
                          Minimum Traffic
                        </th>
                        <th>
                          <span>Addon</span>
                          Content Approval
                        </th>
                        <th>Price</th>
                      </tr>
                      <tr v-for="(link, key) in links" :key="link.id">
                        <td v-if="opportunityOrder"><span class="link-text">{{ this.links[key].placement_url }}</span></td>
                      <td>{{ this.links[key].product.name }}</td>
                        <td>{{ this.links[key].primary_category.name }}</td>
                        <template v-if="opportunityOrder">
                          <td>{{ Math.floor(this.links[key].traffic).toLocaleString() }}</td>
                          <td>{{ this.links[key].addons.length > 0 ? 'Yes ($' + this.links[key].addons[0].cost + ')' : 'None' }}</td>
                        </template>
                        <template v-else>
                          <template v-if="this.links[key].addons.length">
                            <template v-if="this.links[key].addons.length == 2">
                              <td>
                                {{ this.links[key].addons[0].name }} (${{
                                  this.links[key].addons[0].cost
                                }})
                              </td>
                              <td>Yes (${{ this.links[key].addons[1].cost }})</td>
                            </template>
                            <template v-else>
                              <template
                                v-if="
                                  this.links[key].addons[0].addon_name ==
                                  'Minimum Traffic'
                                "
                              >
                                <td>
                                  {{ this.links[key].addons[0].name }} (${{
                                    this.links[key].addons[0].cost
                                  }})
                                </td>
                                <td>None</td>
                              </template>
                              <template
                                v-if="this.links[key].addons[0].addon_name ==
                                  'Content Approval'
                                "
                              >
                                <td>None</td>
                                <td>Yes (${{ this.links[key].addons[0].cost }})</td>
                              </template>
                            </template>
                          </template>
                          <template v-else>
                            <td>None</td>
                            <td>None</td>
                          </template>
                        </template>
                        <td>
                          {{ numberFormat(this.links[key].total, "currency") }}
                        </td>
                      </tr>
                    </template>
                  </table>
                </div>

                <!-- Missed Opportunity Side View Order Summary -->
                <div class="proceed-box" v-if="opportunityOrder">
                  <div class="total-price-table-box proceed-pay-box">
                    <table style="width: 100%">
                      <tr class="bg-text">
                        <td colspan="2"></td>
                        <td>Qty</td>
                        <td>Price</td>
                      </tr>
                      <tr v-for="(summaryData) in summaryPaymentData"
                      :key="summaryData.placement_url">
                        <td colspan="2"><span class="link-text">{{ summaryData.placement_url }}</span></td>
                        <td>{{ summaryData.total_links }}</td>
                        <td>
                          {{ numberFormat(summaryData.total_cost, "currency") }}
                        </td>
                      </tr>
                      <!-- <tr>
                        <td colspan="2">Addons:</td>
                        <td>{{ addon_count }}</td>
                        <td>
                          {{ numberFormat(addon_total, "currency") }}
                        </td>
                      </tr> -->
                      <tr>
                        <td colspan="2">Subtotal:</td>
                        <td colspan="2">
                          USD
                          {{ numberFormat(sub_total, "currency") }}
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2">Discount:</td>
                        <td colspan="2">
                          (USD
                          {{ numberFormat(discount, "currency") }})
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2">Total:</td>
                        <td colspan="2">
                          <b>USD {{ numberFormat(order_total, "currency") }}</b>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="4" style="padding: 0">
                          <a href="#" class="btn-3" @click="closeSidebar"
                            >Proceed to Pay</a
                          >
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>

                <!-- Package Order Side View Order Summary -->
                <div class="proceed-box" v-else-if="packageOrder">
                  <div class="total-price-table-box proceed-pay-box">
                    <table style="width: 100%">
                      <tr class="bg-text">
                        <td colspan="2"></td>
                        <td>Qty</td>
                        <td>Price</td>
                      </tr>
                      <tr>
                        <td colspan="2">Total Links:</td>
                        <td>{{ links.length }}</td>
                        <td>
                          {{ numberFormat(link_total, "currency") }}
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2">Total:</td>
                        <td colspan="2">
                          <b>USD {{ numberFormat(order_total, "currency") }}</b>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="4" style="padding: 0">
                          <a href="#" class="btn-3" @click="closeSidebar"
                            >Proceed to Pay</a
                          >
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>

                <!-- Normal Side View Order Summary -->
                <div class="proceed-box" v-else>
                  <div class="total-price-table-box proceed-pay-box">
                    <table style="width: 100%">
                      <tr class="bg-text">
                        <td colspan="2"></td>
                        <td>Qty</td>
                        <td>Price</td>
                      </tr>
                      <tr v-if="da_20_count > 0">
                        <td colspan="2">DA 20 or Below</td>
                        <td>{{ da_20_count }}</td>
                        <td>
                          {{ numberFormat(da_20_total, "currency") }}
                        </td>
                      </tr>
                      <tr v-if="da_30_count > 0">
                        <td colspan="2">DA 30+</td>
                        <td>{{ da_30_count }}</td>
                        <td>
                          {{ numberFormat(da_30_total, "currency") }}
                        </td>
                      </tr>
                      <tr v-if="da_40_count > 0">
                        <td colspan="2">DA 40+</td>
                        <td>{{ da_40_count }}</td>
                        <td>
                          {{ numberFormat(da_40_total, "currency") }}
                        </td>
                      </tr>
                      <tr v-if="da_50_count > 0">
                        <td colspan="2">DA 50+</td>
                        <td>{{ da_50_count }}</td>
                        <td>
                          {{ numberFormat(da_50_total, "currency") }}
                        </td>
                      </tr>
                      <tr v-if="da_60_count > 0">
                        <td colspan="2">DA 60+</td>
                        <td>{{ da_60_count }}</td>
                        <td>
                          {{ numberFormat(da_60_total, "currency") }}
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2">Total Links:</td>
                        <td>{{ links.length }}</td>
                        <td>
                          {{ numberFormat(link_total, "currency") }}
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2">Addons:</td>
                        <td>{{ addon_count }}</td>
                        <td>
                          {{ numberFormat(addon_total, "currency") }}
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2">Subtotal:</td>
                        <td colspan="2">
                          USD
                          {{ numberFormat(sub_total, "currency") }}
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2">Discount:</td>
                        <td colspan="2">
                          (USD
                          {{ numberFormat(discount, "currency") }})
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2">Total:</td>
                        <td colspan="2">
                          <b>USD {{ numberFormat(order_total, "currency") }}</b>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="4" style="padding: 0">
                          <a href="#" class="btn-3" @click="closeSidebar"
                            >Proceed to Pay</a
                          >
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="associate-error-message" v-else>
          You are not authorized to place an order. Please contact your agency
          to get the access.
        </div>
      </div>

      <div class="modal-list">
        <b-modal
          id="modal-box-4"
          centered
          title="Add Balance"
          :no-close-on-backdrop="true"
          v-if="userData.role == 'Agency'"
        >
          <AddBalanceComponent
            :countries="countries"
            :existingCards="existingCardList"
          />
        </b-modal>
      </div>
    </section>
  </div>
</template>

<style></style>

<script>
import { useToast } from "vue-toastification";
const toast = useToast();
import XLSX from "xlsx";
import swal from "sweetalert2";
import HeaderView from "@/components/layout/HeaderComponent.vue";
import LoaderComponent from "@/components/LoaderComponent.vue";
import url from "@/prod.js";
import { numberFormat } from "@/prod.js";
import LZString from "lz-string";
import AddBalanceComponent from "@/components/AddBalanceComponent.vue";

export default {
  name: "PaymentView",

  components: {
    HeaderView,
    LoaderComponent,
    AddBalanceComponent,
  },
  mounted() {
    if (
      !localStorage.getItem("order-total") ||
      localStorage.getItem("order-total") == null
    ) {
      this.$router.push("/product");
    } else {
      this.loadScript();
      if (
        this.userData.role == "Agency" ||
        (this.userData.role == "Associate" && this.userData.is_save_card == 1)
      ) {
        this.getExistingCards();
      }
      this.getItems();
      this.getCountries();
      if (
        this.userData.role == "Associate" &&
        this.userData.is_add_card !== 1 &&
        this.userData.is_save_card !== 1
      ) {
        this.paymentOption = "balance";
        this.cardText = 3;
        this.cardListFunction(3);
      }

      this.showAddBalance();
    }
  },

  data() {
    return {
      numberFormat,
      LZString,
      appStatus: url.appStatus,
      loading: false,
      selected: [], // Must be an array reference!
      options: [
        { text: "Orange", value: "orange" },
        { text: "Apple", value: "apple" },
        { text: "Pineapple", value: "pineapple" },
        { text: "Grape", value: "grape" },
      ],
      paypal: {
        sandbox: url.paypalKey,
        production: "",
      },
      counter: 0,
      decisions: ["DA", "DR"],
      cardText: 1,
      detailBox: false,
      walletBox: false,
      payPalBox: false,
      detailBoxBalance: true,
      saveCheck: "no",
      existingCard: "no",
      card: "",
      isSidebarOpen: false,
      isSidebarClose: true,
      links: [],
      method: "new_card",
      rawFile: null,
      workbook: null,
      form: {
        id: "",
        Drdown: false,
        picked: "",
        address: "",
        cardNumber: "",
        cardMM: "",
        cardYY: "",
        cardCVC: "",
        amountAdd: "",
        couponCode: "",
        first_name: "",
        last_name: "",
        country: null,
        state: null,
        city: "",
        zipCode: "",
      },
      summary: {
        order_title: "",
      },
      link_total: 0,
      addon_count: 0,
      addon_total: 0,
      order_total: 0,
      sub_total: 0,
      discount: 0,
      discount_perc: 0,
      da_20_count: 0,
      da_20_total: 0,
      da_30_count: 0,
      da_30_total: 0,
      da_40_count: 0,
      da_40_total: 0,
      da_50_count: 0,
      da_50_total: 0,
      da_60_count: 0,
      da_60_total: 0,
      countries: [],
      states: [],
      cities: [],
      payment_id: "",
      paypalShow: false,
      errors: [],
      existingCardList: [],
      draft_order_id: null,
      showAddBalanceDiv: false,
      coupon: "",
      paymentOption: "card",
      useProfileAddressChecked: false,
      orderMedium: "direct",
      orderSource: "direct",
      opportunity_ids: [],
      opportunityOrder: false,
      summaryPaymentData: [],
      packageOrder: false,
      packageName: '',
      packageId: '',
      packagePrice: 0,
      packageLinks: 0,
    };
  },

  methods: {
    filterNumeric(event) {
      const keysAllowed = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
      const keyPressed = event.key;

      if (!keysAllowed.includes(keyPressed)) {
        event.preventDefault();
      }
    },
    getItems() {
      this.draft_order_id = localStorage.getItem("draft-order-id");
      this.summary.order_title = localStorage.getItem("order-title");
      this.links = JSON.parse(
        LZString.decompress(localStorage.getItem("links"))
      );
      console.log(this.links);
      this.da_20_count = localStorage.getItem("da_20_count");
      this.da_20_total = localStorage.getItem("da_20_total");
      this.da_30_count = localStorage.getItem("da_30_count");
      this.da_30_total = localStorage.getItem("da_30_total");
      this.da_40_count = localStorage.getItem("da_40_count");
      this.da_40_total = localStorage.getItem("da_40_total");
      this.da_50_count = localStorage.getItem("da_50_count");
      this.da_50_total = localStorage.getItem("da_50_total");
      this.da_60_count = localStorage.getItem("da_60_count");
      this.da_60_total = localStorage.getItem("da_60_total");
      this.link_total = localStorage.getItem("links-total");
      this.addon_count = localStorage.getItem("addons-count");
      this.addon_total = localStorage.getItem("addons-total");
      this.sub_total = localStorage.getItem("sub-total");
      this.discount = localStorage.getItem("discount") ?? 0;
      this.order_total = localStorage.getItem("order-total");
      this.form.couponCode = localStorage.getItem("couponCode");
      this.opportunity_ids = JSON.parse(
        LZString.decompress(localStorage.getItem("selected-opportunity"))
      );
      if(this.opportunity_ids){
        this.opportunityOrder = true;
        this.summaryPaymentData = JSON.parse(
          LZString.decompress(localStorage.getItem("summary-data"))
        );
      }
      this.packageOrder = JSON.parse(localStorage.getItem('packageOrder'));
      this.packageName = localStorage.getItem("packageName");
      this.packageId = localStorage.getItem("packageId");
      this.packagePrice = localStorage.getItem("packagePrice");
      this.packageLinks = localStorage.getItem("packageLinks");
    },
    loadScript() {
      const recaptchaScript = document.createElement("script");
      recaptchaScript.setAttribute(
        "src",
        "https://www.paypal.com/sdk/js?client-id=" +
          this.paypal.sandbox +
          "&currency=USD&disable-funding=credit,card"
      );
      document.head.appendChild(recaptchaScript);
    },
    paypalOption() {
      if (window.paypal) {
        var vm = this;
        window.paypal
          .Buttons({
            style: {
              shape: "rect",
              color: "gold",
              layout: "vertical",
              label: "paypal",
              size: "responsive",
              tagline: "false",
            },
            // Sets up the transaction when a payment button is clicked
            createOrder: (data, actions) => {
              return actions.order.create({
                purchase_units: [
                  {
                    amount: {
                      value: Number(vm.order_total).toFixed(2),
                    },
                  },
                ],
              });
            },
            // Finalize the transaction after payer approval
            onApprove: (data, actions) => {
              return actions.order.capture().then(function (orderData) {
                const transaction =
                  orderData.purchase_units[0].payments.captures[0];
                vm.payment_id = transaction.id;
                vm.addOrder();
              });
            },
          })
          .render("#paypal-button-container");
      }
    },
    paypalOptionBalance() {
      if (window.paypal) {
        var vm = this;
        window.paypal
          .Buttons({
            style: {
              shape: "rect",
              color: "gold",
              layout: "vertical",
              label: "paypal",
              size: "responsive",
              tagline: "false",
            },
            onClick: function () {
              if (vm.form.amountAdd == "") {
                vm.errors["balanceAmount"] = "The balance field is required.";
                return false;
              }
            },
            // Sets up the transaction when a payment button is clicked
            createOrder: (data, actions) => {
              return actions.order.create({
                purchase_units: [
                  {
                    amount: {
                      value: Number(vm.form.amountAdd).toFixed(2),
                    },
                  },
                ],
              });
            },
            // Finalize the transaction after payer approval
            onApprove: (data, actions) => {
              return actions.order.capture().then(function (orderData) {
                const transaction =
                  orderData.purchase_units[0].payments.captures[0];
                vm.payment_id = transaction.id;
                vm.addBalance();
              });
            },
            onError: function () {
              if (!Number.isInteger(vm.form.amountAdd)) {
                vm.errors["balanceAmount"] =
                  "The balance field must be a number.";
              } else if (vm.form.amountAdd == "") {
                vm.errors["balanceAmount"] = "The balance field is required.";
              }
            },
          })
          .render("#paypal-button-container-balance");
      }
    },
    cardFunction(card) {
      this.errors = [];
      this.cardText = card;
      if (this.cardText == 2) {
        this.existingCard = "yes";
        this.saveCard = "no";
        this.method = "save_card";
        if (this.card != "") {
          this.getSelectedCardDetail(this.card);
        }
      } else {
        this.existingCard = "no";
        this.method = "new_card";
        this.errors["card"] = "";
      }
    },
    cardListFunction(num) {
      if (this.cardText == 2 && num == 1) {
        this.cardFunction(2);
      }
      if (num == 1) {
        this.detailBox = false;
        this.walletBox = false;
        this.payPalBox = false;
        this.cardFunction(1);
      } else if (num == 3) {
        this.existingCard = "no";
        this.method = "balance";
        this.cardText = "";
        this.form.cardNumber = "";
        this.form.first_name = "";
        this.form.last_name = "";
        this.form.cardMM = "";
        this.form.cardYY = "";
        this.form.cardCVC = "";
        this.detailBox = true;
        this.walletBox = true;
        this.payPalBox = false;
      } else if (num == 4 && this.paypalShow == false) {
        this.existingCard = "no";
        this.method = "paypal";
        this.cardText = "";
        this.form.cardNumber = "";
        this.form.first_name = "";
        this.form.last_name = "";
        this.form.cardMM = "";
        this.form.cardYY = "";
        this.form.cardCVC = "";
        this.detailBox = true;
        this.walletBox = false;
        this.payPalBox = true;
        this.paypalShow = true;
        this.paypalOption();
      } else {
        this.existingCard = "no";
        this.method = "paypal";
        this.cardText = "";
        this.form.cardNumber = "";
        this.form.first_name = "";
        this.form.last_name = "";
        this.form.cardMM = "";
        this.form.cardYY = "";
        this.form.cardCVC = "";
        this.detailBox = true;
        this.walletBox = false;
        this.payPalBox = true;
      }
    },
    async addOrder() {
      try {
        this.loading = true;
        var vm = this;
        vm.allLinks = [];
        var addon_total;
        var addon_cost;
        vm.totals = {};
        vm.links.forEach((link) => {
          addon_total = 0;
          addon_cost = 0;
          link.addons.forEach((addon) => {
            addon_total += addon.cost;
            addon_cost += addon.cost;
          });
          vm.allLinks.push({
            id: link.id,
            product_id: link.product ? link.product.id : "",
            product_type: link.product_type ? link.product_type : null,
            exact_da_dr: link.exact_da_dr ? link.exact_da_dr : null,
            product_name: link.product ? link.product.name : "",
            link: link,
            link_total: this.packageOrder ? (this.packagePrice / this.packageLinks) : link.primary_category.total_cost,
            addon_total: addon_total,
            trialDA: link.trialDA,
            order_title: link.order_title,
            draft_order_id: this.draft_order_id,
            total: this.packageOrder ? (this.packagePrice / this.packageLinks) : link.primary_category.total_cost,
            addon_cost: addon_cost,
          });
        });
        vm.totals.sub_total = {
          label: "Subtotal",
          value: this.sub_total,
        };
        if (vm.form.couponCode != null) {
          vm.totals.discount = {
            label: "Discount",
            value: this.discount,
          };
        }
        vm.totals.total = {
          label: "Total",
          value: this.order_total,
        };

        let res = await this.$store.dispatch(
          "Product/addOrderAction",
          {
            links: vm.allLinks,
            orderTitle: vm.summary.order_title,
            totals: vm.totals,
            couponCode: vm.form.couponCode,
            method: vm.method,
            payment_id: vm.payment_id,
            saveCard: vm.saveCheck,
            existingCard: vm.existingCard,
            card: vm.card,
            first_name: vm.form.first_name,
            last_name: vm.form.last_name,
            card_number: vm.form.cardNumber,
            expiration_month: vm.form.cardMM,
            expiration_year: vm.form.cardYY,
            cvv: vm.form.cardCVC,
            address: vm.form.address,
            country: vm.form.country,
            state: vm.form.state,
            city: vm.form.city,
            zip_code: vm.form.zipCode,
            order_medium: this.orderMedium,
            order_source: this.orderSource,
            opportunity_id: this.opportunity_ids,
            opportunity: this.opportunityOrder,
            packageOrder: this.packageOrder,
            packageId: this.packageId,
          },
          {
            root: true,
          }
        );
        if (res.success) {
          if (this.appStatus === "production") {
            this.mainProducts = JSON.parse(
              localStorage.getItem("main_products")
            );
            const id = res.data.id;
            this.ga4EventPurchase(id);
            // this.universalAnalyticsCode(id);
          }

          localStorage.removeItem("order-title");
          localStorage.removeItem("links");
          localStorage.removeItem("links-total");
          localStorage.removeItem("addons-count");
          localStorage.removeItem("addons-total");
          localStorage.removeItem("sub-total");
          localStorage.removeItem("order-total");
          localStorage.removeItem("discount");
          localStorage.removeItem("couponCode");
          localStorage.removeItem("main_products");
          localStorage.removeItem("opportunity-links");
          localStorage.removeItem("selected-opportunity");
          localStorage.removeItem("summary-data");
          this.loading = false;

          toast.success(res.message, {
            timeout: 3000,
          });
          this.$router.push("/order");
        }
      } catch (e) {
        this.loading = false;
        // Already paid error
        if (e.response.data.status == 2) {
          toast.error(e.response.data.error, {
            timeout: 3000,
          });
          this.$router.push("/order");
        }
        // Insufficient funds error
        else if (e.response.data.status == 3 && this.walletBox == true) {
          toast.error("Insufficient Balance.", {
            timeout: 3000,
          });
        } else {
          this.errors = [];
          if (e.response.data.errors) {
            this.errors = e.response.data.errors;
          } else {
            toast.error(e.response.data.message, {
              timeout: 4000,
            });
          }
        }
      }
    },
    ga4EventPurchase(id) {
      var arr = [];
      var o_total;
      var adn = 0;
      var cost = 0;
      var price = 0;
      var addon_count = 0;
      var quantity = 0;
      Object.entries(this.mainProducts).forEach(([key, val]) => {
        adn = val.addon_cost;
        cost = val.link_cost;
        price = +cost + +adn;
        addon_count = val.addon_count;
        quantity = val.link_count;
        arr.push({
          id: id,
          name: key,
          currency: "USD",
          addon: Number(addon_count),
          price: Number(price),
          quantity: Number(quantity),
        });
      });

      o_total = Number(this.order_total);

      // window.dataLayer = window.dataLayer || [];
      // window.dataLayer.push({ ecommerce: null });
      // window.dataLayer.push({
      //   event: "purchase",
      //   ecommerce: {
      //     transaction_id: id, // Order ID
      //     affiliation: "Link Building HQ",
      //     currency: "USD",
      //     value: o_total,
      //     items: arr,
      //   },
      // });
      this.$gtag.purchase({
        transaction_id: id,
        affiliation: "Link Building HQ",
        currency: "USD",
        value: o_total,
        items: arr,
      });
    },
    universalAnalyticsCode(id) {
      // Configure Universal Analytics with the Ecommerce plugin
      this.$gtag.config("UA-128070241-1", {
        send_page_view: true, // Adjust as per your requirements
        ecommerce: {
          currencyCode: "USD",
        },
      });

      this.$gtag.command("ecommerce:addTransaction", {
        id: id, // Transaction ID. Required.
        affiliation: "Link Building HQ", // Affiliation or store name.
        revenue: this.order_total,
      });

      Object.entries(this.mainProducts).forEach(([key, val]) => {
        this.$gtag.command("ecommerce:addItem", {
          id: id, // Transaction ID. Required.
          name: key, // Product name. Required.
          price: +val.link_cost + +val.addon_cost, // Unit price.
          quantity: val.link_count, // Quantity.
        });
      });

      // Send data to Universal Analytics
      this.$gtag.command("ecommerce:send");
    },
    async applyCoupon() {
      try {
        this.errors["coupon"] = "";
        this.loading = true;
        var vm = this;
        vm.totals = {};
        vm.totals.sub_total = {
          label: "Subtotal",
          value: this.sub_total,
        };
        vm.totals.total = {
          label: "Total",
          value: this.order_total,
        };
        let res = await this.$store.dispatch(
          "Product/applyCouponAction",
          {
            code: this.coupon,
            totals: vm.totals,
          },
          {
            root: true,
          }
        );
        if (res.success) {
          this.form.couponCode = this.coupon;
          this.discount = res.discount;
          this.order_total = this.sub_total - this.discount;
          this.discount_perc =
            ((this.sub_total - this.order_total) / this.sub_total) * 100;
          localStorage.setItem("couponCode", this.coupon);
          localStorage.setItem("discount", this.discount);
          localStorage.setItem("order-total", this.order_total);
          this.coupon = "";
          this.loading = false;
          toast.success(res.message, {
            timeout: 3000,
          });
        }
      } catch (error) {
        this.loading = false;
        this.errors["coupon"] = error.response.data.errors;
      }
    },
    async getExistingCards() {
      try {
        this.loading = true;
        let res = await this.$store.dispatch(
          "Product/getExistingCardsDataAction"
        );
        this.existingCardList = res;
        if (this.existingCardList.length > 0 && this.paymentOption == "card") {
          this.cardFunction(2);
        } else if (this.paymentOption == "card") {
          this.cardFunction(1);
        }
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },
    async getSelectedCardDetail(id) {
      this.loading = true;
      this.useProfileAddressChecked = false;
      try {
        let res = await this.$store.dispatch(
          "Product/getSelectedCardDataAction",
          id
        );
        if (res.country) {
          this.getStates(res.country);
        }
        this.form.address = res.line1;
        this.form.country = res.country;
        this.form.city = res.city;
        this.form.zipCode = res.postal_code;
        this.form.state = res.state;
        // this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },
    async getProfileAddress() {
      this.loading = true;
      if (this.useProfileAddressChecked) {
        try {
          let res = await this.$store.dispatch(
            "Profile/getProfileAddressDataAction"
          );
          this.form.address = res.user_address.address;
          this.form.city = res.user_address.city;
          this.form.country = res.user_address.country;
          if (this.form.country) {
            this.getStates(this.form.country);
            this.form.state = res.user_address.state;
          }
          this.form.zipCode = res.user_address.postal_code;
          this.loading = false;
        } catch (e) {
          this.loading = false;
        }
      } else if (
        !this.useProfileAddressChecked &&
        this.method == "save_card" &&
        this.card != ""
      ) {
        this.getSelectedCardDetail(this.card);
      } else {
        this.form.address = "";
        this.form.city = "";
        this.form.country = null;
        this.form.state = null;
        this.form.zipCode = "";
        this.loading = false;
      }
    },
    async getCountries() {
      try {
        let res = await this.$store.dispatch("Product/getCountriesDataAction");
        this.countries = res.countries;
      } catch (e) {
        this.loading = false;
      }
    },
    async getStates(countryId) {
      this.loading = true;
      this.form.state = null;
      try {
        let res = await this.$store.dispatch(
          "Product/getStatesDataAction",
          countryId
        );
        this.states = res.states;
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },
    openSidebar() {
      setTimeout(() => (this.isSidebarOpen = true), 500);
      setTimeout(() => (this.isSidebarClose = false), 500);
    },
    closeSidebar() {
      setTimeout(() => (this.isSidebarOpen = false), 500);
    },
    handleFileChange(e) {
      if (this.rawFile !== null) {
        return;
      }
      this.rawFile = e.target.files[0];
      if (
        this.rawFile.type !=
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        swal("invalid file!");
        return;
      }
      this.fileConvertToWorkbook(this.rawFile)
        .then((workbook) => {
          let xlsxArr = XLSX.utils.sheet_to_json(
            workbook.Sheets[workbook.SheetNames[0]]
          );
          this.workbook = workbook;
          this.initTable(this.xlsxArrToTableArr(xlsxArr));
        })
        .catch((err) => {
          console.error(err);
        });
    },
    fileConvertToWorkbook(file) {
      let reader = new FileReader();
      let fixdata = (data) => {
        let o = "",
          l = 0,
          w = 10240;
        for (; l < data.byteLength / w; ++l) {
          o += String.fromCharCode.apply(
            null,
            new Uint8Array(data.slice(l * w, l * w + w))
          );
        }
        o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w)));
        return o;
      };
      return new Promise((resolve, reject) => {
        try {
          reader.onload = (renderEvent) => {
            let data = renderEvent.target.result;
            if (this.rABS) {
              /* if binary string, read with type 'binary' */
              resolve(
                XLSX.read(data, {
                  type: "binary",
                })
              );
            } else {
              /* if array buffer, convert to base64 */
              let arr = fixdata(data);
              resolve(
                XLSX.read(btoa(arr), {
                  type: "base64",
                })
              );
            }
          };
          reader.onerror = (error) => {
            reject(error);
          };
          if (this.rABS) {
            reader.readAsBinaryString(file);
          } else {
            reader.readAsArrayBuffer(file);
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    showAddBalance() {
      this.showAddBalanceDiv = this.userData.balance < this.order_total;
    },
  },
  computed: {
    userData() {
      const data = this.$store.getters["Auth/getUser"];
      return {
        balance: data.balance,
        role: data.role,
        is_wallet: data.is_wallet,
        is_add_card: data.is_add_card,
        is_save_card: data.is_save_card,
      };
    },
  },
  watch: {
    "$route.query": {
      immediate: true,
      handler(newQuery){
        const obj = newQuery;
        if(obj.utm_medium && obj.utm_source){
          this.orderMedium = obj.utm_medium;
          this.orderSource = obj.utm_source;
        }
      }
    },
    userData() {
      this.showAddBalance();
    },
    order_total() {
      this.showAddBalance();
    },
  },
};
</script>
