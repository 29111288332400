\<template>
  <div class="home">
    <LoaderComponent v-show="loading" />
    <HeaderView />
    <section
      class="order-page-main-sec profile-page-main-sec agency-design-grid-right"
    >
      <div class="desktop-respovsive-main-sec">
        <div class="invoice-history-content">
          <h2 class="text-heading">Profile</h2>
        </div>

        <div class="editor-dashboard-sec-2">
          <div class="profile-list-tabs">
            <b-card no-body>
              <b-tabs card>
                <b-tab title="Profile Overview" active>
                  <b-card-text>
                    <div class="profile-overview-box-sec">
                      <div class="agency-profile-bar">
                        <div class="img-box">
                          <input
                            type="file"
                            @change="onFileChange"
                            id="file_id"
                            accept="image/x-png,image/gif,image/jpeg"
                          />
                          <label for="file_id"></label>
                          <!-- <img src="@/assets/common/images/logo.svg"> -->
                          <div id="preview">
                            <img v-if="image" :src="image" />
                          </div>

                          <div class="user-text">
                            <div class="text-1">{{ userProfile.name }}</div>
                            <div class="text-2">{{ userProfile.email }}</div>
                          </div>
                        </div>
                        <div
                          class="balance-box"
                          :class="{
                            balanceCenter: userData.role == 'Associate',
                          }"
                        >
                          <div class="text-1">
                            Balance<br />
                            <b>{{ numberFormat(userData.balance,'currency') }}</b>
                          </div>
                          <div class="btn-box" v-if="userData.role == 'Agency'">
                            <a href="#" class="btn-1" v-b-modal.modal-box-4
                              >Add Balance</a
                            >
                          </div>
                        </div>
                      </div>
                      <div class="payment-detail-box">
                        <form>
                          <div class="detail-box">
                            <b-row>
                              <b-col md="12">
                                <div class="payment-heading">
                                  Personal Details
                                </div>
                              </b-col>
                              <template v-if="userData.role == 'Agency'">
                                <b-col md="6">
                                  <div class="form-group">
                                    <label>Name</label>
                                    <b-form-input
                                      v-model="userProfile.name"
                                      type="text"
                                      required
                                    ></b-form-input>
                                    <span
                                      v-if="errors.name"
                                      class="required-text"
                                      style="display: block"
                                      role="alert"
                                    >
                                      <strong>{{ errors.name[0] }}</strong>
                                    </span>
                                  </div>
                                </b-col>
                                <b-col md="6">
                                  <div class="form-group">
                                    <label>Phone #</label>
                                    <!-- <b-form-input v-model="userProfile.phone" type="text"
                                    required></b-form-input> -->
                                    <MazPhoneNumberInput
                                      v-model="userProfile.phone"
                                      show-code-on-list
                                      size="md"
                                      @update="onUpdate = $event"
                                    />
                                    <span
                                      v-if="errors.phone"
                                      class="required-text"
                                      style="display: block"
                                      role="alert"
                                    >
                                      <strong>{{ errors.phone[0] }}</strong>
                                    </span>
                                  </div>
                                </b-col>
                                <b-col md="6">
                                  <div class="form-group">
                                    <label>Email</label>
                                    <b-form-input
                                      v-model="userProfile.email"
                                      type="text"
                                      readonly
                                    ></b-form-input>
                                    <span
                                      v-if="errors.email"
                                      class="required-text"
                                      style="display: block"
                                      role="alert"
                                    >
                                      <strong>{{ errors.email[0] }}</strong>
                                    </span>
                                  </div>
                                </b-col>
                                <b-col md="6">
                                  <div class="form-group">
                                    <label>Company Name</label>
                                    <b-form-input
                                      v-model="userProfile.company"
                                      type="text"
                                      required
                                    ></b-form-input>
                                    <span
                                      v-if="errors.company"
                                      class="required-text"
                                      style="display: block"
                                      role="alert"
                                    >
                                      <strong>{{ errors.company[0] }}</strong>
                                    </span>
                                  </div>
                                </b-col>
                              </template>
                              <template v-if="userData.role == 'Associate'">
                                <b-col md="6">
                                  <div class="form-group">
                                    <label>First Name</label>
                                    <b-form-input
                                      v-model="userProfile.first_name"
                                      type="text"
                                      required
                                    ></b-form-input>
                                    <span
                                      v-if="errors.first_name"
                                      class="required-text"
                                      style="display: block"
                                      role="alert"
                                    >
                                      <strong>{{ errors.first_name[0] }}</strong>
                                    </span>
                                  </div>
                                </b-col>
                                <b-col md="6">
                                  <div class="form-group">
                                    <label>Last Name</label>
                                    <b-form-input
                                      v-model="userProfile.last_name"
                                      type="text"
                                      required
                                    ></b-form-input>
                                    <span
                                      v-if="errors.last_name"
                                      class="required-text"
                                      style="display: block"
                                      role="alert"
                                    >
                                      <strong>{{ errors.last_name[0] }}</strong>
                                    </span>
                                  </div>
                                </b-col>
                                <b-col md="6">
                                  <div class="form-group">
                                    <label>Email</label>
                                    <b-form-input
                                      v-model="userProfile.email"
                                      type="text"
                                      readonly
                                    ></b-form-input>
                                    <span
                                      v-if="errors.email"
                                      class="required-text"
                                      style="display: block"
                                      role="alert"
                                    >
                                      <strong>{{ errors.email[0] }}</strong>
                                    </span>
                                  </div>
                                </b-col>
                              </template>
                              <b-col md="12">
                                <div class="payment-heading billing-heading">
                                  Address Details
                                </div>
                              </b-col>
                              <b-col md="6">
                                <div class="form-group">
                                  <label>Country</label>
                                  <b-form-select
                                    v-model="userProfile.country"
                                    @change="getStates($event, 'profile')"
                                    required
                                  >
                                    <option value="" disabled>
                                      Select Country
                                    </option>
                                    <option
                                      v-for="(pd, k) in countries"
                                      :key="k"
                                      :value="pd.id"
                                    >
                                      {{ pd.name }}
                                    </option>
                                  </b-form-select>
                                  <span
                                    v-if="errors.country"
                                    class="required-text"
                                    style="display: block"
                                    role="alert"
                                  >
                                    <strong>{{
                                      userProfile.country != ""
                                        ? ""
                                        : errors.country[0]
                                    }}</strong>
                                  </span>
                                </div>
                              </b-col>
                              <b-col md="6">
                                <div class="form-group">
                                  <label>State</label>
                                  <b-form-select
                                    class=""
                                    v-model="userProfile.state"
                                    required
                                  >
                                    <template v-slot:first>
                                      <option :value="null" disabled>
                                        Select State
                                      </option>
                                    </template>
                                    <option
                                      v-for="(state, index) in states"
                                      v-bind:value="state.id"
                                      :key="index"
                                    >
                                      {{ state.name }}
                                    </option>
                                  </b-form-select>
                                  <span
                                    v-if="errors.state"
                                    class="required-text"
                                    style="display: block"
                                    role="alert"
                                  >
                                    <strong>{{
                                      userProfile.state != null
                                        ? ""
                                        : errors.state[0]
                                    }}</strong>
                                  </span>
                                </div>
                              </b-col>
                              <b-col md="12">
                                <div class="form-group">
                                  <label>Address</label>
                                  <b-form-input
                                    type="text"
                                    v-model="userProfile.address"
                                    required
                                  ></b-form-input>
                                  <span
                                    v-if="errors.address"
                                    class="required-text"
                                    style="display: block"
                                    role="alert"
                                  >
                                    <strong>{{
                                      userProfile.address.length > 0
                                        ? ""
                                        : errors.address[0]
                                    }}</strong>
                                  </span>
                                </div>
                              </b-col>

                              <b-col md="6">
                                <div class="form-group">
                                  <label>City</label>
                                  <b-form-input
                                    type="text"
                                    v-model="userProfile.city_name"
                                    required
                                  ></b-form-input>
                                  <span
                                    v-if="errors.city_name"
                                    class="required-text"
                                    style="display: block"
                                    role="alert"
                                  >
                                    <strong>{{
                                      userProfile.city_name.length > 0
                                        ? ""
                                        : errors.city_name[0]
                                    }}</strong>
                                  </span>
                                </div>
                              </b-col>
                              <b-col md="6">
                                <div class="form-group">
                                  <label>Zip Code</label>
                                  <b-form-input
                                    type="tel"
                                    v-model="userProfile.postal_code"
                                    required
                                  ></b-form-input>
                                  <span
                                    v-if="errors.postal_code"
                                    class="required-text"
                                    style="display: block"
                                    role="alert"
                                  >
                                    <strong>{{
                                      userProfile.postal_code.length > 0
                                        ? ""
                                        : errors.postal_code[0]
                                    }}</strong>
                                  </span>
                                </div>
                              </b-col>
                              <b-col md="12">
                                <div class="btn-box-list">
                                  <a
                                    href="javascript:;"
                                    :disabled="isSending"
                                    @click="submitUserProfile"
                                    >Save changes
                                  </a>
                                </div>
                              </b-col>
                            </b-row>
                          </div>
                        </form>
                      </div>
                    </div>
                  </b-card-text>
                </b-tab>
                <b-tab title="Payment Options" v-if="userData.role == 'Agency'">
                  <b-card-text>
                    <div class="payment-option-sec-1">
                      <b-row>
                        <b-col md="12">
                          <div class="save-heading">Saved Cards</div>
                          <div class="save-card-box">
                            <div
                              class="card-1"
                              v-for="existingCard in existingCardList"
                              :key="existingCard.id"
                            >
                              <input
                                type="radio"
                                :id="'test' + existingCard.id"
                                name="chip-group"
                                :value="existingCard.id"
                              />
                              <label :for="'test' + existingCard.id">
                                <img src="@/assets/common/images/chip.svg" />
                                <div class="stars">
                                  <ul>
                                    <li>
                                      <i class="fas fa-star-of-life"></i>
                                      <i class="fas fa-star-of-life"></i>
                                      <i class="fas fa-star-of-life"></i>
                                      <i class="fas fa-star-of-life"></i>
                                    </li>
                                    <li>
                                      <i class="fas fa-star-of-life"></i>
                                      <i class="fas fa-star-of-life"></i>
                                      <i class="fas fa-star-of-life"></i>
                                      <i class="fas fa-star-of-life"></i>
                                    </li>
                                    <li>
                                      <i class="fas fa-star-of-life"></i>
                                      <i class="fas fa-star-of-life"></i>
                                      <i class="fas fa-star-of-life"></i>
                                      <i class="fas fa-star-of-life"></i>
                                    </li>
                                    <li>
                                      {{ existingCard.last4 }}
                                    </li>
                                    <div class="btn-box">
                                      <a
                                        href="javascript:;"
                                        @click="
                                          deleteButtonPopup(existingCard.id)
                                        "
                                        ><i class="fas fa-trash-alt"></i
                                      ></a>
                                    </div>
                                  </ul>
                                </div>
                              </label>
                            </div>

                            <div
                              class="btn-box-list"
                              v-if="
                                userData.role == 'Agency' ||
                                (userData.role == 'Associate' &&
                                  userData.is_add_card == 1)
                              "
                            >
                              <a
                                href="javascript:;"
                                v-b-modal.modal-payment-box-1
                                >Add New Card
                              </a>
                            </div>
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                  </b-card-text>
                </b-tab>
                <b-tab title="Reset Password">
                  <b-card-text>
                    <div
                      class="profile-overview-box-sec reset-password-box-sec"
                    >
                      <div class="payment-detail-box">
                        <form>
                          <div class="detail-box">
                            <b-row>
                              <b-col md="12">
                                <div class="payment-heading">
                                  Change Password
                                </div>
                              </b-col>
                              <b-col md="6">
                                <div class="form-group">
                                  <label>Current Password</label>
                                  <b-form-input
                                    v-model="password.current_password"
                                    type="password"
                                    required
                                  ></b-form-input>
                                  <span
                                    v-if="errors.current_password"
                                    class="required-text"
                                    style="display: block"
                                    role="alert"
                                  >
                                    <strong>{{
                                      errors.current_password[0]
                                    }}</strong>
                                  </span>
                                </div>
                              </b-col>
                              <b-col md="6"> </b-col>
                              <b-col md="6">
                                <div class="form-group">
                                  <label>New Password</label>
                                  <b-form-input
                                    v-model="password.new_password"
                                    type="password"
                                    required
                                  ></b-form-input>
                                  <span
                                    v-if="errors.new_password"
                                    class="required-text"
                                    style="display: block"
                                    role="alert"
                                  >
                                    <strong>{{
                                      errors.new_password[0]
                                    }}</strong>
                                  </span>
                                </div>
                              </b-col>
                              <b-col md="6">
                                <div class="form-group">
                                  <label>Confirm New Password</label>
                                  <b-form-input
                                    v-model="password.confirm_password"
                                    type="password"
                                    required
                                  ></b-form-input>
                                  <span
                                    v-if="errors.confirm_password"
                                    class="required-text"
                                    style="display: block"
                                    role="alert"
                                  >
                                    <strong>{{
                                      errors.confirm_password[0]
                                    }}</strong>
                                  </span>
                                </div>
                              </b-col>

                              <b-col md="12">
                                <div class="btn-box-list">
                                  <a
                                    href="javascript:;"
                                    :disabled="isSending"
                                    @click="changePassword()"
                                    >Save Changes
                                  </a>
                                </div>
                              </b-col>
                            </b-row>
                          </div>
                        </form>
                      </div>
                    </div>
                  </b-card-text>
                </b-tab>

                <b-tab title="Alternate Emails" v-if="userData.role == 'Agency'">
                  <b-card-text>
                    <div class="alternate-email-sec">
                      <b-row>
                        <b-col md="12">
                          <div class="add-email-heading">Listed Emails</div>
                          <div
                            class="form-group"
                            label="Email"
                            label-for="input-1"
                            v-for="(email, index) in alternate_email"
                            :key="index"
                          >
                            <div class="add-email">
                              <b-form-input
                                :readonly="true"
                                type="email"
                                v-model="email.email"
                              ></b-form-input>
                              <div class="btn-box">
                                <a
                                  href="javascript:;"
                                  :disabled="isSending"
                                  @click="removeListedEmail(email.id)"
                                  ><i class="fas fa-trash-alt"></i
                                ></a>
                              </div>
                            </div>
                          </div>
                        </b-col>
                        <b-col md="12">
                          <div class="add-email-heading alternate-heading">
                            Add Alternate Emails
                          </div>
                        </b-col>
                        <b-col md="12">
                          <div
                            class="form-group"
                            label="Email"
                            label-for="input-1"
                            v-for="(add, index) in addmore"
                            :key="index"
                          >
                            <div class="add-email">
                              <b-form-input
                                v-model="addmore[index].email"
                                placeholder="example@example.com"
                              ></b-form-input>
                              <div class="btn-box" v-if="index == 0">
                                <a href="javascript:;" @click="addEmail">
                                  <i class="fas fa-plus"></i
                                ></a>
                              </div>
                              <div class="btn-box" v-if="index != 0">
                                <a
                                  href="javascript:;"
                                  @click="removeEmail(index)"
                                  ><i class="fas fa-trash-alt"></i
                                ></a>
                              </div>
                            </div>
                            <div v-if="errors">
                              <div v-for="(v, k) in errors" :key="k">
                                <span
                                  v-if="k == 'addmore.' + index + '.email'"
                                  class="required-text"
                                  style="display: block"
                                  role="alert"
                                >
                                  <strong> {{ v[0] }}</strong>
                                </span>
                              </div>
                            </div>
                          </div>
                        </b-col>

                        <b-col md="12">
                          <div class="btn-box-list">
                            <a
                              href="javascript:;"
                              :disabled="isSending"
                              @click="submitAlternateEmail()"
                              >Save changes
                            </a>
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                  </b-card-text>
                </b-tab>
              </b-tabs>
            </b-card>
          </div>
        </div>
      </div>

      
      <div class="modal-list">
        <!-- Payment Options Tab -> Add New Card -->
        <b-modal
          id="modal-payment-box-1"
          centered
          title="Add New Card"
          :no-close-on-backdrop="true"
        >
          <div class="payment-detail-box">
            <form>
              <b-row>
                <div class="detail-box" :class="{ balanceBox }">
                  <b-row>
                      <b-col md="12">
                        <div class="method-heading">Billing Details</div>
                        <div class="form-group">
                          <b-form-input
                            v-model="newCard.address"
                            type="text"
                            placeholder="Address*"
                            required
                          ></b-form-input>
                          <span
                            v-if="errorsNewCard.address"
                            class="invalid-feedback"
                            style="display: block"
                            role="alert"
                          >
                            <strong>
                              {{
                                newCard.address.length > 0
                                  ? ""
                                  : errorsNewCard.address[0]
                              }}
                            </strong>
                          </span>
                        </div>
                      </b-col>
                      <b-col md="6">
                        <div class="form-group">
                          <b-form-select
                            v-model="newCard.country"
                            @change="getStates($event, 'popup')"
                            required
                          >
                            <option value="null" disabled hidden>
                              Select Country*
                            </option>
                            <option
                              v-for="(pd, k) in countries"
                              :key="k"
                              :value="pd.id"
                            >
                              {{ pd.name }}
                            </option>
                          </b-form-select>
                          <span
                            v-if="errorsNewCard.country"
                            class="invalid-feedback"
                            style="display: block"
                            role="alert"
                          >
                            <strong>
                              {{
                                newCard.country != null ? "" : errorsNewCard.country[0]
                              }}
                            </strong>
                          </span>
                        </div>
                      </b-col>
                      <b-col md="6">
                        <div class="form-group">
                          <b-form-select v-model="newCard.state" required>
                            <option value="null" disabled hidden>
                              Select States*
                            </option>
                            <option
                              v-for="(pd, k) in statesPopup"
                              :key="k"
                              :value="pd.id"
                            >
                              {{ pd.name }}
                            </option>
                          </b-form-select>
                          <span
                            v-if="errorsNewCard.state"
                            class="invalid-feedback"
                            style="display: block"
                            role="alert"
                          >
                            <strong>
                              {{ newCard.state != null ? "" : errorsNewCard.state[0] }}
                            </strong>
                          </span>
                        </div>
                      </b-col>
                      <b-col md="6">
                        <div class="form-group">
                          <b-form-input
                            v-model="newCard.city"
                            type="text"
                            placeholder="City*"
                            required
                          ></b-form-input>
                          <span
                            v-if="errorsNewCard.city"
                            class="invalid-feedback"
                            style="display: block"
                            role="alert"
                          >
                            <strong>
                              {{
                                newCard.city.length > 0 ? "" : errorsNewCard.city[0]
                              }}
                            </strong>
                          </span>
                        </div>
                      </b-col>
                      <b-col md="6">
                        <div class="form-group">
                          <b-form-input
                            v-model="newCard.zip_code"
                            type="tel"
                            placeholder="Zip Code*"
                            required
                          ></b-form-input>
                          <span
                            v-if="errorsNewCard.zip_code"
                            class="invalid-feedback"
                            style="display: block"
                            role="alert"
                          >
                            <strong>
                              {{
                                newCard.zip_code.length > 0
                                  ? ""
                                  : errorsNewCard.zip_code[0]
                              }}
                            </strong>
                          </span>
                        </div>
                      </b-col>
                      <b-col md="12">
                        <div
                          class="check-box-text"
                        >
                          <b-form-checkbox
                            id="checkbox-2"
                            v-model="useProfileAddressChecked"
                            name="checkbox-2"
                            @change="getProfileAddress"
                          >
                            Use Profile Address
                          </b-form-checkbox>
                        </div>
                      </b-col>
                    <b-col md="12">
                      <div class="method-heading">Card Details</div>
                      <div
                        class="card-sec-1"
                        :class="{ active: cardText == 1 }"
                      >
                        <b-row class="card-field-1">
                          <b-col md="9">
                            <div class="form-group">
                              <b-form-input
                                v-model="newCard.card_number"
                                type="text"
                                placeholder="Card number"
                                required
                              ></b-form-input>
                            </div>
                          </b-col>
                          <b-col md="2">
                            <div class="form-group">
                              <b-form-input
                                v-model="newCard.expiration_month"
                                type="text"
                                maxlength="2"
                                placeholder="MM /"
                                @keypress="filterNumeric"
                                required
                              ></b-form-input>
                            </div>
                            <div class="form-group">
                              <b-form-input
                                v-model="newCard.expiration_year"
                                type="text"
                                maxlength="2"
                                placeholder="YY /"
                                @keypress="filterNumeric"
                                required
                              ></b-form-input>
                            </div>
                            <div class="form-group">
                              <b-form-input
                                v-model="newCard.cvc"
                                type="text"
                                maxlength="4"
                                placeholder="CVV"
                                @keypress="filterNumeric"
                                required
                              ></b-form-input>
                            </div>
                          </b-col>
                        </b-row>
                        <b-col md="12" v-if="errorsNewCard">
                          <b-row>
                            <b-col md="9">
                              <span
                                v-if="errorsNewCard.card_number"
                                class="invalid-feedback"
                                style="display: block"
                                role="alert"
                              >
                                <strong>
                                  {{ errorsNewCard.card_number[0] }}
                                </strong>
                              </span>
                            </b-col>
                            <b-col md="3">
                              <span
                                v-if="errorsNewCard.expiration_month"
                                class="invalid-feedback"
                                style="display: block"
                                role="alert"
                              >
                                <strong>
                                  {{ errorsNewCard.expiration_month[0] }}
                                </strong>
                              </span>
                              <span
                                v-if="errorsNewCard.expiration_year"
                                class="invalid-feedback"
                                style="display: block"
                                role="alert"
                              >
                                <strong>
                                  {{ errorsNewCard.expiration_year[0] }}
                                </strong>
                              </span>
                              <span
                                v-if="errorsNewCard.cvc"
                                class="invalid-feedback"
                                style="display: block"
                                role="alert"
                              >
                                <strong>
                                  {{ errorsNewCard.cvc[0] }}
                                </strong>
                              </span>
                            </b-col>
                          </b-row>
                        </b-col>
                        <b-col md="12" class="card-field-2">
                          <b-row>
                            <b-col md="6">
                              <div class="form-group">
                                <b-form-input
                                  v-model="newCard.first_name"
                                  type="text"
                                  placeholder="First Name"
                                  required
                                ></b-form-input>
                                <span
                                  v-if="errorsNewCard.first_name"
                                  class="invalid-feedback"
                                  style="display: block"
                                  role="alert"
                                >
                                  <strong>{{ errorsNewCard.first_name[0] }}</strong>
                                </span>
                              </div>
                            </b-col>
                            <b-col md="6">
                              <div class="form-group">
                                <b-form-input
                                  v-model="newCard.last_name"
                                  type="text"
                                  placeholder="Last Name"
                                  required
                                ></b-form-input>
                                <span
                                  v-if="errorsNewCard.last_name"
                                  class="invalid-feedback"
                                  style="display: block"
                                  role="alert"
                                >
                                  <strong>{{ errorsNewCard.last_name[0] }}</strong>
                                </span>
                              </div>
                            </b-col>
                          </b-row>
                        </b-col>
                      </div>
                    </b-col>
                    <!-- <b-col md="12">
                      <div class="save-card-btns">
                        <div
                          class="check-box-text"
                          @click="cardFunction(1)"
                          :class="{ active: cardText == 1 }"
                        >
                          <b-form-checkbox
                            id="checkbox-1"
                            name="checkbox-1"
                            value="accepted"
                            unchecked-value="not_accepted"
                          >
                            <span> Save this card </span>
                          </b-form-checkbox>
                        </div>
                      </div>
                    </b-col> -->
                    <b-col md="12">
                      <a
                        href="javascript:;"
                        @click="addNewCard()"
                        class="btn-1"
                      >
                        <i class="fas fa-lock"></i> Save this Card
                      </a></b-col
                    >
                  </b-row>
                </div>
              </b-row>
            </form>
          </div>
        </b-modal>

        <!-- Profile Overview -> Add Balance -->
        <b-modal
          id="modal-box-4"
          centered
          title="Add Balance"
          :no-close-on-backdrop="true"
          v-if="userData.role == 'Agency'"
        >
          <AddBalanceComponent :countries="countries" :existingCards="existingCardList"/>
        </b-modal>
      </div>
    </section>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
const toast = useToast();
import HeaderView from "@/components/layout/HeaderComponent.vue";
import LoaderComponent from "@/components/LoaderComponent.vue";
import MazPhoneNumberInput from "maz-ui/components/MazPhoneNumberInput";
import swal from "sweetalert2";
import "maz-ui/css/main.css";
import url, { numberFormat } from "@/prod.js";
import jquery from "jquery";
import AddBalanceComponent from "@/components/AddBalanceComponent.vue";

export default {
  name: "UserProfileView",
  components: {
    HeaderView,
    LoaderComponent,
    MazPhoneNumberInput,
    AddBalanceComponent
  },
  data() {
    return {
      numberFormat,
      loading: false,
      base_url: url.baseUrl,
      cardText: 1,
      isSending: false,
      balanceBox: true,
      countries: [],
      states: [],
      statesPopup: [],
      addmore: [
        {
          email: "",
        },
      ],
      password: {
        current_password: "",
        new_password: "",
        confirm_password: "",
      },
      file: "",
      image: null,
      userProfile: {
        name: "",
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        company: "",
        address: "",
        postal_code: "",
        country: "",
        state: null,
        city_name: "",
      },
      newCard: {
        card_number: "",
        expiration_month: "",
        expiration_year: "",
        cvc: "",
        first_name: "",
        last_name: "",
        address: "",
        zip_code: "",
        country: null,
        state: null,
        city: "",
      },
      alternate_email: [],
      form: {
        card_number: "",
        amountAdd: "",
        expirationMM: "",
        expirationYear: "",
        cardCVC: "",
        cardNumber: "",
        first_name: "",
        last_name: "",
        address: "",
        zip_code: "",
        country: null,
        state: null,
        city: "",
      },
      errors: [],
      errorsNewCard: [],
      showPassword: false,
      existingCardList: [],
      useProfileAddressChecked: false,
    };
  },

  mounted() {
    let vm = this;
    vm.getUserProfile();
    vm.getCountries();
    vm.getExistingCards();
  },

  methods: {
    filterNumeric(event) { 
      const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']; 
      const keyPressed = event.key;
      
      if (!keysAllowed.includes(keyPressed)) {
        event.preventDefault();
      }
    },
    async getUserProfile() {
      try {
        this.loading = true;
        let res = await this.$store.dispatch(
          "Profile/getAllUserProfileDataAction"
        );
        if(this.userData.role == 'Associate'){
          this.userProfile.first_name = res.personal_detail.first_name;
          this.userProfile.last_name = res.personal_detail.last_name;
        }else{
          this.userProfile.name = res.personal_detail.name;
          this.userProfile.phone = res.personal_detail.phone;
          this.userProfile.company = res.personal_detail.company;
        }
        this.userProfile.email = res.personal_detail.email;
        this.userProfile.address = res.billing_detail.address;
        this.userProfile.city_name = res.billing_detail.city;
        this.userProfile.country = res.billing_detail.country;
        this.alternate_email = res.personal_detail.alternate_email;
        if (this.userProfile.country) {
          this.getStates(this.userProfile.country, "profile");
          this.userProfile.state = res.billing_detail.state;
        }
        this.userProfile.postal_code = res.billing_detail.postal_code;
        this.image = this.base_url + res.image;
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },
    async submitUserProfile() {
      try {
        this.loading = true;
        let res = await this.$store.dispatch(
          "Profile/postAllUpdateUserDataAction",
          this.userProfile,
          {
            root: true,
          }
        );
        if (res.success) {
          if(this.userData.role == 'Associate'){
            this.userProfile.first_name = res.personal_detail.first_name;
            this.userProfile.last_name = res.personal_detail.last_name;
          }else{
            this.userProfile.name = res.personal_detail.name;
            this.userProfile.phone = res.personal_detail.phone;
            this.userProfile.company = res.personal_detail.company;
          }
          this.userProfile.email = res.personal_detail.email;
          this.userProfile.address = res.billing_detail.address;
          this.userProfile.city_name = res.billing_detail.city;
          this.userProfile.country = res.billing_detail.country;
          this.userProfile.state = res.billing_detail.state;
          this.userProfile.postal_code = res.billing_detail.postal_code;
          this.loading = false;
          toast.success(res.message, { timeout: 3000 });
          this.errors = [];
        }
      } catch (error) {
        this.errors = error.response.data.errors;
        console.log(this.errors);
        this.loading = false;
      }
    },
    async submitAlternateEmail() {
      try {
        this.loading = true;
        let res = await this.$store.dispatch(
          "Profile/postAlternateEmailDataAction",
          {
            addmore: this.addmore,
          },
          {
            root: true,
          }
        );
        this.alternate_email = res.alternate_email;
        if (res.success) {
          this.loading = false;
          // After submitting, clear all email input fields
          this.addmore.forEach((item) => {
            item.email = "";
          });
          this.addmore.splice(1);
          toast.success(res.message, { timeout: 3000 });
          this.errors = [];
        }
      } catch (error) {
        this.loading = false;
        this.errors = error.response.data.errors;
      }
    },
    async removeListedEmail(remove_id) {
      try {
        this.loading = true;
        let res = await this.$store.dispatch(
          "Profile/removeAlternateEmailAction",
          { id: remove_id },
          {
            root: true,
          }
        );
        this.alternate_email = res.alternate_email;
        if (res.success) {
          toast.success(res.message, { timeout: 3000 });
          this.loading = false;
        }
      } catch (e) {
        this.loading = false;
      }
    },
    async deleteButtonPopup(id) {
      swal
        .fire({
          title: "Are you sure?",
          text: "On click delete your card will be removed from the list!",
          icon: "warning",
          width: 600,
          showCancelButton: true,
          confirmButtonColor: "#00b6cb",
          cancelButtonColor: "#ff0e52",
          confirmButtonText: "Delete",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.deleteButton(id);
          }
        });
    },
    async deleteButton(id) {
      try {
        this.loading = true;
        let res = await this.$store.dispatch(
          "Profile/removeCardAction",
          { id: id },
          {
            root: true,
          }
        );
        if (res.success) {
          this.existingCardList = res.data;
          toast.success(res.message, { timeout: 3000 });
          this.loading = false;
        }
      } catch (e) {
        this.loading = false;
      }
    },
    async getCountries() {
      try {
        let res = await this.$store.dispatch("Product/getCountriesDataAction");
        this.countries = res.countries;
      } catch (e) {
        this.loading = false;
      }
    },
    async getStates(countryId, option) {
      this.loading = true;
      if(option == 'profile'){
        this.userProfile.state = null;
      }
      this.newCard.state = null;
      this.form.state = null;

      try {
        let res = await this.$store.dispatch(
          "Product/getStatesDataAction",
          countryId
        );
        if (option != "profile") {
          this.statesPopup = res.states;
        } else {
          this.states = res.states;
        }
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },
    async onFileChange(e) {
      this.file = e.target.files[0];
      let data = new FormData();
      data.append("image", this.file);
      this.loading = true;
      try {
        let res = await this.$store.dispatch(
          "Profile/postSaveImageDataAction",
          data
        );
        if (res.success) {
          this.loading = false;
          this.image = this.base_url + res.image;
          toast.success(res.message, { timeout: 3000 });
        }
      } catch (e) {
        this.loading = false;
      }
    },
    async getExistingCards() {
      try {
        let res = await this.$store.dispatch(
          "Product/getExistingCardsDataAction"
        );
        this.existingCardList = res;
      } catch (e) {
        this.loading = false;
      }
    },
    async changePassword() {
      this.errors = [];
      try {
        this.loading = true;
        let res = await this.$store.dispatch(
          "Profile/changePasswordAction",
          this.password,
          {
            root: true,
          }
        );
        if (res.success) {
          toast.success(res.message, { timeout: 3000 });
          this.password.current_password = "";
          this.password.new_password = "";
          this.password.confirm_password = "";
          this.loading = false;
          this.errors = [];
        }
      } catch (error) {
        this.loading = false;
        if(error.response.data.same_password){
          toast.error(error.response.data.message, {
            timeout: 4000,
          });
        }else{
          this.errors = error.response.data.errors;
        }
      }
    },
    async addNewCard() {
      this.errorsNewCard = [];
      try {
        this.loading = true;
        let res = await this.$store.dispatch(
          "Profile/addNewCardAction",
          this.newCard,
          {
            root: true,
          }
        );
        if (res.success) {
          this.existingCardList = res.data;
          this.loading = false;
          jquery(".btn-close").trigger("click");
          toast.success(res.message, { timeout: 3000 });
          this.errorsNewCard = [];
          for (let key in this.newCard) {
            if (Object.prototype.hasOwnProperty.call(this.newCard, key)) {
              this.newCard[key] = '';
            }
          }
          this.newCard.country = null;
          this.newCard.state = null;
          this.useProfileAddressChecked = false;
        }
      } catch (error) {
        this.useProfileAddressChecked = false;
        this.loading = false;
        this.errorsNewCard = [];
        if (error.response.data.errors) {
          this.errorsNewCard = error.response.data.errors;
        } else {
          toast.error(error.response.data.message, {
            timeout: 4000,
          });
        }
      }
    },
    addEmail() {
      this.addmore.push({
        email: "",
      });
    },
    removeEmail(index) {
      //type.splice(index, 1);
      this.addmore.splice(index, 1);
    },
    async getProfileAddress() {
      this.loading = true;
      if(this.useProfileAddressChecked){
        try {
          let res = await this.$store.dispatch(
            "Profile/getProfileAddressDataAction"
          );
          this.newCard.address = res.user_address.address;
          this.newCard.city = res.user_address.city;
          this.newCard.country = res.user_address.country;
          if (this.newCard.country) {
            this.getStates(this.newCard.country);
            this.newCard.state = res.user_address.state;
          }
          this.newCard.zip_code = res.user_address.postal_code;
          this.loading = false;
        } catch (e) {
          this.loading = false;
        }
      }else{
        this.newCard.address = "";
        this.newCard.city = "";
        this.newCard.country = null;
        this.newCard.state = null;
        this.newCard.zip_code = "";
        this.loading = false;
      }
    },
  },

  computed: {
    userData() {
      const data = this.$store.getters["Auth/getUser"];
      return {
        name: data.name,
        email: data.email,
        balance: data.balance,
        is_add_card: data.is_add_card,
        role: data.role,
      };
    },
  },
};
</script>
