import { createApp } from 'vue'
import App from './App.vue'
import VueGtag from "vue-gtag";
import router from './router'
import store from './store'
import Toast from "vue-toastification";
import BootstrapVue3 from 'bootstrap-vue-3'
import Datepicker from '@vuepic/vue-datepicker'
// import axios from 'axios'
// import VueAxios from 'vue-axios'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import '@vuepic/vue-datepicker/dist/main.css';
import "vue-toastification/dist/index.css";

window.$ = window.jQuery = require("jquery");


const toastOption = {
    transition: "Vue-Toastification__fade",
    position: "top-right",
    timeout: 8000,
    closeOnClick: true,
    pauseOnFocusLoss: false,
    pauseOnHover: false,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: false,
    closeButton: "button",
    icon: true,
    rtl: false
}
createApp(App).use(VueGtag, {
    config: { id: "G-K51T6T1GCD" }
  }).use(store).use(router).use(Datepicker).use(BootstrapVue3).use(Toast,toastOption).mount('#app')