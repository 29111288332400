<template>
  <div class="order-count-list">
    <ul>
      <li>
        <div class="text-1">
          <div class="icon-box" style="background: #f4f2ff">
            <img src="@/assets/common/images/dashboard-icon-2.png" />

          </div>
     
        </div>

        <div class="text-2">
          <StatsLoaderComponent v-show="$parent.loarder1"/>
          <div
            class="count-text" v-if="!$parent.loarder1"
            v-text="Math.floor(stats.total_orders).toLocaleString()"
          >
          </div>
          Total Orders</div>
      </li>
      <li>
        <div class="text-1">
          <div class="icon-box" style="background: #e1e2ef">
            <img src="@/assets/common/images/dashboard-icon-4.png" />
          </div>
        </div>
        <div class="text-2">
          <StatsLoaderComponent v-show="$parent.loarder2"/>
          <div
            class="count-text" v-if="!$parent.loarder2"
            v-text="Math.floor(stats.total_links).toLocaleString()"
          ></div>
          Total Links</div>
      </li>
      <li>
        <div class="text-1">
          <div class="icon-box" style="background: #DCF5F0">
            <img src="@/assets/common/images/dashboard-icon-1.png" />
          </div>
        </div>
        <div class="text-2">
          <StatsLoaderComponent v-show="$parent.loarder3"/>
          <div
            class="count-text" v-if="!$parent.loarder3"
            v-text="Math.floor(stats.total_clients).toLocaleString()"
          ></div>
          Domains Added</div>
      </li>
      <li>
        <div class="text-1">
          <div class="icon-box" style="background: #d9f4f7">
            <img src="@/assets/common/images/dashboard-icon-5.png" />
          </div>
        
        </div>
        <div class="text-2">
          <StatsLoaderComponent v-show="$parent.loarder4"/>
          <div
            class="count-text" v-if="!$parent.loarder4"
            v-text="Math.floor(stats.link_delivered).toLocaleString()"
          ></div>
          Links Delivered</div>
      </li>
      <li>
        <div class="text-1">
          <div class="icon-box" style="background: #8F7AD833">
            <img src="@/assets/common/images/avgLink-icon.png" />
          </div>
         
        </div>
        <div class="text-2">
          <StatsLoaderComponent v-show="$parent.loarder5"/>
          <div
            class="count-text" v-if="!$parent.loarder5"
            v-text="stats.total_revenue"
          ></div>
          Total Spend</div>
      </li>

      <li>
        <div class="text-1">
          <div class="icon-box" style="background: #FFF1EB">
            <img src="@/assets/common/images/dashboard-icon-3.png" />
          </div>
          
        </div>
        <div class="text-2">
          <StatsLoaderComponent v-show="$parent.loarder6"/>
          <div
            class="count-text" v-if="!$parent.loarder6"
            v-text="Math.floor(stats.pending_links).toLocaleString()"
          ></div>
          Pending Links</div>
      </li>
      <li>
        <div class="text-1">
          <div class="icon-box" style="background: #D9E8FF">
            <img src="@/assets/common/images/dashboard-icon-6.png" />
          </div>
        
        </div>
        <div class="text-2">
          <StatsLoaderComponent v-show="$parent.loarder7"/>
          <div
            class="count-text" v-if="!$parent.loarder7"
            v-text="stats.avg_link_cost"
          ></div>
          Avg. Link Cost</div>
      </li>
      <li>
        <div class="text-1">
          <div class="icon-box" style="background: #ebdee8">
            <img src="@/assets/common/images/avgDelivered-icon.png" />
          </div>
        </div>
        <div class="text-2">
          <StatsLoaderComponent v-show="$parent.loarder8"/>
          <div
            class="count-text" v-if="!$parent.loarder8"
            v-text="stats.avg_da_delivered"
          ></div>
          Avg. DA Delivered</div>
      </li>
    </ul>
  </div>
</template>

<script>
import "@vuepic/vue-datepicker/dist/main.css";
import { numberFormat } from '@/prod.js';
import StatsLoaderComponent from "@/components/StatsLoaderComponent.vue";

export default {
  components: {
    StatsLoaderComponent,
  },
  props: ['userStatsData'],
  watch: {
    userStatsData() {
        this.stats.total_orders = this.userStatsData.total_orders;
        this.$parent.loarder1 = false;
        this.stats.total_links = this.userStatsData.total_links;
        this.$parent.loarder2 = false;
        this.stats.total_clients = this.userStatsData.total_clients;
        this.$parent.loarder3 = false;
        this.stats.link_delivered = this.userStatsData.link_delivered;
        this.$parent.loarder4 = false;
        this.stats.total_revenue = numberFormat(this.userStatsData.total_revenue,'currency');
        this.$parent.loarder5 = false;
        this.stats.pending_links = this.userStatsData.pending_links;
        this.$parent.loarder6 = false;
        this.stats.avg_link_cost = numberFormat(this.userStatsData.avg_link_cost,'currency');
        this.$parent.loarder7 = false;
        this.stats.avg_da_delivered = numberFormat(this.userStatsData.avg_da_delivered,'number');
        this.$parent.loarder8 = false;
    },
  },
  mounted() {},
  data() {
    return {
      stats: {
        total_orders: 0,
        total_links: 0,
        total_clients: 0,
        link_delivered: 0,
        total_revenue: 0,
        pending_links: 0,
        avg_link_cost: 0,
        avg_da_delivered: 0,
      }
    };
  },
  methods: {},
};
</script>
