<template>
  <div class="editor-dashboard-sec-2 approval-list-sec draft-order-sec">
    <div class="table-responsive">
      <table class="addon-table">
        <tr>
          <th v-if="dashboardDraft">Order Title</th>
          <th v-else @click="sortByColumn('order_title')">
            Order Title 
            <span><i class="fas fa-sort"></i></span>
          </th>
          <th>Plan Submitted For</th>
          <th>Created By</th>
          <th>Updated By</th>
          <th>Approved By</th>
          <th>Updated At</th>
          <th>Total</th>
          <th>Total Links</th>
          <th>Type</th>
          <th v-if="dashboardDraft">Date Created</th>
          <th v-else @click="sortByColumn('created_at')">
            Date Created  
            <span><i class="fas fa-sort"></i></span>
          </th>
          <th>Actions</th>
        </tr>
        <tr v-for="draftRow in draft_orders" :key="draftRow.id" :class="{ draftApproved: draftRow.approved_at }">
          <td>{{ draftRow.order_title }}</td>
          <td>{{ draftRow.user_id }}</td>
          <td>{{ draftRow.created_by }}</td>
          <td>{{ draftRow.updated_by }}</td>
          <td>{{ draftRow.approved_by }}</td>
          <td>{{ draftRow.updated_at }}</td>
          <td>{{ numberFormat(draftRow.amount,'currency') }}</td>
          <td>{{ draftRow.links }}</td>
          <td>{{ draftRow.order_status == 'pending' ? 'new plan' : draftRow.order_status }}</td>
          <td>{{ draftRow.date }}</td>
          <td colspan="2" class="action-btns">
            <!-- <router-link :to="`/product/${draftRow.id}`">Proceed Order</router-link>
            <a href="javascript:;" v-b-modal.modal-box-4 @click="currentDraft(draftRow.id)">Mark Approved</a>
            <a href="javascript:;" @click="deleteDraft(draftRow.id)" class="btn-danger">Remove Order</a> -->
            <!-- <a href="#" class="btn-secondary">Proceed Order</a> -->
            <div class="btn-group">
              <button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown">
                ...
              </button>

              <ul class="dropdown-menu" role="menu">
                <li><router-link :to="draftRow.order_type == 'normal' ? `/product/${draftRow.id}` : `/missed-opportunity-order/${draftRow.id}`" class="proceed-text">Review Order</router-link></li>
                <li><a href="javascript:;" v-b-modal.modal-box-4 @click="currentDraft(draftRow.id)">Mark Approved</a></li>
                <li><a href="javascript:;" @click="deleteButtonPopupDraft(draftRow.id)">Remove Order</a></li>
              </ul>

            </div>
          </td>
        </tr>
      </table>
      <p class="article-text" v-if="draftOrdersLength == false">
        You Have No Orders In Draft.
      </p>
      <draftModals @approvedDraft="approvedDraft"></draftModals>
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
import draftModals from "@/components/orderLinksComponents/AllModals.vue";
import { numberFormat } from '@/prod.js';
import swal from "sweetalert2";
const toast = useToast();
import jquery from "jquery";
export default {
  props: ['draft_orders','dashboardDraft'],
  components: {
    draftModals,
  },
  mounted() {

  },
  data() {
    return {
      numberFormat,
      loading: false,
      filter: {
        date_form: "",
        date_to: "",
      },
      date: new Date(),
      dateFormat: "MM-dd-yyyy",
      options: {
        format: "MM/DD/YYYY",
        useCurrent: false,
      },
      currentLink: '',
      order: 'desc',
    };
  },
  methods: {
    async currentDraft(id) {
      this.currentLink = id;
    },
    async deleteDraft(delete_id) {
      this.loading = true;
      try {
        let res = await this.$store.dispatch("Product/deleteDraftOrderAction",
          { 'id': delete_id },
          {
            root: true,
          });
        if (res.success) {
          this.$parent.getDraftOrder();
          this.loading = false;
          toast.success(res.message, { timeout: 3000 }, window.location.reload());
        }
      } catch (error) {
        this.loading = false;
        this.errors = error.response.data.errors;
        this.$router.push("/dashboard");
      }
    },

    async approvedDraft(approvedAt) {
      this.$parent.loading = true;
      try {
        let res = await this.$store.dispatch("Product/approvedDraftOrderAction", {
          id: this.currentLink,
          approved_at: approvedAt
        },
          {
            root: true,
          });
        if (res.success) {
          this.$parent.getDraftOrder();
          jquery(".btn-close").trigger("click");
          this.$parent.loading = false;
          toast.success(res.message, { timeout: 3000 });
        }
      } catch (error) {
        this.$parent.loading = false;
        this.errors = error;
      }
    },

    async deleteButtonPopupDraft(id) {
      swal.fire({
        title: 'Are you sure?',
        text: "On click delete your draft will be removed from the list!",
        icon: 'warning',
        width: 600,
        showCancelButton: true,
        confirmButtonColor: '#00b6cb',
        cancelButtonColor: '#ff0e52',
        confirmButtonText: 'Delete'
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteDraft(id);
        }
      });
      },
      sortByColumn(column) {
        this.order = (this.order == 'asc') ? 'desc' : 'asc';
        this.$parent.getSortedResults(column,this.order);
      },
  },
  computed: {
    draftOrdersLength() {
      return this.draft_orders ? true : false;
    },
  }
};
</script>
