<template>
  <div class="col-md-6">
    <div class="links-status-report-box-sec">
      <h3 class="text-heading-2">Link Status Report</h3>
      <LinkStatusPieChart :client-status-data="clientStatusData" />
    </div>
  </div>
  <div class="col-md-6">
    <div class="links-by-da-box-sec">
      <!-- <label class="switch">
        <input type="checkbox" id="togBtn" />
        <div class="slider round" @click="isVisible = !isVisible">
          <span class="off" :class="{active: !isVisible}">Links by DA</span>
          <span class="on" :class="{active: isVisible}">Links by DR</span>
        </div>
      </label> -->
      <!-- <div v-if="!isVisible">
        <DAPieChart v-if="loading == false" :DA-status-data="DAStatusData" />
      </div>
      <div v-else>
        <DRPieChart v-if="loading == false" :DRStatusData="DRStatusData" />
      </div> -->
      <h3 class="text-heading-2">Links by DA</h3>
      <DAPieChart v-if="loading == false" :DA-status-data="DAStatusData" />
    </div>
  </div>
</template>

<script>
import LinkStatusPieChart from "@/components/LinkStatusPieChart.vue";
import DAPieChart from "@/components/DAPieChart.vue";
// import DRPieChart from "@/components/DRPieChart.vue";
// import { numberFormat } from '@/prod.js';
export default {
  props: ["DAStatusChartData", "clientStatusChartData"],
  watch: {
    clientStatusChartData() {
      this.clientStatusData = this.clientStatusChartData;
    },
    DAStatusChartData() {
      this.DAStatusData = this.DAStatusChartData.data;
    },
    // DRStatusChartData() {
    //   this.DRStatusData = this.DRStatusChartData.data;
    // }
  },
  components: {
    LinkStatusPieChart,
    DAPieChart,
    // DRPieChart,
  },

  mounted() {
  },
  data() {
    return {
      loading: false,
      isVisible: false,
      isToggled: false,
      clientStatusData: [],
      DAStatusData: [],
      // DRStatusData: [],
    };
  },

  methods: {
  },
  computed: {
  },
};
</script>

<style>
.links-by-da-box-sec .switch {
  position: relative;
  display: inline-block;
  width: 220px;
  height: 34px;
  z-index: 4;
}

.links-by-da-box-sec .switch input {
  display: none;
}

.links-by-da-box-sec .switch  .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #EDF0F7;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.links-by-da-box-sec .switch .on,
.links-by-da-box-sec .switch .off{
    padding: 6px 0px;
    display: inline-block;
    font-size: 14px;
    border-radius: 50px;
    color: #0A0E31;
    width: 110px;
    text-align: center;
}



/*------ ADDED CSS ---------*/
.links-by-da-box-sec .switch  .active {
    background-color: #00b6cb;
    color: #fff;

}

/*--------- END --------*/

/* Rounded sliders */
.links-by-da-box-sec .switch .slider.round {
  border-radius: 34px;
}

</style>