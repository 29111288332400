<template>
  <div class="home">
    <LoaderComponent v-show="loading" />
    <HeaderView />
    <section
      class="dashboard-page-main-sec order-page-main-sec track-order-main-sec agency-design-grid-right draft-order-page-sec"
    >
      <div class="row">
        <div class="col-md-12">
          <div class="text-heading-2">Draft Orders</div>
          <div class="editor-dashboard-sec-2 approval-list-sec draft-order-sec">
            <div class="order-list-tabs">
              <div class="order-input-field">
                <div class="date-picker-box">
                  <div class="form-row">
                    <div class="col-md-12">
                      <label>Filter by date</label>
                      <div
                        class="input-daterange input-group"
                        id="m_datepicker"
                      >
                        <DatePickerComponent
                          :minDate="minDate"
                          :maxDate="maxDate"
                          :options="options"
                          :position="position"
                          :refVal="'datepickerMenu'"
                          :orderDate="order_date"
                          @getSearchData="getSearchData"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="download-icon-list">
                  <div @click="downloadFile()" class="top_icons">
                    <i
                      class="fas fa-file-export"
                      v-b-tooltip.hover.top="'Export CSV'"
                    ></i>
                  </div>
                  <div class="per-page-right-box">
                    <div class="select-item">
                      <select
                        @change="changePagination()"
                        v-model="filter.per_page"
                      >
                        <option>50</option>
                        <option>100</option>
                        <option>200</option>
                        <option>500</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <DraftOrderView :draft_orders="draft_orders" />
            <div class="total-records-list">
              <p>Showing {{ rows.length }} of {{ totalRecords }} records</p>
            </div>
          </div>
        </div>
      </div>
      <div class="pagination-list">
        <paginate
          :click-handler="getDraftOrder"
          :prev-text="'Prev'"
          :next-text="'Next'"
          :page-count="filter.last_page"
          :page-range="3"
          :margin-pages="2"
          :container-class="'className'"
          :records="filter.length"
          v-model="filter.page"
          :per-page="filter.per_page"
        >
        </paginate>
      </div>
    </section>
  </div>
</template>

<script>
import HeaderView from "@/components/layout/HeaderComponent.vue";
import LoaderComponent from "@/components/LoaderComponent.vue";
import DraftOrderView from "@/components/dashboardComponents/draftOrder.vue";
import Paginate from "vuejs-paginate-next";
import DatePickerComponent from "@/components/DatePickerComponent.vue";

export default {
  components: {
    DatePickerComponent,
    HeaderView,
    LoaderComponent,
    DraftOrderView,
    Paginate,
  },
  mounted() {
    this.getDraftOrder();
  },
  data() {
    return {
      loading: false,
      date: new Date(),
      options: {
        format: "MM/DD/YYYY",
        useCurrent: false,
      },
      order_date: "",
      minDate: new Date(new Date().setMonth(new Date().getMonth() - 1)),
      maxDate: new Date(new Date().setMonth(new Date().getMonth() + 1)),
      filter: {
        s: "",
        e: "",
        columnTo: "id",
        orderBy: "desc",
        per_page: 50,
        page: 1,
        last_page: 1,
        length: "",
      },
      totalRecords: 0,
      rows: [],
      paginationData: {},
      draft_orders: [],
      selectedRange: "",
      ranges: {
        Today: [new Date(), new Date()],
        Yesterday: [
          new Date(new Date().setDate(new Date().getDate() - 1)),
          new Date(new Date().setDate(new Date().getDate() - 1)),
        ],
        "Last 7 Days": [
          new Date(new Date().setDate(new Date().getDate() - 7)),
          new Date(),
        ],
        "Last 30 Days": [
          new Date(new Date().setDate(new Date().getDate() - 30)),
          new Date(),
        ],
        "This Month": [
          new Date(new Date().getFullYear(), new Date().getMonth(), 1),
          new Date(),
        ],
        "Last Month": [
          new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
          new Date(new Date().getFullYear(), new Date().getMonth(), 0),
        ],
        "This Year": [new Date(new Date().getFullYear(), 0, 1), new Date()],
        "Custom Range": [new Date(), new Date()],
      },
      position: "left",
    };
  },
  methods: {
    getSearchData(value) {
      this.order_date = value;
      this.getDraftOrderSearch();
    },

    async getDraftOrder() {
      this.loading = true;
      this.filter.s = this.order_date ? this.order_date[0] : "";
      this.filter.e = this.order_date ? this.order_date[1] : "";
      try {
        let res = await this.$store.dispatch(
          "Product/getDraftOrderAction",
          this.filter
        );
        this.draft_orders = res.data;
        this.paginationData = res;
        this.totalRecords = res.meta.total;
        this.rows = res.data;
        this.filter.page = res.meta.current_page;
        this.filter.per_page = res.meta.per_page;
        this.filter.last_page = res.meta.last_page;
        this.filter.length = res.meta.total;
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },

    changePagination() {
      this.filter.page = 1;
      this.getDraftOrder();
    },

    getDraftOrderSearch() {
      this.filter.page = 1;
      this.getDraftOrder();
    },

    getSortedResults(column, order) {
      this.filter.columnTo = column;
      this.filter.orderBy = order;
      this.getDraftOrder();
    },
    downloadFile() {
      // Convert Object to JSON if not
      var jsonObject = this.draft_orders;
      var array =
        typeof jsonObject != "object" ? JSON.parse(jsonObject) : jsonObject;

      var Head = [
        [
          '"Order Title"',
          '"Plan Submitted For"',
          '"Created By"',
          '"Updated By"',
          '"Approved By"',
          '"Updated At"',
          '"Total"',
          '"Links"',
          '"Type"',
          '"Date Created"',
        ],
      ];

      var row = [];
      for (var i = 0; i < array.length; i++) {
        row.push({
          key1: '"' + array[i].order_title + '"',
          key2: '"' + array[i].user_id + '"',
          key3: '"' + array[i].created_by + '"',
          key4: '"' + array[i].updated_by + '"',
          key5: '"' + array[i].approved_by + '"',
          key6: '"' + array[i].updated_at + '"',
          key7: '"$' + array[i].amount + '"',
          key8: '"' + array[i].links + '"',
          key9:
            '"' +
            (array[i].order_status == "pending"
              ? "new plan"
              : array[i].order_status) +
            '"',
          key10: '"' + array[i].date + '"',
        });
      }

      for (var item = 0; item < row.length; ++item) {
        Head.push([
          row[item].key1,
          row[item].key2,
          row[item].key3,
          row[item].key4,
          row[item].key5,
          row[item].key6,
          row[item].key7,
          row[item].key8,
          row[item].key9,
          row[item].key10,
        ]);
      }

      var csvRows = [];
      for (var cell = 0; cell < Head.length; ++cell) {
        csvRows.push(Head[cell].join(","));
      }

      var csvString = csvRows.join("\n");
      let csvFile = new Blob([csvString], {
        type: "text/csv",
      });
      let downloadLink = document.createElement("a");
      downloadLink.download = "Draft-Order.csv";
      downloadLink.href = window.URL.createObjectURL(csvFile);
      downloadLink.style.display = "none";
      document.body.appendChild(downloadLink);
      downloadLink.click();
    },
  },
};
</script>
