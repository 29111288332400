import { AgencyUserServices } from '@/services/agencyUserServices';
export default {
    namespaced: true,

    actions:{

        async addUsersDataAction(_, payload) {
            let res = await AgencyUserServices.addUsersDataAPI(payload);
            let data = res.data;
            return data;
        },

        async getUserListDataAction (_, payload){
            let res = await AgencyUserServices.getUserListDataAPI(payload);
            let data = res.data;
            return data;
        },

        async getEditUserDataAction (_, payload){
            let res = await AgencyUserServices.getEditUserDataAPI(payload);
            let data = res.data;
            return data;
        },

        async updateUserDataAction (_, payload){
            let res = await AgencyUserServices.updateUserDataAPI(payload);
            let data = res.data;
            return data;
        },
        async deleteUserDataAction (_, payload){
            let res = await AgencyUserServices.deleteUserDataAPI(payload);
            let data = res.data;
            return data;
        },
        
        
    }
}