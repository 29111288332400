<template>
  <div class="chart-box DA-links-chart">
    <vue3-chart-js v-if="loading == false" v-bind="{ ...horizontalBarChart }" class="chart-circle" />
    <p class="article-text" v-if="showMessage">No Data to Display</p>
  </div>
</template>

<script>
import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";

export default {
  props: ['DAStatusData'],
  components: {
    Vue3ChartJs,
  },

  mounted() {
    let vm = this;
    vm.updateGraph();
  },

  data() {
    return {
      loading: true,
      showMessage: false,
      horizontalBarChart: {
        type: "doughnut",
        data: {
          labels: [],
          datasets: [
            {
              backgroundColor: ["#FF0E52", "#8E30FF", "#FF7723", "#373E92", "#0263FF"],
              data: [],
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: true,
              position: 'left',
              labels: { 
                padding: 10,
                usePointStyle: true,
              },
            },
          },
        },
      },
    };
  },
  methods: {
    updateGraph() {
      this.loading = true;
      this.horizontalBarChart.data.datasets[0].data = [];
      this.horizontalBarChart.data.labels = [];
      if (this.DAStatusData.length > 0) {
        this.DAStatusData.forEach((d) => {
            this.horizontalBarChart.data.datasets[0].data.push(d.link_count);
            this.horizontalBarChart.data.labels.push(d.labels);
        });
        // this.horizontalBarChart.data.labels = ['DA 20 or Below', 'DA 30+', 'DA 40+', 'DA 50+', 'DA 60+'];
      }else{
        this.showMessage = true;
      }
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
  },
  watch: {
    DAStatusData: function () {
      if (this.DAStatusData.length < 1) {
        this.showMessage = true;
      } else {
        this.showMessage = false;
      }
      this.updateGraph();
    },
  },

};
</script>