<template>
  <div class="editor-dashboard-sec-2 approval-list-sec draft-order-sec live-link-list">
    <div class="table-responsive">
      <table class="addon-table">
        <tr>
          <th>Link ID</th>
          <th>Destination URL</th>
          <th>Live URL</th>
          <th>Anchor Text</th>
          <th>Live DA</th>
          <th>Published Date</th>
        </tr>
        <tr v-for="link in live_links" :key="link.id">
          <td>{{ link.id }}</td>
          <td class="destination-text" v-b-tooltip.hover.top="link.destination_link">
            <a @click="redirectURL(link.destination_link)"
              ><span>{{ link.destination_link }}</span>
              <i
                v-if="
                  link.destination_link !== true
                    ? link.destination_link
                    : link.destination_link
                "
                class="fas fa-external-link-alt"
              ></i
            ></a>
          </td>
          <td class="destination-text">
            <span
              v-b-tooltip.hover.top="link.live_link"
              v-if="link.live_link !== true ? link.live_link : link.live_link"
              ><a @click="redirectURL(link.live_link)"
                ><span>{{ link.live_link }} </span>
                <i
                  v-if="
                    link.live_link !== true ? link.live_link : link.live_link
                  "
                  class="fas fa-external-link-alt"
                ></i></a
            ></span>
          </td>
          <td>{{ link.anchor_text }}</td>
          <td>{{ link.da }}</td>
          <td>{{ link.publish_date }}</td>
        </tr>
      </table>
      <p class="article-text" v-if="liveLinksLength == false">
        You Have No Live Links.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["live_links"],
  components: {},
  mounted() {},
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    redirectURL(url) {
      const pattern = /^(http:\/\/|https:\/\/)/i;
      const hasHttpOrHttps = pattern.test(url);

      let finalUrl = url;
      if (!hasHttpOrHttps) {
        finalUrl = "https://" + url;
      }

      const newTab = window.open(finalUrl, "_blank");
      if (newTab) {
        newTab.focus();
      }
    },
  },
  computed: {
    liveLinksLength() {
      return this.live_links ? true : false;
    },
  },
};
</script>
