<template>
  <div class="home">
    <LoaderComponent v-show="loading" />
    <HeaderView />
    <section
      class="order-page-main-sec invoice-page-main-sec .track-order-main-sec agency-design-grid-right missed-opportunitie-page-sec"
    >
      <div class="desktop-respovsive-main-sec">
        <div class="order-input-field invoice-history-content">
          <div class="reports-heading">
            <div class="text-heading">Premium Placements</div>
            <p>Uncover overlooked backlink opportunities for enhanced SEO.</p>
          </div>
        </div>

        <div class="editor-dashboard-sec-2">
          <div class="order-list-tabs">
            <div class="order-input-field">
              <div class="date-picker-box">
                <div class="form-row">
                  <div class="col-md-4">
                    <label>By Placement URL</label>
                    <b-form-input
                      id="input-2"
                      v-model="filter.placement_url"
                      placeholder="www.example.com"
                    ></b-form-input>
                  </div>
                  <div class="col-md-4">
                    <label>By Category</label>
                    <b-form-select
                      id="input-3"
                      v-model="filter.category"
                      :options="primaryCategoryList"
                      required
                    ></b-form-select>
                  </div>
                  <div class="col-md-4">
                    <label>By Status</label>
                    <b-form-select
                      id="input-3"
                      v-model="filter.status"
                      :options="opportunityStatuses"
                      required
                    ></b-form-select>
                  </div>
                  <b-col md="12">
                    <div class="btn-box-list">
                      <a
                        href="javascript:;"
                        class="btn-1"
                        @click="getMissedOpportunity('search')"
                        >Search
                      </a>
                      <a
                        href="javascript:;"
                        class="btn-1 btn-2"
                        @click="resetFilter()"
                        >Reset
                      </a>
                    </div>
                  </b-col>
                </div>
              </div>
            </div>
          </div>
          <div class="table-responsive">
            <form>
              <table class="addon-table">
                <tr>
                  <th>Select</th>
                  <th>Placement URL</th>
                  <th>DA</th>
                  <!-- <th>DR</th> -->
                  <th>Category</th>
                  <th>Sub Category</th>
                  <th>Spam Score</th>
                  <th>Status</th>
                  <th>Link Cost</th>
                  <th>Link Quantity</th>
                  <th>Total</th>
                  <th>Action</th>
                </tr>
                <tr
                  v-for="(missedOpportunity, i) in missed_opportunities"
                  :key="missedOpportunity.id"
                  :class="{
                    missedOpportunityGreen:
                      missedOpportunity.status == 'sent to client',
                    missedOpportunityBlue:
                      missedOpportunity.status == 'Ordered',
                  }"
                >
                  <td>
                    <div class="checkbox">
                      <label :for="'checkbox-' + i" style="margin-bottom: -2px">
                        <input
                          type="checkbox"
                          :id="'checkbox-' + i"
                          :value="missedOpportunity.id"
                          v-model="checkedItem[i]"
                          @change="selectedUrl(i, missedOpportunity)"
                        />
                      </label>
                    </div>
                  </td>
                  <td
                    class="missedOpportunity-text"
                    v-b-tooltip.hover.top="missedOpportunity.placement_url"
                  >
                    <a @click="redirectURL(missedOpportunity.placement_url)"
                      ><span>{{ missedOpportunity.placement_url }}</span>
                      <i
                        v-if="
                          missedOpportunity.placement_url !== true
                            ? missedOpportunity.placement_url
                            : missedOpportunity.placement_url
                        "
                        class="fas fa-external-link-alt"
                      ></i
                    ></a>
                  </td>
                  <!-- <td>{{ missedOpportunity.exact_da }}</td>
                  <td>{{ missedOpportunity.exact_dr }}</td> -->
                  <td>{{ missedOpportunity.product }}</td>
                  <td>{{ missedOpportunity.category }}</td>
                  <td>{{ missedOpportunity.secondary_category }}</td>
                  <td>{{ missedOpportunity.spam_score }}</td>
                  <td>
                    {{
                      missedOpportunity.status == "sent to client"
                        ? "New"
                        : "Placed"
                    }}
                  </td>
                  <td>
                    {{ numberFormat(missedOpportunity.total_cost, "currency") }}
                  </td>
                  <td>
                    <div class="counter-box">
                      <input
                        class="quantity"
                        type="number"
                        name="name"
                        min="1"
                        step="1"
                        @keypress="validateInput"
                        @blur="
                          setDefaultIfEmpty(
                            i,
                            missedOpportunity,
                            missedOpportunity.total_cost
                          )
                        "
                        v-model="linkCounter[i]"
                        v-on:input="
                          calculateCost(
                            i,
                            missedOpportunity,
                            missedOpportunity.total_cost
                          )
                        "
                      />
                      <!-- <div class="arrow-box">
                        <i class="fas fa-angle-up" @click="changeCounter('1')"></i>
                        <i class="fas fa-angle-down" @click="changeCounter('-1')"></i>
                      </div> -->
                    </div>
                  </td>
                  <td>
                    {{
                      totalCost[i]
                        ? numberFormat(totalCost[i], "currency")
                        : numberFormat(missedOpportunity.total_cost, "currency")
                    }}
                  </td>
                  <!-- <td>
                    <div
                      class="btn-box"
                      @click="
                        deleteButtonPopupOpportunity(missedOpportunity.id)
                      "
                    >
                      <i class="fas fa-trash-alt" title="Discard"></i>
                    </div>
                  </td> -->
                  <td class="action-btns">
                    <div class="btn-group">
                      <button
                        type="button"
                        class="btn btn-primary dropdown-toggle"
                        data-toggle="dropdown"
                      >
                        ...
                      </button>

                      <ul class="dropdown-menu" role="menu">
                        <!-- <li><router-link :to="draftRow.order_type == 'normal' ? `/product/${draftRow.id}` : `/missed-opportunity-order/${draftRow.id}`" class="proceed-text">Review Order</router-link></li> -->
                        <li>
                          <a
                            href="javascript:;"
                            @click="
                              openOpportunitySidebar(missedOpportunity.id)
                            "
                            >View</a
                          >
                        </li>
                        <li>
                          <a
                            href="javascript:;"
                            @click="
                              deleteButtonPopupOpportunity(missedOpportunity.id)
                            "
                            >Reject</a
                          >
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
              </table>
            </form>
          </div>
          <div class="proceed-box">
            <div class="total-price-table-box proceed-pay-box">
              <div class="summary-box">
                <table style="width: 100%">
                  <tr>
                    <td>Placement URLs</td>
                    <td>{{ placementURLCount }}</td>
                  </tr>
                  <tr>
                    <td>Total Links</td>
                    <td>{{ totalLinksCount }}</td>
                  </tr>

                  <tr>
                    <td>Total:</td>
                    <td>
                      <b>USD {{ numberFormat(totalAmount, "currency") }}</b>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="4" style="padding: 0">
                      <a
                        v-if="buttonDisabled"
                        href="javascript:;"
                        class="btn-3 diableButtonTooltip"
                        v-b-tooltip.hover.top="'Please select the opportunity'"
                      >
                        Proceed to Complete Order
                      </a>
                      <a
                        v-else
                        href="javascript:;"
                        @click.prevent="proceed"
                        class="btn-3"
                      >
                        Proceed to Complete Order
                      </a>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="email-sideabar-box missedOpportunity-sideabar-box"
        :class="{ openOpportunitySidebar: isSidebarOpen }"
      >
        <div class="top-bar">
          <div class="text-heading"></div>
          <div class="icon-box">
            <i class="fas fa-times" @click="closeOpportunitySidebar"></i>
          </div>
        </div>
        <MissedOpportunityDetail
          :opportunityDetail="opportunityDetail"
        ></MissedOpportunityDetail>
      </div>
    </section>
  </div>
</template>
<style>
.swal2-container.swal2-center.swal2-backdrop-show div#swal2-html-container div#swalContent textarea#reasonTextarea {
    width: 100%;
    margin: 0;
}
</style>

<script>
import HeaderView from "@/components/layout/HeaderComponent.vue";
import LoaderComponent from "@/components/LoaderComponent.vue";
import MissedOpportunityDetail from "@/components/missedOpportunityComponents/MissedOpportunityDetailComponent.vue";

import "@vuepic/vue-datepicker/dist/main.css";
import { numberFormat } from "@/prod.js";
import LZString from "lz-string";
import url from "@/prod.js";
import Swal from "sweetalert2";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  components: {
    HeaderView,
    LoaderComponent,
    MissedOpportunityDetail,
  },
  mounted() {
    localStorage.removeItem("draft-order-id");
    this.CategoryList();
    // this.missedOpportunityStatus();
    this.getMissedOpportunity('direct');
  },
  data() {
    return {
      numberFormat,
      LZString,
      loading: false,
      appURL: url.appUrl,
      filter: {
        placement_url: "",
        category: null,
        status: null,
      },
      missed_opportunities: [],
      checkedItem: [],
      totalCost: [],
      placementURLCount: 0,
      totalLinksCount: 0,
      totalAmount: 0,
      links: [],
      isSidebarOpen: false,
      linkCounter: [],
      buttonDisabled: true,
      opportunityStatuses: [
        { text: "Select Status", value: null },
        { text: "New", value: "New" },
        { text: "Ordered", value: "Ordered" },
      ],
      primaryCategoryList: [{ text: "Select Category", value: null }],
      opportunityDetail: [],
      reason: "",
    };
  },
  methods: {
    redirectURL(url) {
      const pattern = /^(http:\/\/|https:\/\/)/i;
      const hasHttpOrHttps = pattern.test(url);

      let finalUrl = url;
      if (!hasHttpOrHttps) {
        finalUrl = "https://" + url;
      }

      const newTab = window.open(finalUrl, "_blank");
      if (newTab) {
        newTab.focus();
      }
    },
    proceed() {
      if (!this.buttonDisabled) {
        this.loading = true;
        this.links = this.links.filter(Boolean);
        localStorage.setItem(
          "opportunity-links",
          LZString.compress(JSON.stringify(this.links))
        );
        window.location = this.appURL + "/missed-opportunity-order";
      }
    },
    async getMissedOpportunity(type) {
      this.loading = true;
      try {
        let res = await this.$store.dispatch(
          "MissedOpportunity/getMissedOpportunityDataAction",
          this.filter
        );
        if(res.data.length < 1 && type == 'direct'){
          this.$router.push("/dashboard");
        }
        this.missed_opportunities = res.data;
        for (let i = 0; i < res.data.length; i++) {
          this.linkCounter.push(1);
        }
        this.rows = res.data;
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },
    openOpportunitySidebar(id) {
      var arr = this.missed_opportunities;
      arr = arr.filter((x) => x.id === id);
      this.opportunityDetail = arr[0];
      setTimeout(() => (this.isSidebarOpen = true), 500);
      setTimeout(() => (this.isSidebarClose = false), 500);
    },

    closeOpportunitySidebar() {
      setTimeout(() => (this.isSidebarOpen = false), 500);
    },
    calculateCost(key, link, linkCost) {
      // if (this.linkCounter[key] === "" || this.linkCounter[key] < 1) {
      //   this.linkCounter[key] = 1; // If the input is empty or less than 1, set it to 1
      // }
      const totalVal = linkCost * this.linkCounter[key];
      this.totalCost[key] = totalVal;
      if (this.checkedItem[key]) {
        this.selectedUrl(key, link);
      }
    },
    validateInput(event) {
      // Get the key that was pressed
      const key = event.key;

      // Check if the key is a number (0-9)
      if (!(key >= "0" && key <= "9")) {
        // If the key is not a number, prevent default behavior
        event.preventDefault();
      }
    },
    setDefaultIfEmpty(key, link, linkCost) {
      // If the input field is empty, set it to 1
      if (
        this.linkCounter[key] === "" ||
        this.linkCounter[key] === 0 ||
        this.linkCounter[key] === undefined
      ) {
        this.linkCounter[key] = 1;
        this.calculateCost(key, link, linkCost);
      }
    },
    selectedUrl(key, link) {
      if (this.checkedItem[key]) {
        if (!this.links[key]) {
          this.links[key] = {};
        }
        this.links[key][link.placement_url] = [];
        for (var i = 0; i < this.linkCounter[key]; i++) {
          this.links[key][link.placement_url][i] = {
            ...link,
            content_approval: false,
            outreacher_id: link.outreacher_id
          };
        }
      } else {
        this.uncheckedItem(key);
      }
      this.updateTotal();
    },
    uncheckedItem(key) {
      delete this.links[key];
    },
    updateTotal() {
      this.totalLinksCount = 0;
      this.totalAmount = 0;
      this.placementURLCount = 0;
      this.placementURLCount = Object.keys(this.checkedItem).filter(
        (key) => this.checkedItem[key] === true
      ).length;
      if (this.placementURLCount > 0) {
        for (const key in this.links) {
          if (Object.hasOwnProperty.call(this.links, key)) {
            const obj = this.links[key];
            for (const placementUrlKey in obj) {
              if (Object.hasOwnProperty.call(obj, placementUrlKey)) {
                const arr = obj[placementUrlKey];
                arr.forEach((v) => {
                  this.totalLinksCount += v.link_count;
                  this.totalAmount += v.total_cost;
                });
              }
            }
          }
        }
      }
    },
    async deleteButtonPopupOpportunity(id) {
      const vm = this;

      Swal.fire({
        title: "Are you sure?",
        html: `
          <div id="swalContent">
            <p id="swalText">On click delete, your opportunity will be discarded from the list!</p>
            <textarea id="reasonTextarea" class="swal2-textarea" placeholder="Enter your reason here" @input="adjustTextareaWidth"></textarea>
          </div>
        `,
        icon: "warning",
        width: 600,
        showCancelButton: true,
        confirmButtonColor: "#00b6cb",
        cancelButtonColor: "#ff0e52",
        confirmButtonText: "Delete",
        preConfirm: () => {
          const reason = Swal.getPopup()
            .querySelector("#reasonTextarea")
            .value.trim(); // Trim to remove leading and trailing spaces
          if (!reason) {
            Swal.showValidationMessage("Reason is required");
          }
          return reason;
        },
      }).then((result) => {
        if (result.isConfirmed) {
          vm.reason = result.value;
          vm.removeOpportunity(id);
        }
      });
    },
    async removeOpportunity(delete_id) {
      this.loading = true;
      try {
        let res = await this.$store.dispatch(
          "MissedOpportunity/deleteOpportunityOrderAction",
          { id: delete_id, reason: this.reason },
          {
            root: true,
          }
        );
        if (res.success) {
          this.loading = false;
          toast.success(
            res.message,
            { timeout: 3000 },
            window.location.reload()
          );
        }
      } catch (error) {
        this.loading = false;
        this.errors = error.response.data.errors;
        this.$router.push("/dashboard");
      }
    },
    // async missedOpportunityStatus() {
    //   try {
    //     let res = await this.$store.dispatch("MissedOpportunity/getMissedOpportunityStatusDataAction");
    //     res.forEach((item) => {
    //       this.opportunityStatuses.push({ value: item.status, text: item.status });
    //     });
    //   } catch (e) {
    //     this.loading = false;
    //   }
    // },
    async CategoryList() {
      try {
        let res = await this.$store.dispatch("MissedOpportunity/getAllCategoriesDataAction");
        res.forEach((item) => {
          this.primaryCategoryList.push({ text: item.name, value: item.id });
        });
      } catch (e) {
        this.loading = false;
      }
    },
    resetFilter() {
      this.filter.placement_url = "";
      this.filter.category = null;
      this.filter.status = null;
      this.getMissedOpportunity('direct');
    },
  },
  watch: {
    placementURLCount() {
      if (this.placementURLCount > 0) {
        this.buttonDisabled = false;
      } else {
        this.buttonDisabled = true;
      }
    },
  },
};
</script>
