import { BillingServices } from '@/services/billingServices';
export default {
    namespaced: true,

    actions:{

        async getBillingDataAction (_, payload){
            let res = await BillingServices.getBillingDataAPI(payload);
            let data = res.data;
            return data;
        },
        async getInvoiceDataAction (_, val){
            let res = await BillingServices.getInvoiceDataAPI(val);
            let data = res.data;
            return data;
        }, 

        async sendInvoiceEmailDataAction (_, val){
            let res = await BillingServices.sendInvoiceEmailDataAPI(val);
            let data = res.data;
            return data;
        }, 
        
    }
}