import { PackageServices } from '@/services/packageServices';
export default {
    namespaced: true,

    actions:{

        async getPackagesListDataAction(_, payload) {
            let res = await PackageServices.getAllPackagesAPI(payload);
            let data = res.data;
            return data;
        },
        
        async getPackageCountAction (){
            let res = await PackageServices.packageCountDataAPI();
            let data = res.data;
            return data;
        }, 
    }
}