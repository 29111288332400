<template>
  <div class="chart-box link-status-chart">
    <vue3-chart-js v-if="loading == false"  v-bind="{ ...barChart }"  />
  </div>
</template>

<script>
import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";

export default {
  props:['clientStatusData'],
  components: {
    Vue3ChartJs,
  },

  mounted () {
    let vm = this;
    vm.updateGraph();
  },

  data() {
    return {
      loading: true,
      barChart: {
        type: "bar",
        data: {
          labels: ["Not Delivered", "Outreaching", "In Writing", "In Submission", "Delivered"],
          datasets: [
            {
              backgroundColor: ["#f4516c", "#ffb822", "#00b6cb", "#36a3f7", "#41b883"],
              data: [],
              hoverBackgroundColor: ["#f4516c", "#ffb822", "#00b6cb", "#36a3f7", "#41b883"],
            },
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
            plugins: {
              legend: {
                position: 'bottom',
                display: false,
              },
          }
        },
      },
    };
  },
  methods: {
      updateGraph(){
        this.loading = true;
        this.barChart.data.datasets[0].data = [];
        this.barChart.data.labels = [];
        // console.log(this.clientStatusData[0]);
        this.clientStatusData.forEach((d) => {
            this.barChart.data.datasets[0].data.push(d.link_count);
            this.barChart.data.labels.push(d.labels);
        });
        // this.barChart.data.labels = ["Not Delivered", "Outreaching", "In Writing", "In Submission", "Delivered"];
        setTimeout(() => {
          this.loading = false;
        }, 500);
      },
    },
    watch: {
        clientStatusData : function(){
            this.updateGraph();
        }
    },   

};
</script>