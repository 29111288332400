import apiInstance from "./apiInstance";

export class UserProfileServices{

    static getAllUserProfileDataAPI(){
        return apiInstance.get('/api/get-user-profile');
    }

    static postAllUpdateUserDataAPI(payload){
        return apiInstance.post('/api/update-user',payload);
    }

    static postSaveimageDataAPI(payload){
        return apiInstance.post('/api/save-user-img',payload);
    }

    static postAlternateEmailDataAPI(payload){
        return apiInstance.post('/api/alternate-email',payload);
    }

    static changePasswordAPI(payload){
        return apiInstance.post('/api/change-password',payload);
    }

    static removeAlternateEmailAPI(payload){
        return apiInstance.post('/api/remove-alternate-email',payload);
    }

    static addNewCardAPI(payload){
        return apiInstance.post('/api/add-new-card',payload);
    }

    static removeCardAPI(payload){
        return apiInstance.post('/api/remove-card',payload);
    }
    
    static getProfileAddressDataAPI(){
        return apiInstance.get('/api/get-profile-address');
    }
}