import { ReportServices } from '@/services/agencyReportServices';
export default {
    namespaced: true,

    actions:{

        async DomainReportDataAction(_, payload){
            let res = await ReportServices.DomainReportAPI(payload);
            let data = res.data;
            return data;
        }, 

        async DomainReportDetailDataAction(_, payload){
            let res = await ReportServices.DomainReportDetailAPI(payload);
            let data = res.data;
            return data;
        }, 

        async OrderReportDataAction(_, payload){
            let res = await ReportServices.OrderReportAPI(payload);
            let data = res.data;
            return data;
        }, 

        async OrderReportDetailDataAction(_, payload){
            let res = await ReportServices.OrderReportDetailAPI(payload);
            let data = res.data;
            return data;
        }, 

        async OrderGraphDataAction(_, payload){
            let res = await ReportServices.OrderGraphAPI(payload);
            let data = res.data;
            return data;
        },

        async getOrderIdDataAction(){
            let res = await ReportServices.getOrderIdAPI();
            let data = res.data;
            return data;
        }, 

        async DomainGraphDataAction(_, payload){
            let res = await ReportServices.DomainGraphAPI(payload);
            let data = res.data;
            return data;
        },

        async DestinationReportDataAction(_, payload){
            let res = await ReportServices.DestinationReportAPI(payload);
            let data = res.data;
            return data;
        }, 

        async DomainDestinationsReportDataAction(_, payload){
            let res = await ReportServices.getDomainDestinationsReportAPI(payload);
            let data = res.data;
            return data;
        },

        async SelectedDestinationsReportDataAction(_, payload){
            let res = await ReportServices.getSelectedDestinationsReportAPI(payload);
            let data = res.data;
            return data;
        },

    }
}