<template>
    <div class="home">
      <LoaderComponent v-show="loading" />
      <HeaderView />
      <section
        class="dashboard-page-main-sec order-page-main-sec track-order-main-sec agency-design-grid-right package-main-sec"
      >
        <div class="row">
          <div class="col-md-12">
            <div class="text-heading-2">Packages</div>
            <div class="editor-dashboard-sec-2 approval-list-sec draft-order-sec">
              <div class="order-list-tabs">
                <div class="order-input-field">
                  <div class="date-picker-box">
                    
                  </div>
                  <div class="download-icon-list">
                    <div class="per-page-right-box">
                      <div class="select-item">
                        <select
                          @change="changePagination()"
                          v-model="filter.per_page"
                        >
                          <option>50</option>
                          <option>100</option>
                          <option>200</option>
                          <option>500</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="table-responsive">
                <table class="addon-table">
                  <tr>
                    <th @click="sortByColumn('package_name')">
                      Package Name <span><i class="fas fa-sort"></i></span>
                    </th>
                    <th @click="sortByColumn('total_links')">
                      Total Links <span><i class="fas fa-sort"></i></span>
                    </th>
                    <th @click="sortByColumn('product')">
                      Product <span><i class="fas fa-sort"></i></span>
                    </th>
                    <th @click="sortByColumn('category')">
                      Category <span><i class="fas fa-sort"></i></span>
                    </th>
                    <th @click="sortByColumn('amount')">
                      Amount <span><i class="fas fa-sort"></i></span>
                    </th>
                    <th @click="sortByColumn('created_at')">
                      Date <span><i class="fas fa-sort"></i></span>
                    </th>
                    <th>Actions</th>
                  </tr>
                  <tr v-for="order in packages" :key="order.id">
                    <td>{{ order.package_name }}</td>
                    <td>{{ order.total_links }}</td>
                    <td>{{ order.product }}</td>
                    <td>{{ order.category }}</td>
                    <td>{{ numberFormat(order.amount, 'currency') }}</td>
                    <td>{{ order.date }}</td>
                    <td colspan="2" class="action-btns">
                      <router-link :to="`/product/${order.id}`"
                        >Review</router-link
                      >
                    </td>
                  </tr>
                </table>
                <div class="total-records-list">
                  <p>Showing {{ rows.length }} of {{ totalRecords }} records</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pagination-list">
          <paginate
            :click-handler="getUserList"
            :prev-text="'Prev'"
            :next-text="'Next'"
            :page-count="filter.last_page"
            :page-range="3"
            :margin-pages="2"
            :container-class="'className'"
            :records="filter.length"
            v-model="filter.page"
            :per-page="filter.per_page"
          >
          </paginate>
        </div>
      </section>
    </div>
  </template>
  
  <script>
  import HeaderView from "@/components/layout/HeaderComponent.vue";
  import LoaderComponent from "@/components/LoaderComponent.vue";
  import Paginate from "vuejs-paginate-next";
  import { numberFormat } from '@/prod.js';

  export default {
    components: {
      HeaderView,
      LoaderComponent,
      Paginate,
    },
    mounted() {
      this.getPackagesList();
    },
    data() {
      return {
        numberFormat,
        loading: false,
        date: new Date(),
        dateFormat: "dd-MM-yyyy",
        options: {
          format: "MM/DD/YYYY",
          useCurrent: false,
        },
        filter: {
          columnTo: "id",
          orderBy: "desc",
          per_page: 50,
          page: 1,
          last_page: 1,
          length: "",
          date_form: "",
          date_to: "",
          name: "",
          email: "",
        },
        paginationData: {},
        packages: [],
        totalRecords: 0,
        rows: [],
      };
    },
    methods: {
      async getPackagesList() {
        try {
          let res = await this.$store.dispatch(
            "Packages/getPackagesListDataAction",
            this.filter
          );
          if(res.data.length > 0){
            this.packages = res.data;
            this.paginationData = res;
            this.totalRecords = res.meta.total;
            this.rows = res.data;
            this.filter.page = res.meta.current_page;
            this.filter.per_page = res.meta.per_page;
            this.filter.last_page = res.meta.last_page;
            this.filter.length = res.meta.total;
            this.loading = false;
          }else{
            this.$router.push("/dashboard");
          }
        } catch (e) {
          this.loading = false;
        }
      },
      sortByColumn(column) {
        this.filter.orderBy = this.filter.orderBy == "asc" ? "desc" : "asc";
        this.getSortedResults(column, this.filter.orderBy);
      },
      getSortedResults(column, order) {
        this.filter.columnTo = column;
        this.filter.orderBy = order;
        this.getPackagesList();
      },
      changePagination() {
        this.filter.page = 1;
        this.getPackagesList();
      },
    },
    computed: {
      userData() {
        const data = this.$store.getters["Auth/getUser"];
        return {
          role: data.role,
        };
      },
    },
  };
  </script>
  