<template>
  <div class="home">
    <LoaderComponent v-show="loading" />
    <HeaderView />
    <section
      class="dashboard-page-main-sec order-page-main-sec track-order-main-sec agency-design-grid-right draft-order-page-sec domain-detail-page-sec"
    >
      <div class="row">
        <div class="col-md-12">
          <div class="order-input-field">
            <div class="reports-heading">
              <div class="text-heading-2">Orders Report</div>
              <p>Track all your orders and links</p>
            </div>
            <div class="date-picker-box">
              <div class="form-row">
                <div class="col-md-12">
                  <div class="input-daterange input-group" id="m_datepicker">
                    <label>Sort by Date</label>
                    <DatePickerComponent
                      :minDate="minDate"
                      :maxDate="maxDate"
                      :options="options"
                      :position="position"
                      :refVal="'datepickerMenu'"
                      :orderDate="filter.range"
                      @getSearchData="getSearchData"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="order-count-list domain-box">
            <ul>
              <li>
                <div class="text-1">
                  <div class="icon-box" style="background: #dcf5f0">
                    <img src="@/assets/common/images/dashboard-icon-1.png" />
                  </div>
                </div>

                <div class="text-2">
                  <StatsLoaderComponent v-show="loarder1"/>
                  <div class="count-text" v-if="!loarder1" v-text="stats.total_spent"></div>
                  Total Spent
                </div>
              </li>

              <li>
                <div class="text-1">
                  <div class="icon-box" style="background: #8f7ad833">
                    <img src="@/assets/common/images/avgLink-icon.png" />
                  </div>
                </div>

                <div class="text-2">
                  <StatsLoaderComponent v-show="loarder2"/>
                  <div class="count-text" v-if="!loarder2" v-text="stats.total_links"></div>
                  Total Links
                </div>
              </li>

              <li>
                <div class="text-1">
                  <div class="icon-box" style="background: #d9e8ff">
                    <img src="@/assets/common/images/dashboard-icon-6.png" />
                  </div>
                </div>

                <div class="text-2">
                  <StatsLoaderComponent v-show="loarder3"/>
                  <div class="count-text" v-if="!loarder3" v-text="stats.avg_order_value"></div>
                  Avg. Order Value
                </div>
              </li>
              <li>
                <div class="text-1">
                  <div class="icon-box" style="background: #ebdee8">
                    <img src="@/assets/common/images/avgDelivered-icon.png" />
                  </div>
                </div>

                <div class="text-2">
                  <StatsLoaderComponent v-show="loarder4"/>
                  <div class="count-text" v-if="!loarder4" v-text="stats.most_ordered_da"></div>
                  Most Ordered
                </div>
              </li>
            </ul>
          </div>
          <div class="editor-dashboard-sec-2 approval-list-sec draft-order-sec">
            <VueApexCharts
              height="350"
              :options="graphData.chartOptions"
              :series="graphData.series"
            ></VueApexCharts>
          </div>

          <div class="text-heading-2">Orders</div>

          <div class="editor-dashboard-sec-2 approval-list-sec draft-order-sec">
            <div class="table-responsive">
              <table class="addon-table">
                <tr>
                  <th @click="sortByColumn('id')">
                    Order ID <span><i class="fas fa-sort"></i></span>
                  </th>
                  <th @click="sortByColumn('order_value')">
                    Order Value <span><i class="fas fa-sort"></i></span>
                  </th>
                  <th @click="sortByColumn('total_links')">
                    Total Links <span><i class="fas fa-sort"></i></span>
                  </th>
                  <th @click="sortByColumn('avg_delivered_order')">
                    Order Delivered % <span><i class="fas fa-sort"></i></span>
                  </th>
                  <th @click="sortByColumn('avg_pending_order')">
                    Order Pending % <span><i class="fas fa-sort"></i></span>
                  </th>
                  <th @click="sortByColumn('created_at')">
                    Order Date <span><i class="fas fa-sort"></i></span>
                  </th>
                  <th>Action</th>
                </tr>
                <tr v-for="orderData in order_data" :key="orderData.order_id">
                  <td>
                    <router-link
                      :to="`/report-order-detail/${orderData.order_id}`"
                      >{{ orderData.order_id }}</router-link
                    >
                  </td>
                  <td>{{ numberFormat(orderData.order_value, "currency") }}</td>
                  <td>{{ orderData.total_links }}</td>
                  <td>{{ orderData.avg_delivered_order }}%</td>
                  <td>{{ orderData.avg_pending_order }}%</td>
                  <td>{{ orderData.created_at }}</td>
                  <td>
                    <div class="btn-box">
                      <router-link
                        :to="`/report-order-detail/${orderData.order_id}`"
                        v-b-tooltip.hover.top="'View'"
                        ><i class="fas fa-eye"></i
                      ></router-link>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import HeaderView from "@/components/layout/HeaderComponent.vue";
import LoaderComponent from "@/components/LoaderComponent.vue";
import { numberFormat } from "@/prod.js";
import VueApexCharts from "vue3-apexcharts";
import DatePickerComponent from "@/components/DatePickerComponent.vue";
import StatsLoaderComponent from "@/components/StatsLoaderComponent.vue";

export default {
  components: {
    HeaderView,
    LoaderComponent,
    DatePickerComponent,
    VueApexCharts,
    StatsLoaderComponent
  },
  mounted() {
    this.orderChartReports();
    this.getOrderReport();
  },
  data() {
    return {
      numberFormat,
      loading: false,
      loarder1: false,
      loarder2: false,
      loarder3: false,
      loarder4: false,
      max: 10,
      graphData: {
        series: [
          {
            name: "Links",
            data: [],
            color: "#00b6cb",
          },
        ],
        chartOptions: {
          chart: {
            height: 400,
            type: "area",
            zoom: {
              enabled: false,
            },
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "straight",
          },
          markers: {
            size: 5,
          },
          title: {
            text: "Links Ordered",
            align: "left",
          },
          grid: {
            row: {
              colors: ["transparent", "transparent"],
              opacity: 0.5,
            },
          },
          yaxis: {
            labels: {
              formatter: function (val) {
                return val.toFixed(0);
              },
            },
            min: 0,
            max: this.max,
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val;
              },
            },
          },
        },
      },
      date: new Date(),
      dateFormat: "MM-dd-yyyy",
      options: {
        format: "MM/DD/YYYY",
        useCurrent: true,
      },
      minDate: new Date(new Date().setMonth(new Date().getMonth() - 1)),
      maxDate: new Date(new Date().setMonth(new Date().getMonth() + 1)),
      filter: {
        columnTo: "",
        orderBy: "",
        range: null,
      },
      order_data: [],
      stats: {
        total_spent: 0,
        total_links: 0,
        avg_order_value: 0,
        most_ordered_da: 0,
      },
      selectedRange: "Custom Range",
      position: "right",
    };
  },
  methods: {
    getSearchData(value) {
      this.filter.range = value;
      this.orderChartReports();
      this.getOrderReport();
    },
    async getOrderReport() {
      this.loading = true;
      this.loarder1 = true;
      this.loarder2 = true;
      this.loarder3 = true;
      this.loarder4 = true;
      if (this.filter.range === null) {
        this.filter.range = [
          new Date(new Date().setMonth(new Date().getMonth() - 1)),
          new Date(),
        ];
      }
      try {
        let res = await this.$store.dispatch(
          "AgencyReport/OrderReportDataAction",
          this.filter
        );
        this.order_data = res.data;
        this.stats.total_spent = numberFormat(res.total_spent, "currency");
        this.loarder1 = false;
        this.stats.total_links = res.total_links;
        this.loarder2 = false;
        this.stats.avg_order_value = numberFormat(res.avg_order_value, "currency");
        this.loarder3 = false;
        this.stats.most_ordered_da = res.most_ordered_da;
        this.loarder4 = false;
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },

    async orderChartReports() {
      this.loading = true;
      if (this.filter.range === null) {
        this.filter.range = [
          new Date(new Date().setMonth(new Date().getMonth() - 1)),
          new Date(),
        ];
      }
      try {
        let res = await this.$store.dispatch(
          "AgencyReport/OrderGraphDataAction",
          this.filter
        );
        const val = Math.max(...res.data);
        this.max = val + 1;
        const yearArr = res.year;
        (this.graphData.chartOptions = {
          xaxis: {
            categories: res.label,
          },
          yaxis: {
            min: 0,
            max: this.max,
            labels: {
              formatter: function (val) {
                return val.toFixed(0);
              },
            },
          },
          tooltip: {
            x: {
              formatter: function (index) {
                index = index - 1;
                return yearArr[index];
              },
            },
          },
        }),
          (this.graphData.series = [
            {
              data: res.data,
            },
          ]);

        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },

    downloadFile() {
      var jsonObject = this.order_data;
      var array =
        typeof jsonObject != "object" ? JSON.parse(jsonObject) : jsonObject;

      var Head = [
        [
          '"Order Id"',
          '"Order Value"',
          '"Order Delivered %"',
          '"Order Pending %"',
          '"Order Start Date"',
        ],
      ];

      var selectedColumns = array.map(function (item) {
        return [
          '"' + item.order_id + '"',
          '"' + item.order_value + '"',
          '"' + item.avg_delivered_order + '"',
          '"' + item.avg_pending_order + '"',
          '"' + item.created_at + '"',
        ];
      });

      var csvRows = Head.concat(selectedColumns)
        .map(function (row) {
          return row.join(",");
        })
        .join("\n");

      var csvFile = new Blob([csvRows], {
        type: "text/csv",
      });

      var downloadLink = document.createElement("a");
      downloadLink.download = "Orders.csv";
      downloadLink.href = window.URL.createObjectURL(csvFile);
      downloadLink.style.display = "none";
      document.body.appendChild(downloadLink);
      downloadLink.click();
    },

    sortByColumn(column) {
      this.filter.orderBy = this.filter.orderBy == "asc" ? "desc" : "asc";
      this.getSortedResults(column, this.filter.orderBy);
    },

    getSortedResults(column, order) {
      this.filter.columnTo = column;
      this.filter.orderBy = order;
      this.getOrderReport();
    },
  },
};
</script>
