<template>
        <section class="notfound-second-page-sec">
            <div class="container">
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-6 col-12 order-lg-last">
                        <div class="notfound-sec-img">
                            <img src="@/assets/common/images/notfoundimg-2.png" alt="not-found-img">
                        </div>
                    </div>
                    <div class="col-lg-6 col-12 order-lg-first">
                        <div class="notfound-sec-content">
                            <h1 class="color-primary">404</h1>
                            <p> Sorry, the page you are looking for could not be found.</p>
                            <router-link :to="`/dashboard`">GO TO DASHBOARD</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
</template>

<style>
.notfound-second-page-sec {
    padding: 9rem 0;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(0, 193, 232);
    background: linear-gradient(184deg, rgba(0, 193, 232, 1) 0%, rgba(0, 212, 255, 1) 28%, rgba(82, 177, 196, 1) 69%, rgba(75, 154, 170, 1) 100%);
}

.notfound-second-page-sec .notfound-second-page-sec .row.align-items-center {
    margin-top: -3rem
}

.notfound-second-page-sec .notfound-sec-img {
    text-align: end
}

.notfound-second-page-sec .notfound-sec-content h1 {
    position: relative;
    left: 0rem;
    font-size: 10rem;
    font-weight: 700;
    margin-bottom: 0rem;
    color: #ffffff;
}

.notfound-second-page-sec .notfound-sec-content p {
    font-size: 1.7rem;
    font-weight: 400;
    line-height: 2.6rem;
    margin-bottom: 2rem;
    color: #ffffff;
}

.notfound-second-page-sec .notfound-sec-content a {
    background-color: #ffffff;
    border-radius: 3px;
    padding: 10px 30px 10px;
    font-size: 20px;
    color: #00b6cb;
    font-weight: 600;

}

@media only screen and (max-width:1366px) {
    .logo {
        margin-bottom: 0rem;
    }

    .notfound-second-page-sec .notfound-sec .row.align-items-center {
        margin-top: 0
    }

    .notfound-second-page-sec .notfound-sec-content h1 {
        font-size: 2.5rem;
    }

    .notfound-second-page-sec .notfound-sec-content h2 {
        font-size: 1.4rem;
        font-weight: 600;
    }

    .notfound-second-page-sec .notfound-sec-content p {
        font-size: 1.5rem;
        font-weight: 400;
        line-height: 2.3rem;
    }

}

@media only screen and (max-width:1200px) {
    .logo {
        margin-bottom: 1rem;
    }

    .notfound-second-page-sec .row.align-items-center {
        margin-top: 0
    }

    .notfound-second-page-sec .notfound-sec-content h1 {
        font-size: 2rem;
    }

    .notfound-second-page-sec .notfound-sec-content h2 {
        font-size: 1.2rem;
        font-weight: 600;
        line-height: 2.5rem;
    }

    .notfound-second-page-sec .notfound-sec-content p {
        font-size: 1.3rem;
        font-weight: 400;
        line-height: 2.1rem;
        margin-bottom: 2rem;
    }
}

@media only screen and (max-width:992px) {
    .notfound-second-page-sec {
        padding: 7rem 0
    }

    .notfound-sec .logo {
        display: flex;
        justify-content: center;
        margin-bottom: 0
    }

    .notfound-second-page-sec .notfound-sec-img {
        text-align: center;
        width: 60%;
        margin: 2rem auto 0
    }

    .notfound-sec-content {
        text-align: center
    }

    .notfound-second-page-sec .notfound-sec-content h1 {
        margin-top: 20px;
        font-size: 8rem;
    }

    .notfound-second-page-sec .notfound-sec-content h2 {
        font-size: 1.6rem;
        font-weight: 600;
        line-height: 2.5rem;
    }

    .notfound-second-page-sec .notfound-sec-content p {
        font-size: 2rem;
        line-height: 3rem
    }
}

@media only screen and (max-width:767px) {
    .notfound-second-page-sec {
        padding: 6rem 0
    }

    .notfound-sec .logo {
        width: 115px;
        margin: 0 auto 0.5rem;
    }

    .notfound-second-page-sec .notfound-sec-img {
        width: 75%;
        margin: 1rem auto
    }

    .notfound-second-page-sec .notfound-sec-content h1 {
        margin-top: 5px;
        font-size: 7rem;
        left: 0;
    }

    .notfound-second-page-sec .notfound-sec-content h2 {
        font-size: 1.6rem;
        line-height: 2.2rem;
    }

    .notfound-second-page-sec .notfound-sec-content p {
        font-size: 1.2rem;
        line-height: 1.8rem;
        margin-bottom: 20px;
    }

}
</style>