<template>
          <div class="recent-box-sec">
            <div class="table-responsive">
              <table class="addon-table">
                <tr
                  v-for="recentMessage in recent_messages"
                  :key="recentMessage.link_id"
                >
                  <td @click="messageRead(recentMessage.link_id,recentMessage.status)">
                    <!-- <router-link :to="`/order/${recentMessage.link_id}?tab=message`"> -->
                      <!-- <a :href="`/order/${recentMessage.link_id}?tab=message`"> -->
                        <a >
                    <span>{{ recentMessage.date }}</span>
                    <div class="text-1">
                      Link ID ({{ recentMessage.link_id }}) 
                      <i v-if="recentMessage.status === 'r'" class="bx bx-envelope-open"></i>
                      <i v-else class="bx bx-envelope"></i>
                    </div>
                    <div class="text-2" v-html="recentMessage.message"></div>
                    <!-- </router-link> -->
                    </a>
                  </td>
                </tr>
              </table>
              <p class="article-text" v-if="recent_messages.length == 0">
                You Have No Messages.
              </p>
            </div>
          </div>
</template>

<script>
export default {
  props: ['recent_messages'],
  components: {},
  mounted() {
    
  },
  data() {
    return {
      loading: true,
    };
  },
  methods: {
    async messageRead(id,status) {
      if(status == 'ur'){
        try {
          let res = await this.$store.dispatch("Dashboard/messageReadDataAction", id);
          if(res.success){
            // this.$router.push(`/order/${id}?tab=message`);
            window.location = `/order/${id}?tab=message`;
          }
        } catch (e) {
          console.log(e);
        }
      }else{
        // this.$router.push(`/order/${id}?tab=message`);
            window.location = `/order/${id}?tab=message`;
      }
    },
  },
computed: {
},
};
</script>
