import apiInstance from "./apiInstance";

export class LinkServices{

    static getAllLinksAPI(payload){
        return apiInstance.get(`/api/links-list/${payload.per_page}?page=${payload.page}`,{ params: payload });
    }

    static getAllLinkStatusAPI(payload){
        return apiInstance.get('/api/links/statuses',payload);
    }

    static getAgenciesUsersAPI(payload){
        return apiInstance.get('/api/get-agencies-toggle-users',payload);
    }

    static getCategoryAPI(){
        return apiInstance.get('/api/category-list');
    }

    static getEditLinksDataAPI(id){
        return apiInstance.get(`/api/products/${id}`);
    }

    static sendMessageDataAPI(payload){
        return apiInstance.post(`/api/send-message/${payload.id}`, payload);
    }

    static getdownloadDataAPI(id){
        return apiInstance.get(`/api/articles-download/${id}`,{responseType: "blob"});
    }

    static revisionDataAPI(payload){
        return apiInstance.post(`/api/articles-agency-revision/${payload.id}`, payload);
    }

    static approveDataAPI(id){
        return apiInstance.post(`/api/articles-agency-approve/${id}`);
    }

}