import apiInstance from "./apiInstance";

export class AgencyUserServices{

    static addUsersDataAPI(payload){
        return apiInstance.post('/api/add-agency-user', payload);
    }
        static getUserListDataAPI(payload){
        return apiInstance.get(`/api/users-list/${payload.per_page}?page=${payload.page}`,{ params: payload });
    }

    static getEditUserDataAPI(id){
        return apiInstance.get(`/api/edit-user/${id}`);
    }

    static updateUserDataAPI(payload){
        return apiInstance.post(`/api/update-user/${payload.id}`, payload);
    }

    static deleteUserDataAPI(payload){
        return apiInstance.post('/api/delete-associate', payload);
    }
    
}