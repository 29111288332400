<template>
  <div class="home">
    <LoaderComponent v-show="loading" />
    <HeaderView />
    <section class="order-page-main-sec profile-page-main-sec agency-design-grid-right">
      <div class="desktop-respovsive-main-sec">
        <div class="invoice-history-content">
          <h2 class="text-heading">{{ userid ? 'edit User' : 'Add New User' }}</h2>
        </div>

        <div class="editor-dashboard-sec-2">
          <div class="payment-detail-box user-form-box">
            <form>
              <b-row>
                <b-col md="6">
                  <div class="form-group">
                    <label>First Name</label>
                    <b-form-input id="input-6" v-model="data.first_name" type="text" required></b-form-input>
                    <span v-if="errors.first_name" class="required-text" style="display: block" role="alert">
                      <strong>{{
                        errors.first_name[0]
                      }}</strong>
                    </span>
                  </div>
                </b-col>
                <b-col md="6">
                  <div class="form-group">
                    <label>Last Name</label>
                    <b-form-input id="input-6" v-model="data.last_name" type="text" required></b-form-input>
                    <span v-if="errors.last_name" class="required-text" style="display: block" role="alert">
                      <strong>{{
                        errors.last_name[0]
                      }}</strong>
                    </span>
                  </div>
                </b-col>
                <b-col md="6">
                  <div class="form-group">
                    <label>Email</label>
                    <b-form-input id="input-6" v-model="data.email" type="email" :required="userBtn === 'Add User'" :disabled="userBtn === 'Update User'"></b-form-input>
                    <span v-if="errors.email" class="required-text" style="display: block" role="alert">
                      <strong>{{
                        errors.email[0]
                      }}</strong>
                    </span>
                  </div> 
                </b-col>
                <b-col md="12">
                  <div class="form-group">
                    <h6>Permissions</h6>
                    <b-form-checkbox switch size="lg" v-model="data.is_all_links">All Link Access</b-form-checkbox>
                    <p>Checking this option would allow your user to see all the links that were through your account and
                      other users as well.<br>
                      Turn off this option if you want to ensure that the user has access to only their own links.</p>
                    <!-- <span v-if="errors.email" class="required-text" style="display: block" role="alert">
                          <strong>{{
                            errors.email[0]
                          }}</strong>
                        </span> -->
                  </div>
                </b-col>
                <b-col md="12">
                  <div class="form-group">
                    <b-form-checkbox switch size="lg" v-model="data.is_wallet">Checkout with Agency's Credits</b-form-checkbox>
                    <p>Selecting this option will grant your user the ability to checkout with wallet credits that are added from your account.
                      Their checkouts will be charged to your loaded balance on the portal.</p>
                    <!-- <span v-if="errors.email" class="required-text" style="display: block" role="alert">
                          <strong>{{
                            errors.email[0]
                          }}</strong>
                        </span> -->
                  </div>
                </b-col>
                <b-col md="12">
                  <div class="form-group">
                    <b-form-checkbox switch size="lg" v-model="data.is_save_card">Checkout with Saved Credit Card(s)</b-form-checkbox>
                    <p>Selecting this option will allow the user to checkout by charging their order amount on the your added (saved) card(s).
                        All available saved cards can be used by the user.</p>
                    <!-- <span v-if="errors.email" class="required-text" style="display: block" role="alert">
                          <strong>{{
                            errors.email[0]
                          }}</strong>
                        </span> -->
                  </div>
                </b-col>
                <b-col md="12">
                  <div class="form-group">
                    <b-form-checkbox switch size="lg" v-model="data.is_add_card">Pay with Personal Credit Card(s) for Checkout</b-form-checkbox>
                    <p>Selecting this option will let the user add their own credit card during checkout.</p>
                    <!-- <span v-if="errors.email" class="required-text" style="display: block" role="alert">
                          <strong>{{
                            errors.email[0]
                          }}</strong>
                        </span> -->
                  </div>
                  <p class="note-text">
                    <b>Note:</b> If you want the user to only have view access to all the links and not have the ability to place orders, Only select ALL LINK ACCESS.
                  </p>
                </b-col>
                <b-col md="12">
                  <div class="btn-box-list">
                    <a href="javascript:;" class="btn-1" @click="userid ? updateUser(userid) : addUser()">{{ userBtn }}
                    </a>
                  </div>
                </b-col>
              </b-row>
            </form>
          </div>
        </div>
      </div>

    </section>
  </div>
</template>

<script>
import HeaderView from "@/components/layout/HeaderComponent.vue";
import LoaderComponent from "@/components/LoaderComponent.vue";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  name: "UserProfileView",
  components: {
    HeaderView,
    LoaderComponent,
  },
  mounted() {
    if(this.userData.role != 'Agency'){
      this.$router.push("/dashboard");
    }
    var url = window.location.pathname;
    var id = url.substring(url.lastIndexOf('/') + 1);
    if (this.$route.params.id) {
      this.userid = id;
      this.getEditUser(id);
    }
  },
  data() {
    return {
      loading: false,
      userBtn: "Add User",
      data: {
        last_name: "",
        email: "",
        first_name: "",
        is_all_links: false,
        is_wallet: true,
        is_save_card:false,
        is_add_card: true,
      },
      userid: '',
      errors: [],
    };
  },
  methods: {
    async addUser() {
      this.loading = true;
      try {
        let res = await this.$store.dispatch("AgencyUsers/addUsersDataAction", this.data, {
          root: true
        });
        if (res.success) {
          this.loading = false;
          toast.success(res.message, { timeout: 3000 });
          this.$router.push("/users");
        }
      } catch (error) {
        this.loading = false;
        this.errors = error.response.data.errors;
      }
    },
    async updateUser(id) {
      this.data['id'] = id;
      this.loading = true;
      try {
        let res = await this.$store.dispatch("AgencyUsers/updateUserDataAction", this.data, {
          root: true
        });
        if (res.success) {
          this.loading = false;
          toast.success(res.message, { timeout: 3000 });
          this.$router.push("/users");
        }
      } catch (error) {
        this.loading = false;
        this.errors = error.response.data.errors;
      }
    },
    async getEditUser(user_id) {
      this.userBtn = "Update User";
      try {
        let res = await this.$store.dispatch(
          "AgencyUsers/getEditUserDataAction",
          user_id
        );
        this.data.first_name = res.first_name;
        this.data.last_name = res.last_name;
        this.data.email = res.email;
        this.data.is_all_links = res.is_all_links == 1 ? true : false;
        this.data.is_wallet = res.is_wallet == 1 ? true : false;
        this.data.is_save_card = res.is_save_card == 1 ? true : false;
        this.data.is_add_card = res.is_add_card == 1 ? true : false;
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },

  },

  watch: {
    $route() {
      if (!this.$route.params.id) {
        this.userBtn = "Add User";
        this.data.last_name = "";
        this.data.email = "";
        this.data.first_name = "";
        this.data.is_all_links = false;
        this.data.is_wallet = true;
        this.data.is_save_card = false;
        this.data.is_add_card = true;
        this.userid = '';
        this.errors = [];
      }
    },
  }, 
  computed: {
    userData() {
      const data = this.$store.getters["Auth/getUser"];
      return {
        role: data.role,
      };
    },
  },
};
</script>
