import apiInstance from "./apiInstance";

export class DashboardServices{

    static getAllDashboardDataAPI(payload){
        return apiInstance.post('/api/dashboard', payload);
    }
     
    static getContentApprovelAPI(){
        return apiInstance.get('/api/dashboard-content-approval');
    }

    static getRecentMessageAPI(){
        return apiInstance.get('/api/dashboard-messages');
    }

    static messageReadAPI(id){
        return apiInstance.get(`/api/message-read/${id}`);
    }

    static getDashboardReportsAPI(payload){
        return apiInstance.post('/api/dashboard-link-status-report',payload);
    }

    static getDAdReportsAPI(payload){
        return apiInstance.post('/api/dashboard-da-links-report',payload);
    }

    static getDRdReportsAPI(payload){
        return apiInstance.post('/api/dashboard-dr-links-report',payload);
    }

    static getDomainReportsAPI(payload){
        return apiInstance.post('/api/dashboard-domain-report',payload);
    }

    static getProductAPI(){
        return apiInstance.get('/api/get-products');
    }

    static getDomainListAPI(){
        return apiInstance.get('/api/domain-list');
    }

    static getLiveLinkDataAPI(){
        return apiInstance.get('/api/dashboard-live-links');
    }

    static premiumPlacementRequestAPI(){
        return apiInstance.post('/api/premium-placement-request');
    }

    static checkPremiumPlacementRequestOptionDataAPI(){
        return apiInstance.get('/api/allow-premium-placement-request');
    }

    static analyticsDataAPI(payload){
        return apiInstance.post('/api/analytics-data', payload);
    }
}