import apiInstance from "./apiInstance";

export class MissedOpportunityServices{

    static getMissedOpportunityDataAPI(payload){
        return apiInstance.post('/api/opportunity-list',payload);
    }

    static opportunityCountDataAPI(){
        return apiInstance.get('/api/get-missed-opportunity-count');
    }
    
    static deleteOpportunityOrderAPI(payload){
        return apiInstance.post('/api/delete-opportunity', payload);
    }

    static getMissedOpportunityStatusAPI(payload){
        return apiInstance.get('/api/opportunity/statuses',payload);
    }

    static getCategoriesAPI(){
        return apiInstance.get('/api/categories-list');
    }
}