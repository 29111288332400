<template>
  <div>
    <div class="main-header">
      <header>
        <div class="desktop-header">
          <div class="dashboard-dropdown-right">
            <div class="schedule-box-sec">
              <div class="text-1">
                Need help with your link building strategy?
              </div>
              <a
                href="https://calendly.com/linkbuildinghq/30min"
                target="_blank"
                ><i class="fas fa-phone fa-flip-horizontal"></i> Schedule a
                Call</a
              >
            </div>
            <div
              class="admin-back-btn"
              @click.prevent="adminLogout()"
              v-if="checkImpersonateLogin"
            >
              <a href="#" class="btn-1"
                ><i class="bx bx-arrow-back" id="btn" />Back to Admin</a
              >
            </div>
            <div class="top-email-icon">
              <a href="javascript:;" @click="openSidebar"
                ><i class="fas fa-comments"
                  ><span class="count-icon" v-if="messageCount > 0">{{
                    messageCount
                  }}</span></i
                ></a
              >
            </div>
            <ul>
              <li class="dropdown-item-2">
                <div class="dropdown">
                  <button
                    class="btn btn-secondary dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="fas fa-wallet"></i>
                    {{ numberFormat(userData.balance, "currency") }}
                    <!-- <img src="@/assets/common/images/Avatar-1.png" /> -->
                    <div class="image-box">
                      <img :src="base_url + userData.profileImage" />
                    </div>
                  </button>
                  <div
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <div class="dropdown-item agency-name">
                      {{ userData.name }}
                      <span class="m-card-user__email m--font-weight-300"
                        >{{ userData.email }}
                      </span>
                    </div>
                    <router-link to="/user-profile" class="dropdown-item"
                      ><i class="fas fa-user"></i>Profile</router-link
                    >
                    <a
                      href="#"
                      class="dropdown-item"
                      v-b-modal.modal-box-referralLink
                      ><i class="fas fa-share-alt"></i>Referral Link</a
                    >
                    <a
                      href="#"
                      class="dropdown-item logout-text"
                      @click.prevent="logout"
                      ><i class="fas fa-sign-out-alt"></i> Logout</a
                    >
                    <form
                      id="logout-form"
                      action="{{ route('logout') }}"
                      method="POST"
                      style="display: none"
                    ></form>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <div
            id="m_aside_left"
            class="m-grid__item m-aside-left agency-design-grid-left"
          >
            <div class="sidebar" :class="isOpened ? 'open' : ''">
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  flex-grow: 1;
                  max-height: calc(100% - 60px);
                "
              >
                <div class="logo-bar">
                  <router-link to="/dashboard" class="logo-img">
                    <img
                      src="@/assets/common/images/logo.svg"
                      v-if="isOpened == false"
                    />
                    <img
                      src="@/assets/common/images/lbhq-logo.svg"
                      style="width: 90%"
                      v-else
                    />
                  </router-link>
                </div>
                <ul class="sidebar-nav" @click="resetSidebarPadding">
                  <li>
                    <router-link
                      to="/dashboard"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Dashboard"
                      ><i class="fas fa-th-large"></i>
                      <span class="links_name">Dashboard</span>
                    </router-link>
                  </li>
                  <!-- <li>
                    <router-link
                      to="/product"
                      v-if="activeTab == false"
                      title="Products"
                      ><i class="fas fa-shopping-cart"></i>
                      <span class="links_name">Products</span>
                    </router-link>
                    <router-link
                      :to="productId"
                      class="router-link-active router-link-exact-active"
                      v-if="activeTab == true"
                      title="Product"
                      ><i class="fas fa-shopping-cart"></i>
                      <span class="links_name">Products</span>
                    </router-link>
                  </li> -->
                  <li class="left-sibar-open">
                    <a
                      href="javascript:;"
                      title="Products"
                      @click="productDropDown()"
                      ><i class="fas fa-shopping-cart"></i>
                      <span class="links_name">Products</span>
                      <i
                        class="bx"
                        :class="
                          productsDropDown
                            ? '  bx bx-chevron-up'
                            : 'bx bx-chevron-down'
                        "
                        id="btn"
                      />
                    </a>
                    <ul class="left-sub-menu product-menu">
                      <li>
                        <router-link
                      to="/product"
                      v-if="activeTab == false"
                      title="Link Building"
                      ><i class="bx">  <img src="@/assets/common/images/ico-linkbuilding.svg" style="width: 17px;"/></i>
                     Link Building
                    </router-link>
                    <router-link
                      :to="productId"
                      class="router-link-active router-link-exact-active"
                      v-if="activeTab == true"
                      title="Link Building"
                      ><i class="bx">  <img src="@/assets/common/images/ico-linkbuilding.svg" style="width: 17px;"/></i>
                     Link Building
                    </router-link>
                      </li>
                      <li>
                        <router-link to="/enterprise"
                           title="Enterprise Packages"
                          >
                          <i class="bx"> 
                          <img src="@/assets/common/images/ico-enterprisepkg.svg" style="width: 17px;"/>
                          </i>
                          Enterprise Packages</router-link
                        >
                      </li>
                      <li>
                        <router-link to="/digitalpr"
                        title="Digital PR"
                          ><i class="bx" style="padding-left: 3px;"><img src="@/assets/common/images/ico-digitalpr.svg" /></i>  Digital PR</router-link
                        >
                      </li>
                    </ul>
                  </li>
                  <li v-if="packageCount > 0">
                    <router-link to="/packages" title="Packages"
                    class="missed-opportunity-icon"
                      >
                      <i class="fas">
                      <img  src="@/assets/common/images/ico-package.svg" class="inactive-img"/>  
                      <img  src="@/assets/common/images/ico-package-1.svg" class="active-img"/>  
                      <span class="count-icon" v-if="packageCount > 0">{{
                          packageCount
                        }}</span>
                      </i>
                            
             
                      <span class="links_name">Packages</span>
                    </router-link>
                  </li>
                  <li class="left-sibar-open" v-if="opportunityCount > 0">
                    <router-link
                      to="/missed-opportunity"
                      title="Premium Placements"
                      class="missed-opportunity-icon"
                    >
                      <i
                        class="fas"
                      >
                      <img
                      src="@/assets/common/images/premiumplacement_inactive.png" class="inactive-img"
                    />
                    <img
                      src="@/assets/common/images/premiumplacement_active.png" class="active-img"
                    />
                      <span class="count-icon" v-if="newOpportunityCount > 0">{{
                          newOpportunityCount
                        }}</span>
                      </i> 
                      <span class="links_name" style="text-align:left">Premium <br> Placements</span>
                    </router-link>
                    <!-- <ul class="left-sub-menu opportunitie-menu">
                      <li>
                        <router-link to="/byorder"><i class="bx bxs-report"></i> Blogger Outreach </router-link>
                      </li>
                      <li>
                        <router-link to="/missed-opportunity"><i class='bx bx-current-location'></i> Premium Placements</router-link>
                      </li>
                    </ul> -->
                  </li>
                  <li class="left-sibar-open" v-if="userData.role == 'Agency'">
                    <a href="javascript:;" title="User" @click="userDropDown()"
                      ><i class="fas fa-user"></i>
                      <span class="links_name">User</span>
                      <i
                        class="bx"
                        :class="
                          usersDropDown
                            ? '  bx bx-chevron-up'
                            : 'bx bx-chevron-down'
                        "
                        id="btn"
                      />
                    </a>
                    <ul class="left-sub-menu user-menu">
                      <li>
                        <router-link to="/add-user"
                          ><i class="bx bxs-user-plus"></i> Add
                          User</router-link
                        >
                      </li>
                      <li>
                        <router-link to="/users"
                          ><i class="bx bxs-user-detail"></i> See
                          All</router-link
                        >
                      </li>
                    </ul>
                  </li>
                  <li>
                    <router-link
                      to="/order"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Orders"
                      ><i class="fas fa-link"></i>
                      <span class="links_name">Orders</span>
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/draft-order" title="Draft Order"
                      ><i class="fas fa-file-invoice"
                        ><span class="count-icon" v-if="orderPlanCount > 0">{{
                          orderPlanCount
                        }}</span></i
                      >
                      <span class="links_name">Draft Order</span>
                    </router-link>
                  </li>
                  <li class="left-sibar-open">
                    <a
                      href="javascript:;"
                      title="Reports"
                      @click="reportDropDown()"
                      ><i class="fas fa-chart-line"></i>
                      <span class="links_name">Reports</span>
                      <i
                        class="bx"
                        :class="
                          reportsDropDown
                            ? '  bx bx-chevron-up'
                            : 'bx bx-chevron-down'
                        "
                        id="btn"
                      />
                    </a>
                    <ul class="left-sub-menu report-menu">
                      <li>
                        <router-link to="/byorder"
                          ><i class="bx bxs-report"></i> By Orders
                        </router-link>
                      </li>
                      <li>
                        <router-link to="/domain"
                          ><i class="bx bxs-report"></i> By Domain</router-link
                        >
                      </li>
                      <li>
                        <router-link to="/destination"
                          ><i class="bx bxs-report"></i> By Destination</router-link
                        >
                      </li>
                    </ul>
                  </li>
                  <li>
                    <router-link to="/billing" title="Billing"
                      ><i class="fas fa-file-invoice-dollar"></i>
                      <span class="links_name">Billing</span>
                    </router-link>
                  </li>

                  <!-- <li>
                  <router-link to="/user-profile"><i class="fas fa-user"></i></router-link>
                </li> -->
                </ul>

                <div class="logo-details" style="margin: 6px 14px 0 14px">
                  <!-- <img v-if="menuLogo" :src="menuLogo" alt="menu-logo" class="menu-logo icon">
                  <i v-else class="bx icon" :class="menuIcon" />
                  <div class="logo_name">
                    {{ menuTitle }}
                  </div> -->
                  <i
                    class="bx"
                    :class="isOpened ? ' bx-arrow-back' : 'bx-right-arrow-alt'"
                    id="btn"
                    @click="isOpened = !isOpened"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div class="modal-list">
        <b-modal
          id="modal-box-referralLink"
          centered
          title="Referral Link"
          :no-close-on-backdrop="true"
          hide-footer
        >
          <div class="input-group mb-3 mt-2">
            <input
              type="text"
              class="form-control"
              v-model="referralLink"
              ref="message"
              readonly
            />
            <div class="input-group-append">
              <span class="input-group-text" @click="copyText()"
                ><i class="fas fa-copy" title="copy"></i
              ></span>
            </div>
          </div>
        </b-modal>
      </div>
      <div class="email-sideabar-box" :class="{ opensideBar: isSidebarOpen }">
        <div class="top-bar">
          <div class="text-heading">Recent Messages</div>
          <div class="icon-box">
            <i class="fas fa-times" @click="closeSidebar"></i>
          </div>
        </div>
        <RecentMessageView :recent_messages="recent_messages" />
      </div>
    </div>
  </div>
</template>
<script>
import jQuery from "jquery";
import url, { numberFormat } from "@/prod.js";
import RecentMessageView from "@/components/dashboardComponents/recentMessage.vue";

export default {
  components: {
    RecentMessageView,
  },
  props: {
    //! Menu settings
    isMenuOpen: {
      type: Boolean,
      default: true,
    },
    isUsedVueRouter: {
      type: Boolean,
      default: false,
    },
    menuTitle: {
      type: String,
      default: "Akahon",
    },
    menuLogo: {
      type: String,
      default: "",
    },
    menuIcon: {
      type: String,
      default: "bxl-c-plus-plus",
    },
    isPaddingLeft: {
      type: Boolean,
      default: true,
    },
    menuOpenedPaddingLeftBody: {
      type: String,
      default: "160px",
    },
    menuClosedPaddingLeftBody: {
      type: String,
      default: "90px",
    },
  },
  mounted() {
    window.document.body.style.paddingLeft = this.menuClosedPaddingLeftBody;
    this.tooltipAttached();
    this.getopportunityCount();
    this.getpackageCount();
    this.getOrderPlanCount();
    this.getUsersData();
    this.getRecentMessages();
    this.referralLink = this.userData.referralLink;
    if (this.appStatus === "production" && this.userData.role == "Agency") {
      this.additionalScripts();
    }
  },

  data() {
    return {
      numberFormat,
      appStatus: url.appStatus,
      base_url: url.baseUrl,
      adminUrl: url.adminUrl,
      isShow: false,
      isOpened: false,
      reportsDropDown: false,
      productsDropDown: false,
      opportunitiesDropDown: false,
      usersDropDown: false,
      orderPlanCount: 0,
      opportunityCount: 0,
      newOpportunityCount: 0,
      referralLink: "",
      activeTab: false,
      productId: "",
      isSidebarOpen: false,
      messageCount: 0,
      recent_messages: [],
      unreadMessageId: [],
      packageCount: 0,
    };
  },
  methods: {
    additionalScripts() {
      if (this.appStatus === "production" && this.userData.role == "Agency") {
        window.intercomSettings = {
          api_base: "https://api-iam.intercom.io",
          app_id: "tw64onph",
          email: this.userData.email, // Email address
          created_at: this.userData.user_created_at, // Signup date
          "Paid User": this.userData.order_count > 0 ? "true" : "false", //Is user have any order
          Delivered:
            this.userData.deliveredOrders == 0 && this.userData.order_count > 0
              ? "true"
              : "false",
          "Free Trial Fulfilled":
            this.userData.trial == 1 &&
            this.userData.order_count == 1 &&
            this.userData.deliveredOrders == 0
              ? "true"
              : "false",
          "Only Signed Up":
            this.userData.trial == 0 && this.userData.order_count == 0
              ? "true"
              : "false",
          "Paid $5":
            this.userData.order_count == 1 && this.userData.deliveredOrders == 0
              ? "true"
              : "false",
          "Recurring User": this.userData.order_count > 1 ? "true" : "false",
          "Last ordered": this.userData.last_ordered,
          user_hash: window.atob(this.userData.user_token), // HMAC using SHA-256
          "lbhq user id": this.userData.userId,
        };
        // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/tw64onph'
        (function () {
          var w = window;
          var ic = w.Intercom;
          if (typeof ic === "function") {
            ic("reattach_activator");
            ic("update", w.intercomSettings);
          } else {
            var d = document;
            var i = function () {
              i.c(arguments);
            };
            i.q = [];
            i.c = function (args) {
              i.q.push(args);
            };
            w.Intercom = i;
            var l = function () {
              var s = d.createElement("script");
              s.type = "text/javascript";
              s.async = true;
              s.src = "https://widget.intercom.io/widget/tw64onph";
              var x = d.getElementsByTagName("script")[0];
              x.parentNode.insertBefore(s, x);
            };
            if (document.readyState === "complete") {
              l();
            } else if (w.attachEvent) {
              w.attachEvent("onload", l);
            } else {
              w.addEventListener("load", l, false);
            }
          }
        })();
      }
    },
    async getRecentMessages() {
      try {
        let res = await this.$store.dispatch(
          "Dashboard/getRecentMessageDataAction"
        );
        this.recent_messages = res.data;
        this.messageCount = res.message_count;
        this.unreadMessageId = res.unread_message_id;
      } catch (e) {
        this.loading = false;
      }
    },

    async adminLogout() {
      this.$parent.loading = true;
      try {
        let res = await this.$store.dispatch("Auth/logoutAction", null, {
          root: true,
        });
        if (res.success) {
          this.$parent.loading = false;
          localStorage.removeItem("impersonate-login");
          window.location = this.adminUrl;
        }
      } catch (e) {
        this.$parent.loading = false;
      }
    },

    async logout() {
      this.$parent.loading = true;
      try {
        let res = await this.$store.dispatch("Auth/logoutAction", null, {
          root: true,
        });
        if (res.success) {
          this.$parent.loading = false;

          this.$router.push({ path: "/login", replace: true });
        }
      } catch (e) {
        this.$parent.loading = false;
      }
    },

    openSidebar() {
      setTimeout(() => (this.isSidebarOpen = true), 500);
      setTimeout(() => (this.isSidebarClose = false), 500);
    },

    closeSidebar() {
      setTimeout(() => (this.isSidebarOpen = false), 500);
    },

    copyText() {
      const element = this.$refs.message;
      element.select();
      element.setSelectionRange(0, 99999);
      document.execCommand("copy");
    },

    openNav() {
      document.getElementById("mySidenav").style.width = "100%";
    },

    closeNav() {
      document.getElementById("mySidenav").style.width = "0";
    },

    productDropDown() {
  // Close other dropdowns
  this.usersDropDown = false;
  this.reportsDropDown = false;
  this.opportunitiesDropDown = false;

  // Close other menus
  jQuery(".user-menu, .report-menu, .opportunitie-menu").slideUp();

  // Toggle current dropdown
  jQuery(".product-menu").slideToggle();
  this.productsDropDown = !this.productsDropDown;
},

userDropDown() {
  // Close other dropdowns
  this.productsDropDown = false;
  this.reportsDropDown = false;
  this.opportunitiesDropDown = false;

  // Close other menus
  jQuery(".product-menu, .report-menu, .opportunitie-menu").slideUp();

  // Toggle current dropdown
  jQuery(".user-menu").slideToggle();
  this.usersDropDown = !this.usersDropDown;
},

reportDropDown() {
  // Close other dropdowns
  this.productsDropDown = false;
  this.usersDropDown = false;
  this.opportunitiesDropDown = false;

  // Close other menus
  jQuery(".product-menu, .user-menu, .opportunitie-menu").slideUp();

  // Toggle current dropdown
  jQuery(".report-menu").slideToggle();
  this.reportsDropDown = !this.reportsDropDown;
},

opportunitieDropDown() {
  // Close other dropdowns
  this.productsDropDown = false;
  this.usersDropDown = false;
  this.reportsDropDown = false;

  // Close other menus
  jQuery(".product-menu, .user-menu, .report-menu").slideUp();

  // Toggle current dropdown
  jQuery(".opportunitie-menu").slideToggle();
  this.opportunitiesDropDown = !this.opportunitiesDropDown;
},


    resetSidebarPadding(event) {
      const target = event.target;
      const href = target.getAttribute("href");
      if (
        target.className == "fas fa-user" ||
        target.className == "fas fa-chart-line" ||
        target.className == "fas fa-users" ||
        target.className == "fas fa-shopping-cart" ||
        href === "javascript:;" ||
        href === "#"
      ) {
        this.isOpened = true;
      }
    },

    tooltipAttached() {
      const tooltips = document.querySelectorAll(".tooltip");
      tooltips.forEach((tooltip) => {
        document.body.appendChild(tooltip);
      });
      document.querySelectorAll(".tooltip").forEach((tooltip) => {
        const targetID = tooltip.dataset.target;
        const target = document.querySelector(`#${targetID}`);
        if (!target) return;
        target.addEventListener("mouseenter", () => {
          const targetPosition = target.getBoundingClientRect();
          if (this.isOpened) return;
          tooltip.style.top = `${targetPosition.top + window.scrollY}px`;
          tooltip.style.left = `${
            targetPosition.left + targetPosition.width + 20
          }px`;
          tooltip.classList.add("active");
        });
        target.addEventListener("mouseleave", () => {
          tooltip.classList.remove("active");
        });
      });
    },
    async getopportunityCount() {
      try {
        let res = await this.$store.dispatch(
          "MissedOpportunity/getopportunityCountAction"
        );
        this.opportunityCount = res.opportunity_count;
        this.newOpportunityCount = res.new_opportunity_count;
      } catch (e) {
        this.loading = false;
      }
    },
    async getpackageCount() {
      try {
        let res = await this.$store.dispatch(
          "Packages/getPackageCountAction"
        );
        this.packageCount = res.package_count;
      } catch (e) {
        this.loading = false;
      }
    },
    async getOrderPlanCount() {
      try {
        let res = await this.$store.dispatch("Product/getOrderPlanCountAction");
        this.orderPlanCount = res.order_plan_count;
      } catch (e) {
        this.loading = false;
      }
    },
    async getUsersData() {
      try {
        await this.$store.dispatch("Auth/getUserDataAction");
      } catch (e) {
        console.log(e);
      }
    },

    // async messageRead() {
    //   if(this.unreadMessageId.length > 0){
    //     try {
    //       let res = await this.$store.dispatch(
    //         "Dashboard/messageReadDataAction",{
    //           linkId: this.unreadMessageId
    //         });
    //         if(res.success){
    //           this.messageCount = 0;
    //         }
    //     } catch (e) {
    //       console.log(e);
    //     }
    //   }
    // },
  },
  watch: {
    isOpened() {
      if (!this.isOpened && jQuery(".user-menu").is(":visible")) {
        jQuery(".user-menu").slideToggle();
        this.usersDropDown = false;
      }
      if (!this.isOpened && jQuery(".opportunitie-menu").is(":visible")) {
        jQuery(".opportunitie-menu").slideToggle();
        this.usersDropDown = false;
      }
      if (!this.isOpened && jQuery(".report-menu").is(":visible")) {
        jQuery(".report-menu").slideToggle();
        this.reportsDropDown = false;
      }
      if (!this.isOpened && jQuery(".product-menu").is(":visible")) {
        jQuery(".product-menu").slideToggle();
        this.productsDropDown = false;
      }
      window.document.body.style.paddingLeft =
        this.isOpened && this.isPaddingLeft
          ? this.menuOpenedPaddingLeftBody
          : this.menuClosedPaddingLeftBody;
    },

    $route(to) {
      var url = window.location.pathname;
      var id = url.substring(url.lastIndexOf("/") + 1);
      if (to.path === "/product/" + id) {
        this.productId = "/product/" + id;
        localStorage.setItem("draft-order-id", id);
        this.activeTab = true;
      } else {
        this.activeTab = false;
      }
    },
  },
  computed: {
    userData() {
      const data = this.$store.getters["Auth/getUser"];
      return {
        userId: data.user_id,
        name: data.name,
        email: data.email,
        balance: data.balance,
        role: data.role,
        user_token: data.user_token,
        profileImage: data.image,
        referralLink: data.referral_link,
        user_created_at: data.user_created_at,
        trial: data.trial,
        order_count: data.order_count,
        deliveredOrders: data.deliveredOrders,
        last_ordered: data.last_ordered,
      };
    },

    checkImpersonateLogin() {
      if (localStorage.getItem("impersonate-login") != null) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style>
/* Google Font Link */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");
@import url("https://unpkg.com/boxicons@2.0.7/css/boxicons.min.css");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

body {
  transition: all 0.5s ease;
}

.name_job {
  margin-bottom: 5px;
}

.menu-logo {
  width: 30px;
  margin: 0 10px 0 10px;
}

header .logo-bar a img {
  transition: all 0.5s ease;
}

.input-group-append span.input-group-text {
  cursor: pointer;
}

.input-group-append span.input-group-text i.fas.fa-copy {
  color: #00b6cb;
}

.sidebar {
  position: relative;
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  min-height: min-content;
  /* overflow-y: auto; */
  width: 90px;
  background: var(--bg-color);
  /* padding: 6px 14px 0 14px; */
  z-index: 0;
  transition: all 0.4s ease;
}

.sidebar.open {
  width: 160px;
}

.sidebar .logo-details {
  height: 60px;
  display: flex;
  align-items: center;
  position: relative;
}

.sidebar .logo-details .icon {
  opacity: 0;
  transition: all 0.5s ease;
}

.sidebar .logo-details .logo_name {
  color: var(--logo-title-color);
  font-size: 20px;
  font-weight: 600;
  opacity: 0;
  transition: all 0.5s ease;
}

.sidebar.open .logo-details .icon,
.sidebar.open .logo-details .logo_name {
  opacity: 1;
}

.sidebar .logo-details #btn {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  transform: translateY(-50%);
  font-size: 22px;
  transition: all 0.4s ease;
  font-size: 23px;
  text-align: center;
  cursor: pointer;
  transition: all 0.5s ease;
  color: #00b6cb;
}

.sidebar.open .logo-details #btn {
  text-align: right;
}

.sidebar i {
  color: var(--icons-color);
  height: 60px;
  min-width: 53px;
  font-size: 28px;
  text-align: center;
  line-height: 60px;
  transition: all 0.4s ease;
}

.sidebar .nav-list {
  margin-top: 20px;
  /* margin-bottom: 60px; */
  /* height: 100%; */
  /* min-height: min-content; */
}

.sidebar li {
  position: relative;
  margin: 8px 8px 8px 0px;
  list-style: none;
}

.sidebar input {
  font-size: 15px;
  color: var(--serach-input-text-color);
  font-weight: 400;
  outline: none;
  height: 50px;
  width: 100%;
  width: 50px;
  border: none;
  border-radius: 8px;
  transition: all 0.5s ease;
  background: var(--secondary-color);
}

.sidebar.open input {
  padding: 0 20px 0 50px;
  width: 100%;
}

.sidebar .bx-search:hover {
  background: #393939;
  color: var(--bg-color);
}

.sidebar li a {
  display: flex;
  height: 100%;
  width: 100%;
  border-radius: 8px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  align-items: center;
  text-decoration: none;
  transition: all 0.4s ease;
  background: var(--bg-color);
}

.sidebar li a:hover {
  background: #ffff;
  box-shadow: 1px 8px 18px 8px #dbe4e5;
}

.sidebar li a.router-link-active.router-link-exact-active {
  background: #393939;
  box-shadow: 1px 8px 18px 8px #dbe4e5;
  color: #ffff;
}

.sidebar li a .links_name {
  color: var(--menu-items-text-color);
  font-size: 15px;
  font-weight: 400;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  transition: 0.4s;
}

.sidebar.open li a .links_name {
  opacity: 1;
  pointer-events: auto;
}

.sidebar.open li a i {
  min-width: 30px;
}

.sidebar.open li a i span.count-icon {
  right: 3px;
}

.sidebar li a.active .links_name,
.sidebar li a.active i {
  transition: all 0.5s ease;
  color: #ffff;
}

.sidebar li router-link {
  display: flex;
  height: 100%;
  width: 100%;
  border-radius: 12px;
  align-items: center;
  text-decoration: none;
  transition: all 0.4s ease;
  background: var(--bg-color);
}

.sidebar li router-link:hover {
  background: #393939;
}

.sidebar li router-link .links_name {
  color: var(--menu-items-text-color);
  font-size: 15px;
  font-weight: 400;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  transition: 0.4s;
}

.sidebar.open li router-link .links_name {
  opacity: 1;
  pointer-events: auto;
}

.sidebar li router-link:hover .links_name,
.sidebar li router-link:hover i {
  transition: all 0.5s ease;
  color: var(--bg-color);
}

.sidebar li i {
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  border-radius: 12px;
}

.sidebar li i.bx-chevron-down {
  opacity: 0;
}

.sidebar.open li i.bx-chevron-down {
  opacity: 1;
}

.sidebar div.profile {
  position: relative;
  height: 60px;
  width: 78px;
  /* left: 0;
    bottom: 0; */
  padding: 10px 14px;
  background: var(--secondary-color);
  transition: all 0.5s ease;
  overflow: hidden;
}

.sidebar.open div.profile {
  width: 250px;
}

.sidebar div .profile-details {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.sidebar div.profile .name,
.sidebar div.profile .job {
  font-size: 15px;
  font-weight: 400;
  color: var(--menu-footer-text-color);
  white-space: nowrap;
}

.sidebar div.profile .job {
  font-size: 12px;
}

.sidebar .profile #log_out {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background: var(--secondary-color);
  width: 100%;
  height: 60px;
  line-height: 60px;
  border-radius: 0px;
  transition: all 0.5s ease;
}

.sidebar.open .profile #log_out {
  width: 50px;
  background: var(--secondary-color);
  opacity: 0;
}

.sidebar.open .profile:hover #log_out {
  opacity: 1;
}

.sidebar.open .profile #log_out:hover {
  opacity: 1;
  color: red;
}

.sidebar .profile #log_out:hover {
  color: red;
}

.home-section {
  position: relative;
  background: var(--home-section-color);
  min-height: 100vh;
  top: 0;
  left: 78px;
  width: calc(100% - 78px);
  transition: all 0.5s ease;
  z-index: 2;
}

.sidebar.open ~ .home-section {
  left: 250px;
  width: calc(100% - 250px);
}

.home-section .text {
  display: inline-block;
  color: var(--bg-color);
  font-size: 25px;
  font-weight: 500;
  margin: 18px;
}

.my-scroll-active {
  overflow-y: auto;
}

#my-scroll {
  overflow-y: auto;
  height: calc(100% - 60px);
}

#my-scroll::-webkit-scrollbar {
  display: none;
  /* background-color: rgba(255, 255, 255, 0.2); 
    width: 10px;
    border-radius:5px  */
}

/* #my-scroll::-webkit-scrollbar-thumb{
    background-color: red;
    border-radius:5px 
  }
  #my-scroll::-webkit-scrollbar-button:vertical:start:decrement{
    display:none;
  }
  #my-scroll::-webkit-scrollbar-button:vertical:end:increment{
    display:none;
  } */
@media (max-width: 420px) {
  .sidebar li .tooltip {
    display: none;
  }
}
</style>
