<template>
    <main class="main-layout">
        <section class="notfound-sec">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-12 col-12">
                        <div class="logo">
                            <a href="#"><img src="@/assets/common/images/lbhq-logo.svg" alt="logo"></a>
                        </div>
                    </div>
                </div>
                <div class="row align-items-center justify-content-center">
                    <div class="col-lg-6 col-12 order-lg-last">
                        <div class="notfound-sec-img">
                            <img src="@/assets/common/images/notfoundimg.png" alt="not-found-img">
                        </div>
                    </div>
                    <div class="col-lg-6 col-12 order-lg-first">
                        <div class="notfound-sec-content">
                            <h1 class="color-primary">Maintenance Underway</h1>
                            <h2>Link Building HQ is getting a power-up! 🚀</h2>
                            <p> We're fine-tuning our tool to elevate your link-building experience. We'll be back shortly.
                                Thanks for your patience!</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>

<style>
.logo {
    margin-bottom: 3rem;
}

.main-layout {
    padding: 9rem 0;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center
}

.main-layout .notfound-sec .row.align-items-center {
    margin-top: -3rem
}

.main-layout .notfound-sec-img {
    text-align: end
}

.main-layout .notfound-sec-content h1 {
    position: relative;
    left: 0rem;
    font-size: 2.9rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
    color: #00b6cb;
}

.main-layout .notfound-sec-content h2 {
    font-size: 1.7rem;
    font-weight: 600;
    line-height: 3rem;
    margin-bottom: 0.5rem;
}

.main-layout .notfound-sec-content p {
    font-size: 1.7rem;
    font-weight: 400;
    line-height: 2.6rem;
    margin-bottom: 3rem;
}

@media only screen and (max-width:1366px) {
    .logo {
        margin-bottom: 0rem;
    }

    .main-layout .notfound-sec .row.align-items-center {
        margin-top: 0
    }

    .main-layout .notfound-sec-content h1 {
        font-size: 2.5rem;
    }

    .main-layout .notfound-sec-content h2 {
        font-size: 1.4rem;
        font-weight: 600;
    }

    .main-layout .notfound-sec-content p {
        font-size: 1.5rem;
        font-weight: 400;
        line-height: 2.3rem;
    }

}

@media only screen and (max-width:1200px) {
    .logo {
        margin-bottom: 1rem;
    }

    .main-layout .notfound-sec .row.align-items-center {
        margin-top: 0
    }

    .main-layout .notfound-sec-content h1 {
        font-size: 2rem;
    }

    .main-layout .notfound-sec-content h2 {
        font-size: 1.2rem;
        font-weight: 600;
        line-height: 2.5rem;
    }

    .main-layout .notfound-sec-content p {
        font-size: 1.3rem;
        font-weight: 400;
        line-height: 2.1rem;
        margin-bottom: 2rem;
    }
}

@media only screen and (max-width:992px) {
    .main-layout {
        padding: 7rem 0
    }

    .notfound-sec .logo {
        display: flex;
        justify-content: center;
        margin-bottom: 0
    }

    .main-layout .notfound-sec-img {
        text-align: center;
        width: 60%;
        margin: 2rem auto 0
    }

    .notfound-sec-content {
        text-align: center
    }

    .main-layout .notfound-sec-content h1 {
        margin-top: 20px;
        font-size: 3rem;
    }

    .main-layout .notfound-sec-content h2 {
        font-size: 1.6rem;
        font-weight: 600;
        line-height: 2.5rem;
    }

    .main-layout .notfound-sec-content p {
        font-size: 2rem;
        line-height: 3rem
    }
}

@media only screen and (max-width:767px) {
    .main-layout {
        padding: 6rem 0
    }

    .notfound-sec .logo {
        width: 115px;
        margin: 0 auto 0.5rem;
    }

    .main-layout .notfound-sec-img {
        width: 75%;
        margin: 1rem auto
    }

    .main-layout .notfound-sec-content h1 {
        margin-top: 5px;
        font-size: 1.5rem;
        left: 0;
    }

    .main-layout .notfound-sec-content h2 {
        font-size: 1.6rem;
        line-height: 2.2rem;
    }

    .main-layout .notfound-sec-content p {
        font-size: 1.2rem;
        line-height: 1.8rem;
        margin-bottom: 0;
    }

}
</style>