<template>
  <div class="detail-list-box content-revision-box" :class="{ 'missed-opportunity-class': links.missed_opportunities }">
    <b-card no-body>
      <div class="top-bar">
        <div class="icon-box">
          <i class="fas fa-times" @click="closeOrderSidebar"></i>
        </div>
      </div>
      <b-tabs v-model="tabIndex" card>
        <b-tab title="Details">
          <b-card-text>
            <div class="content-box-style_body">
              <div class="detail-table-box">
                <table>
                  <tr>
                    <td>
                      <div class="text-1">
                        Order ID<span>{{ this.links.order_id }}</span>
                      </div>
                    </td>
                    <td>
                      <div class="text-1">
                        Link ID <span>{{ this.links.id }}</span>
                      </div>
                    </td>
                    <td>
                      <div class="text-1">
                        Status <span>{{ this.links.status }}</span>
                      </div>
                    </td>
                    <td>
                      <div class="text-1">
                        DA<span>{{ this.links.product_name }}</span>
                      </div>
                    </td>
                    <!-- <td v-if="this.links.missed_opportunities">
                      <div class="text-1 dr-text" style="width: 40px;">
                        DR<span>{{ this.links.exact_dr }}</span>
                      </div>
                    </td> -->
                  </tr>
                  <tr>
                    <td v-if="this.links.missed_opportunities">
                      <div class="text-1">
                        Live DA
                        <span>{{ this.links.domain_authority }}</span>
                      </div>
                    </td>
                    <td v-else>
                      <div class="text-1">
                        Domain Authority
                        <span>{{ this.links.domain_authority }}</span>
                      </div>
                    </td>
                    <!-- <td v-if="this.links.missed_opportunities">
                      <div class="text-1">
                        Live DR <span>{{ this.links.domain_rating }}</span>
                      </div>
                    </td> -->
                    <td>
                      <div class="text-1">
                        Spam Score <span>{{ this.links.spam_score }}</span>
                      </div>
                    </td>
                    <td v-if="!this.links.missed_opportunities">
                      <div class="text-1">
                        Do Follow <span>{{ this.links.do_follow }}</span>
                      </div>
                    </td>
                    <td v-if="this.links.missed_opportunities">
                      <div class="text-1">
                        Traffic <span>{{ Math.floor(this.links.traffic).toLocaleString()}}</span>
                      </div>
                    </td>
                    <td v-if="this.links.missed_opportunities">
                      <div class="text-1">
                        DA Date <span>{{ this.links.da_dr_date }}</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="1">
                      <div class="text-1">
                        Primary Category<span>{{
                          this.links.primary_category
                        }}</span>
                      </div>
                    </td>
                    <td colspan="1">
                      <div class="text-1">
                        Secondary Category
                        <span>{{ this.links.secondary_category }}</span>
                      </div>
                    </td>
                    <td v-if="this.links.missed_opportunities">
                      <div class="text-1">
                        Do Follow <span>{{ this.links.do_follow }}</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <div class="text-1">
                        Placement URL<span class="destination-link-text">{{
                          this.links.placement_authority
                        }}</span>
                      </div>
                    </td>
                    <td colspan="2">
                      <div class="text-1">
                        Publish date <span>{{ this.links.publish_date }}</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <div class="text-1">
                        Destination URL<span class="destination-link-text">{{
                          this.links.destination_link
                        }}</span>
                      </div>
                    </td>
                    <td colspan="2">
                      <div class="text-1">
                        Anchor Text: <span>{{ this.links.anchor_text }}</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="4">
                      <div class="text-1">
                        Live URL<span class="bText">{{
                          this.links.live_link
                        }}</span>
                      </div>
                    </td>
                  </tr>
                  <template v-if="!this.links.packageOrder">
                    <tr class="addon-text">
                      <td><span>Add-On</span></td>
                    </tr>
                    <tr class="addon-bg-box">
                      <td colspan="1" v-if="!this.links.missed_opportunities">
                        <div class="text-1">
                          Minimum Traffic<span>{{
                            this.links.minimum_traffic == ""
                              ? "No"
                              : this.links.minimum_traffic
                          }}</span>
                        </div>
                      </td>
                      <td colspan="2">
                        <div class="text-1">
                          Content Approval
                          <span>{{
                            this.links.content_approval == ""
                              ? "No"
                              : this.links.content_approval
                          }}</span>
                        </div>
                      </td>
                    </tr>
                  </template>
                </table>
              </div>
            </div>
          </b-card-text>
        </b-tab>
        <b-tab title="Message" v-if="!showOnlyDetail" @click="messageRead(this.links.id)">
          <b-card-text>
            <div class="content-box-style_body">
              <div class="message-box">
                <div class="recent-box-sec" v-if="messages.length > 0">
                  <div class="table-responsive">
                    <table class="addon-table">
                      <tr
                        v-for="recentMessage in messages"
                        :key="recentMessage.link_id"
                      >
                        <td>
                          <span>{{ recentMessage.date }}</span>
                          <div class="text-1">
                            {{ recentMessage.name }}
                          </div>
                          <div class="text-2" v-html="recentMessage.message"></div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <vue-editor
                  v-model="data.message"
                  :editorToolbar="customToolbar"
                ></vue-editor>
                <span
                  v-if="errors.message"
                  class="invalid-feedback"
                  style="display: block"
                  role="alert"
                >
                  <strong>{{
                    data.message.length > 0 ? "" : errors.message[0]
                  }}</strong>
                </span>
                <div class="btn-box">
                  <a class="btn-1" @click="sendMessage(this.links.id)">Send</a>
                </div>
              </div>
            </div>
          </b-card-text>
        </b-tab>
        <!-- <template v-if="estimatedArticleRequest"> -->
          <b-tab title="Content" v-if="!showOnlyDetail && (activeLink.article_current_status == 'Rejected' || activeLink.status == 'Pending Review')">
            <b-card-text>
              <div class="content-box-style_body">
                <div class="content-box">
                  <div
                    class="btn-box"
                    v-if="activeLink.status == 'Pending Review' && activeLink.article_current_status != 'Request Pending'"
                  >
                    <a href="javascript:;" v-b-modal.modal-box-3>Revision</a>
                    <a
                      href="javascript:;"
                      class="green-btn"
                      @click="approve(activeLink.id)"
                      >Approve</a
                    >
                  </div>
                  <template v-if="estimatedArticleRequest.status == 'Rejected' || estimatedArticleRequest.status == 'Required Content Approval'">
                      <ul class="mt-2">
                        <li>
                          <img src="@/assets/common/images/docx.png" />
                          Content file - {{ estimatedArticleRequest.link_id }}
                        </li>
                        <li>Uploaded on: {{ estimatedArticleRequest.uploaded_on }}</li>
                        <li>Status: {{ estimatedArticleRequest.status == 'Rejected' ? 'Revision' : 'Available' }}</li>
                        <li v-if="estimatedArticleRequest.status == 'Required Content Approval'">
                          <a
                            href="javascript:;"
                            @click="
                              download(estimatedArticleRequest.article_id)
                            "
                          >
                            <img
                              src="@/assets/common/images/cloud-computing.png"
                          /></a>
                        </li>
                      </ul>
                  </template>
                </div>
              </div>
            </b-card-text>
          </b-tab>
      <!-- </template> -->
      </b-tabs>
    </b-card>

    <b-modal
      id="modal-box-3"
      class="rivision-modal-box"
      centered
      title="Request For Article Revision"
      :no-close-on-backdrop="true"
    >
      <form>
        <b-row>
          <b-col md="12">
            <div class="form-group">
              <label>Revision Reason</label>
              <b-form-select
                id="input-3"
                v-model="contentData.reason_id"
                required
              >
                <option value="null">Please select an option</option>
                <option v-for="(pd, k) in reasons" :key="k" :value="pd.id">
                  {{ pd.reason }}
                </option>
              </b-form-select>
              <span
                v-if="errors.reason"
                class="required-text invalid-feedback"
                style="display: block"
                role="alert"
              >
                <strong>{{
                  contentData.reason_id == null ||
                  contentData.reason_id == "null"
                    ? errors.reason
                    : ""
                }}</strong>
              </span>
            </div>
          </b-col>
          <b-col md="12">
            <div class="form-group">
              <label>Detail</label>
              <b-form-textarea
                id="input-6"
                v-model="contentData.additional_details"
                type="text"
                rows="5"
                required
              ></b-form-textarea>
              <span
                v-if="errors.additional_details"
                class="required-text invalid-feedback"
                style="display: block"
                role="alert"
              >
                <strong>{{
                  contentData.additional_details.length > 0
                    ? ""
                    : errors.additional_details
                }}</strong>
              </span>
            </div>
          </b-col>
          <b-col md="12">
            <div class="btn-box-list">
              <a href="javascript:;" @click="revision(this.links.id)" class="btn-1"
                >Send
              </a>
            </div>
          </b-col>
        </b-row>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
const toast = useToast();
import { VueEditor } from "vue3-editor";
// import jquery from "jquery";
export default {
  props: ["activeLink", "reasons", "showOnlyDetail"],
  watch: {
    activeLink() {
      this.links = this.activeLink;
      this.linkId = this.links.id;
      this.messages = this.links.messages ? this.links.messages : "";
      // this.estimatedArticleRequest = this.links.estimatedArticleRequest ? this.links.estimatedArticleRequest : "";
      if(this.links.estimatedArticleRequest){
        this.estimatedArticleRequest = this.links.estimatedArticleRequest;
        this.contentTab.filename = this.estimatedArticleRequest.file_name;
      }else{
        this.estimatedArticleRequest = "";
        this.contentTab.filename = "";
      }
      this.count = this.links.counts;
      this.count = --this.count;
    },
    linkId: function(newVal) {
      if (newVal !== "" && this.tabIndex == 1) {
        this.$nextTick(function() {
          this.messageRead(newVal);
        });
      }
    }
  },
  components: {
    VueEditor,
  },
  mounted() {
    var tabVal = location.search.replace("?tab=", "");
    this.tabIndex = 0;
    if (tabVal) {
      if (tabVal == "message" || tabVal == "content") {
        this.tabSelected(tabVal);
      }
    }
  },
  data() {
    return {
      customToolbar: [["bold", "italic"]],
      messages: [],
      estimatedArticleRequest: [],
      links: [],
      linkId: '',
      revision_check: false,
      count: "",
      recent_messages: "",
      contentData: {
        additional_details: "",
        reason_id: null,
      },
      data: {
        message: "",
      },
      errors: [],
      tabIndex: 2,
      contentTab: {
        filename: '',
        link_id: '',
        uploaded_on: '',
        status: '',
        article_id: '',
      },
      messageAlreadyRead: false,
    };
  },
  methods: {
    tabSelected(tab) {
      if (tab === "message") {
        this.tabIndex = 1;
      } else if (tab === "content") {
        this.tabIndex = 2;
      }
    },
    closeOrderSidebar() {
      this.$emit("closeOrderSidebar");
    },
    async sendMessage(id) {
      this.errors = [];
      this.data["id"] = id;
      this.$parent.loading = true;
      try {
        let res = await this.$store.dispatch(
          "Link/sendMessageDataAction",
          this.data,
          {
            root: true,
          }
        );
        if (res.success) {
          this.$parent.loading = false;
          this.messages = res.data;
          this.data.message = "";
          toast.success(res.message, { timeout: 3000 });
        }
      } catch (error) {
        this.$parent.loading = false;
        this.errors["message"] = error.response.data.errors.message;
      }
    },

    async download(id) {
      this.$parent.loading = true;
      try {
        const response = await this.$store.dispatch(
          "Link/downloadDataAction",
          id
        );
        let blob = new Blob([response], {
          type: "application/msword",
        });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = this.contentTab.filename;
        link.click();
        this.$parent.loading = false;
      } catch (error) {
        this.$parent.loading = false;
        this.errors = error.response.data.errors;
      }
    },

    async revision(id) {
      if (
        this.contentData.reason_id == null ||
        this.contentData.reason_id == "null"
      ) {
        this.errors["reason"] = "Please select a reason";
      }
      if (this.contentData.additional_details == "") {
        this.errors["additional_details"] = "Please fill details";
      } else {
        this.contentData["id"] = id;
        this.$parent.loading = true;
        try {
          let res = await this.$store.dispatch(
            "Link/revisionDataAction",
            this.contentData,
            {
              root: true,
            }
          );
          if (res.success) {
            this.$parent.loading = false;
            toast.success(res.message, { timeout: 3000 });
            // jquery(".btn-close").trigger("click");
              setTimeout(() => {
                window.location = window.location.origin + '/order';
              }, 500);
          }
        } catch (error) {
          this.$parent.loading = false;
          this.errors = error.response.data.errors;
        }
      }
    },

    async approve(id) {
      this.$parent.loading = true;
      try {
        let res = await this.$store.dispatch("Link/approveDataAction", id);
        if (res.success) {
          this.$parent.loading = false;
          toast.success(res.message, { timeout: 3000 });
          setTimeout(() => {
            let currentURL = window.location.href;
            // Create a URL object
            let url = new URL(currentURL);
            // Remove the 'tab' parameter
            url.searchParams.delete('tab');
            window.location = url.href;
          }, 2000);
        }
      } catch (error) {
        this.errors = error.response.data.errors;
      }
    },

    async messageRead(id) {
      if(this.messageAlreadyRead == false){
        try {
          let res = await this.$store.dispatch("Dashboard/messageReadDataAction", id);
            if(res.success){
              this.messageAlreadyRead = true;
            }
        } catch (e) {
          console.log(e);
        }
      }
    },
  },
  computed: {
  },
};
</script>
