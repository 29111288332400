
import store from '@/store';
import Axios from 'axios';
import { useToast } from 'vue-toastification';
import url from '@/prod.js';
// import app from '@/main';

const toast = useToast();
const time = 1000;
let isSessionExpired = false;

const apiInstance = Axios.create({
    baseURL: url.baseUrl,
    paypalKey: url.paypalKey,
    adminUrl: url.adminUrl,
});

apiInstance.interceptors.request.use(config => {
    // app.config.globalProperties.$Progress.start();
    let token = store.getters['Auth/authToken'];
    if(token){
        config.headers.Authorization = `Bearer ${token}`;
        config.headers['Access-Control-Allow-Origin'] = url.baseUrl;
        config.headers['Access-Control-Allow-Credentials'] = true;
    }
    return config;
});

apiInstance.interceptors.response.use(
    response => {
        // app.config.globalProperties.$Progress.finish();
        return response
    },
    error => {
        if(error.response.status === 503){
            window.location = url.appUrl + '/503';
        }
        
        // app.config.globalProperties.$Progress.finish();
        else if(error.response.status === 401 && !isSessionExpired){
            isSessionExpired = true;

            toast.error('Session Expired');
            setTimeout(()=>{
                window.localStorage.clear();
                window.location.reload();
            },time)
        }

        return Promise.reject(error);  
    }
);

export default apiInstance;