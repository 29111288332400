<template>
  <div class="home">
    <LoaderComponent v-show="loading" />
    <HeaderView />
    <section
      class="order-page-main-sec agency-design-grid-right missed-opportunitie-order-sec"
    >
      <div class="desktop-respovsive-main-sec">
        <div class="place-order-top-sec">
          <div class="order-list-tabs">
            <div class="links-tabs">
              <h2 class="text-heading">Premium Placements</h2>
              <p>Uncover overlooked backlink opportunities for enhanced SEO.</p>
            </div>
            <div
              class="order-input-field"
              :class="{ 'order-title-error': errors.order_title }"
            >
              <div class="form-group">
                <label>Order Title*</label>
                <input
                  type="text"
                  class="form-control"
                  id="formGroupExampleInput"
                  maxlength="100"
                  placeholder="Add any title here, that will help you track this order later"
                  v-model="order_title"
                  :class="{
                    'has-error border-danger':
                      order_title.length > 0 ? '' : errors.order_title,
                  }"
                />
                <span
                  v-if="errors.order_title"
                  class="invalid-feedback"
                  style="display: block"
                  role="alert"
                >
                  <strong>{{
                    order_title.length > 0 ? "" : errors.order_title[0]
                  }}</strong>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          class="editor-dashboard-sec-2 opportunitie-link-detail"
          v-for="(link, key) in links"
          :key="key"
        >
          <div class="link-detail-count">
            <div class="link-text">
              <div class="text-1">
                {{ Object.keys(links[key])[0] }}
              </div>
              <span>
                {{ linkCount[key] }}
                {{ linkCount[key] > 1 ? "Links" : "Link" }}</span
              >
              <b>USD {{ numberFormat(linkAmount[key], "currency") }}</b>
            </div>
            <div class="delete-btn">
              <a
                href="javascript:;"
                v-if="links.length > 1"
                @click="
                  removeEntireLink(
                    key,
                    links[key][Object.keys(links[key])[0]][0].id
                  )
                "
                ><i class="fas fa-trash-alt"></i
              ></a>
            </div>
          </div>
          <div class="table-responsive">
            <table class="addon-table">
              <tr
                v-for="(val, i) in links[key][Object.keys(links[key])[0]]"
                :key="val.id"
              >
                <td>
                  <form>
                    <table class="addon-table">
                      <tr>
                        <th>Enter Destination URL</th>
                        <th>Enter Anchor Text</th>
                      </tr>
                      <tr>
                        <td>
                          <input
                            type="text"
                            v-model="
                              links[key][Object.keys(links[key])[0]][i]
                                .destination_url
                            "
                            @input="setUrl($event.target.value, key, i)"
                            class="form-control"
                            :class="{
                              'has-error border-danger':
                                validations.links[key][i].destination_url
                                  .is_valid,
                            }"
                            :placeholder="
                              validations.links[key][i].destination_url.is_valid
                                ? 'Enter Destination Url'
                                : 'Enter Destination Url'
                            "
                          />
                          <span></span>

                        </td>
                        <td>
                          <input
                            type="text"
                            v-model="
                              links[key][Object.keys(links[key])[0]][i]
                                .anchor_text
                            "
                            @input="setText($event.target.value, key, i)"
                            class="form-control"
                            :class="{
                              'has-error border-danger':
                                validations.links[key][i].anchor_text.is_valid,
                            }"
                            :placeholder="
                              validations.links[key][i].anchor_text.is_valid
                                ? 'Enter Anchor Text'
                                : 'Enter Anchor Text'
                            "
                          />
                          <span> </span>
                        </td>
                      </tr>
                    </table>
                    <div class="per-15 addons-box">
                          <div class="addon_single" >
                            <div class="checkbox">
                              <label>Addon</label>
                              <div class="checkboxBorder">
                                <input
                                  class="selectedCheckbox"
                                  type="checkbox"
                                  v-model="links[key][Object.keys(links[key])[0]][i].content_approval"
                                  :value="true"
                                  @click="
                                    setSelectedAddon(
                                      key,
                                      i,
                                      $event.target.checked,
                                      val
                                    )"
                                />
                                <span class="check-text">
                                  Content Approval ($25)
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                  </form>
                </td>
                <td>
                  <table class="addon-table">
                    <tr>
                      <th>DA</th>
                      <!-- <th>DR</th> -->
                      <!-- <th>Addon</th> -->
                      <th>Category</th>
                      <th>Traffic</th>
                      <th>Spam Score</th>
                      <th>Link Cost</th>
                    </tr>
                    <tr>
                      <!-- <td>{{ val.exact_da }}</td>
                      <td>{{ val.exact_dr }}</td> -->
                      <td>{{ val.product }}</td>
                      <td>{{ val.category }}</td>
                    
                      <td>{{ Math.floor(val.traffic).toLocaleString() }}</td>
                      <td>{{ val.spam_score }}</td>
                      <td>
                        {{ numberFormat(val.total_cost, "currency") }}
                      </td>
                    </tr>
                  </table>
                </td>
                <td>
                  <div class="btn-box">
                    <a href="javascript:;"
                      ><i @click="copyLink(key, val)" class="fas fa-copy"></i
                    ></a>
                    <a
                      href="javascript:;"
                      v-if="links[key][Object.keys(links[key])[0]].length > 1"
                      @click="removeLink(key, val)"
                      ><i class="fas fa-trash-alt"></i
                    ></a>
                  </div>
                </td>
              </tr>
            </table>
            <div class="proceed-box">
              <div class="btn-box-list">
                <a
                  href="javascript:;"
                  @click="addLink(key, Object.keys(links[key])[0])"
                  class="btn-1"
                  ><i class="fas fa-plus"></i> Add another Link</a
                >
              </div>
            </div>
          </div>
        </div>

        <!-- Proceed Box -->
        <div class="editor-dashboard-sec-2 opportunitie-proceed-pay-box">
          <div class="proceed-box draft-table-box">
            <div class="btn-box-list">
              <a
                href="javascript:;"
                class="btn-3"
                @click="saveDraftOrder('custom')"
                :class="{ disabled: activeProduct }"
                >Save to Draft</a
              >
            </div>
            <div class="total-price-table-box proceed-pay-box">
              <div class="summary-box">
                <table style="width: 100%">
                  <tr>
                    <td>Placement URLs</td>
                    <td>{{ placementURLCount }}</td>
                  </tr>
                  <tr>
                    <td>Total Link</td>
                    <td>{{ totalLinksCount }}</td>
                  </tr>
                  <!-- <tr>
                    <td>Addons:</td>
                    <td>{{ addon_count }}</td>
                    <td>
                      {{ numberFormat(addon_total, "currency") }}
                    </td>
                  </tr> -->
                  <tr>
                    <td>Total:</td>
                    <td>
                      <b>USD {{ numberFormat(totalAmount, "currency") }}</b>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="4" style="padding: 0">
                      <a
                        href="javascript:;"
                        @click="submit"
                        class="btn-3"
                        :class="{ diableButtonTooltip: links.length < 1 }"
                        >Proceed to Pay</a
                      >
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style></style>

<script>
import { useToast } from "vue-toastification";
const toast = useToast();
import HeaderView from "@/components/layout/HeaderComponent.vue";
import LoaderComponent from "@/components/LoaderComponent.vue";
import swal from "sweetalert2";
import { numberFormat } from "@/prod.js";
import moment from "moment";
import LZString from "lz-string";
import url from "@/prod.js";

export default {
  name: "ProductView",

  components: {
    HeaderView,
    LoaderComponent,
  },
  async mounted() {
    localStorage.removeItem("draft-order-id");
    var url = window.location.pathname;
    var id = url.substring(url.lastIndexOf("/") + 1);
    if (this.$route.params.id) {
      this.loading = true;
      this.draft_order_id = id;
      localStorage.setItem("draft-order-id", id);
      this.getDraftOrder(id);
    } else {
      if (
        !localStorage.getItem("opportunity-links") ||
        localStorage.getItem("opportunity-links") == null
      ) {
        this.$router.push("/missed-opportunity");
      }
      this.getItems();
    }
    this.getAddonProduct();
    this.timer = setInterval(() => {
      this.saveDraftOrder();
    }, 20000);
  },
  data() {
    return {
      numberFormat,
      LZString,
      loading: true,
      appURL: url.appUrl,
      timer: null,
      placementUrls: {
        key: [],
        links: [],
      },
      links: [],
      activeProduct: true,
      placementURLCount: 0,
      totalLinksCount: 0,
      totalAmount: 0,
      addon_count: 0,
      addon_total: 0,
      link_total: 0,
      counter: 0,
      linkCount: [],
      linkAmount: [],
      totalLinksAmount: [],
      finalLinks: [],
      summaryPaymentData: [],
      validations: {
        links: [],
      },
      errors: [],
      order_title: "",
      mainProducts: {},
      selected_opportunities: [],
      currentDate: moment(new Date()).format("DD/MM/YYYY"),
      medium: "direct",
      source: "direct",
      draft_order_id: null,
      order_type: "missed_opportunity",
      addon_products: []
    };
  },
  methods: {
    async getAddonProduct() {
      try {
        let res = await this.$store.dispatch(
          "Product/getAddonProductDataAction"
        );
        this.addon_products = res.addon_products;
      } catch (e) {
        this.loaded = false;
      }
    },
    submit() {
      if (this.order_title == "") {
        this.errors["order_title"] = ["Please enter valid order title"];
        this.loaded = false;
        swal.fire({
          icon: "warning",
          text: "Please fill order title first!",
          willClose: () => {
            this.handleValidationScroll();
          },
        });
      } else {
        if (this.links.length > 0) {
          this.loading = true;
          this.finalLinks = [];
          this.summaryPaymentData = [];
          var errCount = 0;
          var urlErrCount = 0;
          var totalPlacementCost = 0;
          for (const key in this.links) {
            const obj = this.links[key];
            for (const placementUrlKey in obj) {
              const arr = obj[placementUrlKey];
              totalPlacementCost = 0;
              arr.forEach((val, i) => {
                if (val.anchor_text == "" || val.anchor_text == undefined) {
                  this.loading = false;
                  this.validations.links[key][i].anchor_text.is_valid = true;
                  this.validations.links[key][i].anchor_text.error =
                    "Enter Anchor Text";
                  errCount += 1;
                }
                if (val.destination_url == "" || val.destination_url == undefined) {
                  this.loading = false;
                  this.validations.links[key][
                    i
                  ].destination_url.is_valid = true;
                  this.validations.links[key][i].destination_url.error =
                    "Enter Destination Url";
                  errCount += 1;
                } else if (val.destination_url != undefined || val.destination_url != "") {
                  this.loading = false;
                  const destURL = val.destination_url;
                  if (!this.isValidURL(destURL.trim().toLowerCase())) {
                    this.validations.links[key][
                      i
                    ].destination_url.is_valid = true;
                    urlErrCount += 1;
                  } else {
                    const addons = [];
                    if(val.content_approval){
                      this.addon_products.forEach((addonVal) => {
                        if(addonVal.name == "Content Approval"){
                          addonVal.options.forEach((addonOption) => {
                            addons.push({
                              id: addonOption.id,
                              addon_id: addonOption.addon_id,
                              type: addonOption.addons_data.type,
                              price_type: addonOption.addons_data.price_type,
                              dependent_da: addonOption.addons_data.dependent_da,
                              addon_name: addonOption.name,
                              name: addonOption.name,
                              cost: addonOption.price,
                              product_id: val.product_id,
                              category_id: val.category_id,
                            });
                          });
                        }
                      });
                    }
                    totalPlacementCost += val.total_cost;
                    this.finalLinks.push({
                      id: "_" + Math.random().toString(36).substr(2, 9),
                      opportunity_list_id: val.id,
                      placement_url: val.placement_url,
                      product: {
                        id: val.product_id,
                        name: val.product,
                      },
                      exact_da: val.exact_da,
                      exact_dr: val.exact_dr,
                      addons: addons,
                      outreacher_id: val.outreacher_id,
                      text: val.anchor_text,
                      url: val.destination_url,
                      traffic: val.traffic,
                      primary_category: {
                        id: val.category_id,
                        name: val.category,
                        total_cost: val.content_approval ? val.total_cost - 25 : val.total_cost,
                      },
                      secondry_category: val.secondary_category,
                      trialDA: true,
                      draft_order_id: "",
                      order_title: this.order_title,
                      total: val.total_cost,
                    });
                    
                  }
                }
              });
              this.summaryPaymentData.push({
                placement_url: placementUrlKey,
                total_links: arr.length,
                total_cost: totalPlacementCost,
              });
            }
          }
          if (errCount > 0) {
            this.loading = false;
            swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Please fill all the required highlighted fields!",
              willClose: () => {
                this.handleValidationScroll();
              },
            });
          } else if (urlErrCount > 0) {
            this.loading = false;
            swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Please enter a valid URL!",
              willClose: () => {
                this.handleValidationScroll();
              },
            });
          } else {
            this.proceed();
          }
        }
      }
    },
    proceed() {
      localStorage.setItem("order-title", this.finalLinks[0].order_title);
      localStorage.setItem(
        "links",
        LZString.compress(JSON.stringify(this.finalLinks))
      );
      localStorage.setItem("links-total", this.link_total);
      localStorage.setItem("sub-total", this.totalAmount);
      localStorage.setItem("order-total", this.totalAmount);
      // localStorage.setItem("addons-count", this.addon_count);
      // localStorage.setItem("addons-total", this.addon_total);
      localStorage.removeItem("selected-opportunity");
      localStorage.setItem(
        "selected-opportunity",
        LZString.compress(JSON.stringify(this.selected_opportunities))
      );
      localStorage.setItem(
        "summary-data",
        LZString.compress(JSON.stringify(this.summaryPaymentData))
      );
      localStorage.removeItem("discount");
      localStorage.removeItem("couponCode");
      // For GA
      localStorage.setItem("main_products", JSON.stringify(this.mainProducts));
      // this.$router.push("/order-summary");
      window.location = this.appURL + "/order-summary";
    },
    isValidURL(url) {
      var isValid = "";
      var match = url.match(/(?:https:\/\/)?(?:www\.)?(.*?)\./);
      const extractedValue = match ? match[1] : "";
      if (/^w+$/.test(extractedValue) && extractedValue.length > 3) {
        isValid = false;
      } else {
        if (/^w+$/.test(extractedValue) && extractedValue.length == 3) {
          const urlPattern =
            /^(?:(?:(?:https?|ftp):\/\/)?(?:www\.)?)?(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))(?:\/(?:[^\s/]*\/)?)?)?(?:\?(?:\S*))?(?:\.(?![^\s.]*$))?$/i;
          isValid = urlPattern.test(url);
        } else {
          const urlPattern =
            /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:(?!www)[/?#]\S*)?$/;
          isValid = urlPattern.test(url);
        }
      }
      return isValid;
    },
    handleValidationScroll() {
      setTimeout(() => {
        const invalidField = document.querySelector(".has-error");
        invalidField.scrollIntoView({ behavior: "smooth" });
      }, 500);
    },
    getItems() {
      this.links = JSON.parse(
        LZString.decompress(localStorage.getItem("opportunity-links"))
      );
      this.updateTotal();
      this.addValidation();
      this.loading = false;
    },
    addValidation() {
      this.links.forEach((val, index) => {
        const placementLength = val[Object.keys(this.links[index])[0]].length;
        for (var i = 0; i < placementLength; i++) {
          if (!this.validations.links[index]) {
            this.validations.links[index] = [];
          }
          this.validations.links[index].push({
            anchor_text: {
              is_valid: false,
            },
            destination_url: {
              is_valid: false,
            },
          });
        }
      });
    },
    updateTotal() {
      this.placementURLCount = 0;
      this.totalLinksCount = 0;
      this.totalAmount = 0;
      this.summaryPaymentData = [];
      this.selected_opportunities = [];
      let id = "";
      this.links.forEach((val, i) => {
        id = "";
        this.linkCount[i] = 0;
        this.linkAmount[i] = 0;
        val[Object.keys(val)[0]].forEach((val2) => {
          this.linkCount[i] += val2.link_count;
          this.linkAmount[i] += val2.total_cost;
          this.totalLinksCount += val2.link_count;
          this.totalAmount += val2.total_cost;
          id = val2.id;
        });
        this.selected_opportunities.push(id);
      });
      this.placementURLCount = this.links.length;
    },
    addLink(key, url) {
      const lastIndex = this.links[key][url].length;
      const newObject = { ...this.links[key][url][0] };
      this.links[key][url].push(newObject);
      this.links[key][url][lastIndex].destination_url = "";
      this.links[key][url][lastIndex].anchor_text = "";
      if(this.links[key][url][lastIndex].content_approval){
        this.links[key][url][lastIndex].total_cost -= 25;
      }
      this.links[key][url][lastIndex].content_approval = false;
      this.validations.links[key].push({
        anchor_text: {
          is_valid: false,
        },
        destination_url: {
          is_valid: false,
        },
      });
      this.updateTotal();
    },
    copyLink(key, link) {
      const copiedLink = { ...link };
      this.links[key][copiedLink.placement_url].push(copiedLink);
      this.validations.links[key].push({
        anchor_text: {
          is_valid: false,
        },
        destination_url: {
          is_valid: false,
        },
      });
      this.updateTotal();
    },
    removeLink(key, link) {
      const indexToRemove = this.links[key][link.placement_url].findIndex(
        (l) => l === link
      );
      if (indexToRemove !== -1) {
        this.validations.links[key].splice(indexToRemove, 1);
        this.links[key][link.placement_url].splice(indexToRemove, 1);
      }
      this.updateTotal();
    },
    removeEntireLink(key, id) {
      var index = this.selected_opportunities.indexOf(id);
      if (index !== -1) {
        this.selected_opportunities.splice(index, 1);
      }
      this.validations.links.splice(key, 1);
      this.links.splice(key, 1);
      this.updateTotal();
    },
    setText(value, key, index) {
      if (value.length > 0) {
        this.validations.links[key][index].anchor_text.is_valid = false;
      } else {
        this.validations.links[key][index].anchor_text.is_valid = true;
      }
    },
    setUrl(value, key, index) {
      if (value.length > 0) {
        this.validations.links[key][index].destination_url.is_valid = false;
      } else {
        this.validations.links[key][index].destination_url.is_valid = true;
      }
    },
    setSelectedAddon(key, index, checked, link){
      const currLink = { ...link };
      if(checked){
        this.links[key][currLink.placement_url][index].content_approval = true;
        this.links[key][currLink.placement_url][index].total_cost += 25;
        this.linkAmount[key] += 25;
        this.totalAmount += 25;
      }else{
        this.links[key][currLink.placement_url][index].content_approval = false;
        this.links[key][currLink.placement_url][index].total_cost -= 25;
        this.linkAmount[key] -= 25;
        this.totalAmount -= 25;
      }
    },
    async saveDraftOrder(saveByUser) {
      if (this.order_title == "" && saveByUser != "custom") {
        this.order_title = "Draft Autosaved " + this.currentDate;
      }
      if (saveByUser == "custom") {
        this.loaded = true;
        if (saveByUser == "custom" && this.order_title == "") {
          this.loaded = false;
          swal.fire({
            icon: "warning",
            text: "Please fill order title first!",
            willClose: () => {
              this.handleValidationScroll();
            },
          });
        }
      }
      try {
        let res = await this.$store.dispatch(
          "Product/saveDraftOrderAction",
          {
            order_type: this.order_type,
            draft_order_id: this.draft_order_id,
            order_title: this.order_title,
            total: this.totalAmount,
            links: this.links,
            order_medium: this.medium,
            order_source: this.source,
          },
          {
            root: true,
          }
        );
        if (res.success) {
          if (saveByUser == "custom") {
            this.loaded = false;
            toast.success(res.message, {
              timeout: 3000,
            });
            window.location = this.appURL + "/draft-order";
          } else {
            localStorage.setItem("draft-order-id", res.draft_order_id);
            if (this.draft_order_id != res.draft_order_id) {
              const newPathname = "/missed-opportunity-order/" + res.draft_order_id;
              this.$router.replace(newPathname);
              this.draft_order_id = res.draft_order_id;
            } else if (!this.$route.params.id) {
              this.draft_order_id = res.draft_order_id;
              if (
                window.location.pathname.endsWith("/missed-opportunity-order/")
              ) {
                this.$router.replace(
                  window.location.pathname + res.draft_order_id
                );
              } else {
                this.$router.replace(
                  window.location.pathname + "/" + res.draft_order_id
                );
              }
            }
          }
        }
      } catch (error) {
        this.loaded = false;
        this.errors = error.response.data.errors;
      }
    },
    async getDraftOrder(id) {
      try {
        let res = await this.$store.dispatch("Link/getEditLinksDataAction", id);
        if (res.draft_order) {
          this.draftOrder = res.draft_order;
          this.links = JSON.parse(res.draft_order.links);
          this.draft_order_id = res.draft_order.id;
          this.order_title = res.draft_order.order_title;
        } else {
          toast.error("Draft Order Already Deleted", {
            timeout: 3000,
          });
        }
        this.updateTotal();
        this.addValidation();
        this.loading = false;
      } catch (e) {
        if (e.response.data.error == "Access Denied") {
          localStorage.removeItem("draft-order-id");
          this.$router.push("/dashboard");
        }
        this.loading = false;
      }
    },
  },
  computed: {},
  beforeUnmount() {
    clearInterval(this.timer);
  },
};
</script>
