<template>
    <div class="home">
      <LoaderComponent v-show="loading" />
      <HeaderView />
      <section class="
            dashboard-page-main-sec
            order-page-main-sec
            track-order-main-sec
            agency-design-grid-right
            draft-order-page-sec
            domain-detail-page-sec
            destination-detail-page-sec
            order-detail-main-sec
          ">
  
        <div class="row">
          <div class="col-md-12">
            <div class="order-input-field">
              <div class="reports-heading">
                <div class="text-heading-2"> <router-link to="/destination"><i class='bx bx-arrow-back'></i></router-link>   {{ this.filter.domain }}</div>
              </div>
            </div>
            <div class="search-field">
              <div class="search-filter">
              <label>Filter by Destination URL</label>
              <b-form-input
                    id="input-2"
                    v-model="filter.destination"
                    placeholder="www.example.com"
                    required
              ></b-form-input>
            </div>
              <div class="date-picker-box">
                <div class="form-row">
                  <div class="col-md-12">
                    <label>Filter by Created Date</label>

                    <div class="input-daterange input-group" id="m_datepicker">
                      <DatePickerComponent
                        :minDate="minDate"
                        :maxDate="maxDate"
                        :options="options"
                        :position="position"
                        :refVal="'datepickerMenu'"
                        :orderDate="filter.order_date"
                        @getSearchData="setDate"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="btn-box-list">
                <a
                  href="javascript:;"
                  class="btn-1"
                  @click="generateDestinationReport()"
                  >Search
                </a>
                <a
                  href="javascript:;"
                  class="btn-1 btn-2"
                  @click="resetFilter()"
                  >Reset
                </a>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="editor-dashboard-sec-2 approval-list-sec draft-order-sec">

              <!-- <div class="all-url-check-box">
                 <div class="checkbox">
                    <label >
                      <input
                        type="checkbox"
                        id="checkbox"
                        v-model="checkedAll"
                        @change="selectAllDestinations('all')"
                      />
                      Select All
                    </label>
   
                  </div>
                 
                      <a
                  href="javascript:;"
                  class="btn-1"
                  @click="generateReport()"
                  >Generate Report
                </a>
              </div> -->

              <!-- <div class="table-responsive">
                <div :class="{'scrollable': domainDestinations.length > 10}">

                  <table class="addon-table">
                    <tr></tr>
                    <tr v-for="(domainDestination, i) in domainDestinations" :key="domainDestination.id">
                      <td>
                        <div class="checkbox">
                          <label :for="'checkbox-' + i" style="margin-bottom: -2px">
                            <input
                              type="checkbox"
                              :id="'checkbox-' + i"
                              :value="domainDestination.destination_link"
                              v-model="checkedItem[i]"
                              @change="selectAllDestinations('single')"
                            />
                            {{ domainDestination.destination_link }}
                          </label>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div> -->

              <!-- Generate Report -->
              <div class="destination-report-sec">
                  <div class="col-md-12">
                    <div class="order-input-field">
                      <div class="reports-heading">
                        <div class="text-heading-2">Destination URL Report</div>
                      </div>
                      <div class="date-picker-box">
                        <div class="form-row">
                          <div class="col-md-12">
                            <div class="download-icon-list">
                              <div @click="downloadFile()" class="top_icons">
                                Download Report <i class="fas fa-arrow-down" v-b-tooltip.hover.top="'Export CSV'"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="table-responsive">
                    <div :class="{'scrollable': selectedDestinationData.length > 10}">
                  <table class="addon-table">
                    <tr>
                      <th>Link ID</th>
                      <th>Destination Url</th>
                      <th>Live Url</th>
                      <th>Anchor Text</th>
                      <th>Published DA</th>
                      <th>Created Date</th>
                      <th>Published Date</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                    <tr v-for="domainDetail in selectedDestinationData" :key="domainDetail.id">
                       <td>{{ domainDetail.id }}</td>
                      <td class="destination-text" v-b-tooltip.hover.top="domainDetail.destination_link">
                        <a @click="copyText(domainDetail.destination_link)" >
                          <span>{{ domainDetail.destination_link }}</span>
                          <i  v-if="domainDetail.destination_link" class='bx bx-copy'
                          ></i>
                      
                        </a>
                      </td>
                      <td class="destination-text" v-if="domainDetail.live_link" v-b-tooltip.hover.top="domainDetail.live_link">
                        <template v-if="domainDetail.live_link !== null
                              ? domainDetail.live_link
                              : ''
                          "><a @click="copyText(domainDetail.live_link)"><span>{{ domainDetail.live_link }} </span> <i v-if="domainDetail.live_link !== true
                            ? domainDetail.live_link
                            : domainDetail.live_link
                        "   class='bx bx-copy'></i></a>
                        </template>
                      </td>
                      <td v-else></td>
                      <td><div v-b-tooltip.hover.top="domainDetail.anchor_text">{{ domainDetail.anchor_text }}</div></td>
                      <td>{{ domainDetail.domain_authority || '' }}</td>
                      <td>{{ domainDetail.created_date }}</td>
                      <td>{{ domainDetail.publish_date || '' }}</td>
                      <td class="status-btns">
                        <a
                          class="btn-success" style="backgroundColor: #41b883; color: #fff"
                          v-if="domainDetail.status == 'Delivered'"
                          >{{ domainDetail.status }}</a
                        >
                        <a
                          class="btn-secondary" style="backgroundColor: #f4516c; color: #fff"
                          v-if="domainDetail.status == 'Not Delivered'"
                          >{{ domainDetail.status }}</a
                        >
                        <a
                          class="btn-secondary" style="backgroundColor: #ffb822; color: #fff"
                          v-if="domainDetail.status == 'Outreaching'"
                          >{{ domainDetail.status }}</a
                        >
                        <a
                          class="btn-success" style="backgroundColor: #00b6cb; color: #fff"
                          v-if="domainDetail.status == 'In Writing'"
                          >{{ domainDetail.status }}</a
                        >
                        <a
                          class="btn-success" style="backgroundColor: #36a3f7; color: #fff"
                          v-if="domainDetail.status == 'Pending Review'"
                          >{{ domainDetail.status }}</a
                        >
                        <a
                          class="btn-success" style="backgroundColor: #9816f4; color: #fff"
                          v-if="domainDetail.status == 'Refund'"
                          >{{ domainDetail.status }}</a
                        >
                        <a
                          class="btn-success" style="backgroundColor: #36a3f7; color: #fff"
                          v-if="domainDetail.status == 'Link Submission'"
                          >{{ domainDetail.status }}</a
                        >
                        <a
                          class="btn-success" style="backgroundColor: red; color: #fff"
                          v-if="domainDetail.status == 'Cancel'"
                          >{{ domainDetail.status }}</a
                        >
                      </td>
                      <td colspan="2" class="action-btns">
                        <!-- <div class="btn-box">
                          <a target="_blank" :href="`/order/${domainDetail.id}`" title="View"><i class="fas fa-eye"></i
                          ></a>
                        </div> -->
                        <div class="btn-group">
                          <button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown">
                            ...
                          </button>
                          <ul class="dropdown-menu" role="menu">
                            <li><a href="javascript:;" @click="openOrderSidebar(domainDetail.id)">View Link Detail</a></li>
                            <li><a target="_blank" :href="`/order-detail/${domainDetail.order_id}`">View Order Detail</a></li>
                          </ul>
                        </div>
                      </td> 
                    </tr>
                  </table>
                    </div>
                </div>
              </div>
              <div class="order-detail-box">
                  <div class="detail-sideabar-box" :class="{ opensideBar: orderSidebarOpen }">
                    <LinksDetail :activeLink="activeLink" :reasons="reasons" :contentTabData="contentTabData"
                      @closeOrderSidebar="closeOrderSidebar" :showOnlyDetail="true"></LinksDetail>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </template>
  
<script>
    import HeaderView from "@/components/layout/HeaderComponent.vue";
    import LoaderComponent from "@/components/LoaderComponent.vue";
    import DatePickerComponent from "@/components/DatePickerComponent.vue";
    import LinksDetail from "@/components/orderLinksComponents/LinkDetailComponent.vue";
    import { useToast } from "vue-toastification";
    const toast = useToast();
    export default {
      components: {
        HeaderView,
        LoaderComponent,
        DatePickerComponent,
        LinksDetail,
      },
      mounted() {
        this.selectedDomain();
      },
      data() {
        return {
          loading: false,
          filter: {
            s: "",
            e: "",
            order_date: "",
            destination: "",
            domain: "",
            download: "no"
          },
          domainDestinations: [],
          checkedItem: [],
          selectedDestinationData: [],
          showReport: false,
          checkedAll: false,
          minDate: new Date(new Date().setMonth(new Date().getMonth() - 1)),
          maxDate: new Date(new Date().setMonth(new Date().getMonth() + 1)),
          options: {
            format: "MM/DD/YYYY",
            useCurrent: false,
          },
          orderSidebarOpen: false,
          orderSidebarClose: true,
          activeLink: [],
          reasons: [],
          contentTabData: [],
        };
      },
      methods: {
        selectAllDestinations(type){
          if(type == 'all'){
            this.checkedItem = [];
            if(this.checkedAll){
              this.domainDestinations.forEach((_, i) => {
                this.checkedItem[i] = true;
              });
            }
          }
          else if(type == 'single'){
            if(this.checkedItem.filter(Boolean).length < this.domainDestinations.length){
              this.checkedAll = false;
            }else{
              this.checkedAll = true;
            }
          }
        },
        selectedDomain(){
          var url = window.location.pathname;
          var domain = url.substring(url.lastIndexOf('/') + 1);
          if (domain) {
            this.filter.domain = decodeURIComponent(domain);
            if(localStorage.getItem('destinationLink')){
              this.filter.destination = localStorage.getItem('destinationLink');
              localStorage.removeItem('destinationLink');
            }
            this.generateDestinationReport();
          }
        },
        setDate(value) {
          this.filter.order_date = value;
        },
        async generateDestinationReport(){
          this.loading = true;
          this.filter.download = 'no';
          try {
            let res = await this.$store.dispatch(
              "AgencyReport/SelectedDestinationsReportDataAction",
              {
                domain: this.filter.domain,
                destination: this.filter.destination,
                date: this.filter.order_date
              }
            );
            this.selectedDestinationData = res;
            this.loading = false;
          } catch (e) {
            this.loading = false;
          }
        },
        redirectURL(url) {
          const pattern = /^(http:\/\/|https:\/\/)/i;
          const hasHttpOrHttps = pattern.test(url);
    
          let finalUrl = url;
          if (!hasHttpOrHttps) {
              finalUrl = 'https://' + url;
          }
    
          const newTab = window.open(finalUrl, '_blank');
          if (newTab) {
              newTab.focus();
          }
        },
        resetFilter() {
          this.filter.destination = "";
          this.filter.order_date = "";
          this.generateDestinationReport();
        },
        openOrderSidebar(id) {
          var arr = this.selectedDestinationData;
          arr = arr.filter(x => x.id === id);
          this.activeLink = arr[0];
          setTimeout(() => (this.orderSidebarOpen = true), 500);
          setTimeout(() => (this.orderSidebarClose = false), 500);
        },
        closeOrderSidebar() {
          setTimeout(() => (this.orderSidebarOpen = false), 500);
        },
        async downloadFile() {
          this.loaderText = 'Please wait while we are processing your request';
          this.loading = true;
          this.filter.download = 'yes';
          try {
            const response = await this.$store.dispatch(
              "AgencyReport/SelectedDestinationsReportDataAction",
              { 
                domain: this.filter.domain,
                destination: this.filter.destination,
                date: this.filter.order_date,
                download: this.filter.download,
              }
            );
            let blob = new Blob([response], {
              type: "application/csv",
            });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = 'Destination-URL-Report.csv';
            link.click();
            this.loaderText = '';
            this.loading = false;
          } catch (e) {
            this.loaderText = '';
            this.loading = false;
          }
        },
        copyText(value) {
          const tempInput = document.createElement("input");
          tempInput.value = value;
          document.body.appendChild(tempInput);
          tempInput.select();
          document.execCommand("copy");
          document.body.removeChild(tempInput);
          toast.success("URL copied", { timeout: 1500 });
        },
      }
    };
</script>
  