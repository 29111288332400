<template>
    <form>
        <table class="addon-table links-table-list" :class="{'orderDetail': hideOrderId == true}">
            <tr>
                <th @click="sortByColumn('id')">Link ID <span><i class="fas fa-sort"></i></span></th>
                <th v-if="hideOrderId == false" @click="sortByColumn('order_id')">Order ID <span><i class="fas fa-sort"></i></span></th>
                <th>Order By</th>
                <th @click="sortByColumn('product')">DA/DR<span><i class="fas fa-sort"></i></span></th>
                <th>Category</th>
                <th @click="sortByColumn('destination_link')">Destination URL <span><i class="fas fa-sort"></i></span></th>
                <th @click="sortByColumn('anchor_text')">Anchor Text <span><i class="fas fa-sort"></i></span></th>
                <th @click="sortByColumn('live_link')">Live URL <span><i class="fas fa-sort"></i></span></th>
                <th @click="sortByColumn('status')">Status <span><i class="fas fa-sort"></i></span></th>
                <th>Action</th>
            </tr>
            <tr v-for="link in links" :key="link.id" 
            :class="{ pendingReviewBg: link.status == 'Pending Review', 
            'premium-placement': link.missed_opportunities, 
            'enterprise-bg': link.status != 'Pending Review' && link.packageOrder && link.order_type == 'Enterprise', 
            'digitalPr-bg': link.status != 'Pending Review' && link.packageOrder && link.order_type == 'Digital PR' }">
                <td @click="openOrderSidebar(link.id)">{{ link.id }}</td>
                <td v-if="hideOrderId == false">{{ link.order_id }}</td>
                <td>{{ link.order_by }}</td>
                <!-- <td><span v-if="link.missed_opportunities">DA </span>{{ link.exact_da }}</td> -->
                <td>{{ link.product_name }}</td>
                <td>{{ link.primary_category }}</td>
                <td v-b-tooltip.hover.top="link.destination_link"><a @click="redirectURL(link.destination_link)"><span>{{
                    link.destination_link }}</span> <i v-if="link.destination_link !== true
            ? link.destination_link
            : link.destination_link
        " class="fas fa-external-link-alt"></i></a></td>
                <td v-b-tooltip.hover.top="link.anchor_text"><div>{{ link.anchor_text }}</div></td>
                <td><template v-if="link.live_link !== true
                        ? link.live_link
                        : link.live_link
                    "><a v-b-tooltip.hover.top="link.live_link" @click="redirectURL(link.live_link)"><span>{{ link.live_link }} </span> <i v-if="link.live_link !== true
            ? link.live_link
            : link.live_link
        " class="fas fa-external-link-alt"></i></a></template></td>

                <!-- <td>
                    <a class="btn-success">{{ link.status }}</a>
                </td> -->
                <td>
                      <a
                        class="btn-success" style="backgroundColor: #41b883; color: #fff"
                        v-if="link.status == 'Delivered'"
                        >{{ link.status }}</a
                      >
                      <a
                        class="btn-secondary" style="backgroundColor: #f4516c; color: #fff"
                        v-if="link.status == 'Not Delivered'"
                        >{{ link.status }}</a
                      >
                      <a
                        class="btn-secondary" style="backgroundColor: #ffb822; color: #fff"
                        v-if="link.status == 'Outreaching'"
                        >{{ link.status }}</a
                      >
                      <a
                        class="btn-success" style="backgroundColor: #00b6cb; color: #fff"
                        v-if="link.status == 'In Writing'"
                        >{{ link.status }}</a
                      >
                      <a
                        class="btn-success" style="backgroundColor: #36a3f7; color: #fff"
                        v-if="link.status == 'Pending Review'"
                        >{{ link.status }}</a
                      >
                      <a
                        class="btn-success" style="backgroundColor: #9816f4; color: #fff"
                        v-if="link.status == 'Refund'"
                        >{{ link.status }}</a
                      >
                      <a
                        class="btn-success" style="backgroundColor: #36a3f7; color: #fff"
                        v-if="link.status == 'Link Submission'"
                        >{{ link.status }}</a
                      >
                      <a
                        class="btn-success" style="backgroundColor: red; color: #fff"
                        v-if="link.status == 'Cancel'"
                        >{{ link.status }}</a
                      >
                    </td>
                <td>
                    <div class="btn-box">
                        <!-- <a href="javascript:;"><i class="fas fa-comments"></i></a> -->
                        <a href="javascript:;"><i class="fas fa-eye" @click="openOrderSidebar(link.id)"></i></a>
                    </div>
                </td>
            </tr>
        </table>
    </form>
</template>

<script>
export default {
    props: ['links','hideOrderId'],
    mounted() {
    },
    
    watch: {
        links() {
            this.linksDetail = this.links;
        },
    },
    data() {
        return {
            linksDetail: [],
            addClass: '',
        };
    },
    methods: {
        openOrderSidebar(value) {
            var arr = this.linksDetail;
            arr = arr.filter(x => x.id === value);
            this.$emit('openOrderSidebar', arr[0])
        },

        redirectURL(url) {
            const pattern = /^(http:\/\/|https:\/\/)/i;
            const hasHttpOrHttps = pattern.test(url);

            let finalUrl = url;
            if (!hasHttpOrHttps) {
                finalUrl = 'https://' + url;
            }

            const newTab = window.open(finalUrl, '_blank');
            if (newTab) {
                newTab.focus();
            }
        },
        sortByColumn(column) {
            this.order = (this.order == 'asc') ? 'desc' : 'asc';
            this.$parent.getSortedResults(column,this.order);
        },
    },
}
</script>