<template>
    <div class="editor-dashboard-sec-2 approval-list-sec draft-order-sec">
      <div class="table-responsive">
        <table class="addon-table">
          <tr>
            <th>Package Name</th>
            <th>Total Links</th>
            <th>Product</th>
            <th>Category</th>
            <th>Amount</th>
            <th>Date</th>
          </tr>
          <tr v-for="pckg in packages" :key="pckg.id">
            <td>{{ pckg.package_name }}</td>
            <td>{{ pckg.total_links }}</td>
            <td>{{ pckg.product }}</td>
            <td>{{ pckg.category }}</td>
            <td>{{ numberFormat(pckg.amount, 'currency') }}</td>
            <td>{{ pckg.date }}</td>
          </tr>
        </table>
      </div>
    </div>
  </template>
  
  <script>
  import { numberFormat } from '@/prod.js';
  export default {
    props: ['packages'],
    components: {
    },
    mounted() {
  
    },
    data() {
      return {
        numberFormat,
        loading: false,
      };
    },
    methods: {  
    },
  };
  </script>
  