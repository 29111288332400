<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
   <router-link to="/login">Login</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <router-view />
</template>

<style lang="scss">
@import "assets/sass/style";
</style>
