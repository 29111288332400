<template>
  <div
    class="editor-dashboard-sec-2 approval-list-sec content-approval-dashboard"
  >
    <!-- <div class="table-responsive addon-tr-row">
              <table class="addon-table" v-if="content_Approvel.length > 0">
            
              </table>
            </div> -->
    <div class="table-responsive addon-td-row content_Approvel">
      <table class="addon-table" v-if="content_Approvel.length > 0">
        <tr v-if="content_Approvel.length > 0">
          <th>Link ID</th>
          <th>Destination Link</th>
          <th>Anchor Text</th>
          <th>Placement Opportunity</th>
          <th>Category</th>
          <th>Available Since</th>
          <th>Action</th>
        </tr>
        <tr v-for="approval in content_Approvel" :key="approval.id">
          <td>{{ approval.id }}</td>
          <td class="destination-text">
            <a
              @click="redirectURL(approval.destination_link)"
              v-b-tooltip.hover.top="approval.destination_link"
              ><span>{{ approval.destination_link }}</span>
              <i
                v-if="
                  approval.destination_link !== true
                    ? approval.destination_link
                    : approval.destination_link == undefined
                "
                class="fas fa-external-link-alt"
              ></i>
            </a>
          </td>
          <td>{{ approval.anchor_text }}</td>
          <td class="destination-text">
            <a
              @click="redirectURL(approval.placement_opportunity)"
              v-b-tooltip.hover.top="approval.placement_opportunity"
              ><span>{{ approval.placement_opportunity }}</span>
              <i
                v-if="
                  approval.placement_opportunity !== true
                    ? approval.placement_opportunity
                    : approval.placement_opportunity == undefined
                "
                class="fas fa-external-link-alt"
              ></i>
            </a>
          </td>
          <td>{{ approval.category }}</td>
          <td>{{ approval.pending_review_date }}</td>
          <td>
            <div class="btn-box">
              <router-link
                :to="`/order/${approval.id}?tab=content`"
                v-b-tooltip.hover
                title="View"
                ><i class="fas fa-eye"></i
              ></router-link>
            </div>
          </td>
        </tr>
      </table>
      <p class="article-text" v-if="content_Approvel.length == 0">
        You Have No Articles In Review.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: ["content_Approvel"],
  mounted() {},
  data() {
    return {
      clientstatus: {
        loaded: false,
        data: null,
        client: "all",
        show: false,
      },

      linksByDA: {
        loaded: false,
        data: null,
        client: "all",
        show: false,
      },

      linksByStatus: {
        loaded: false,
        data: null,
        client: "all",
        show: false,
      },
      tabs: [
        { chartdata: true },
        { clientstatus: false },
        { linksByDA: false },
      ],
      columns: [
        { label: "Destination Link", field: "destination_link" },
        { label: "Anchor Text", field: "anchor_text" },
        { label: "Domain Authority", field: "da" },
        { label: "Date Published", field: "published_at", sortable: false },
      ],
      pcolumns: [
        { label: "Destination Link", field: "destination_link" },
        { label: "Anchor Text", field: "anchor_text" },
        { label: "Placement Opportunity", field: "placement_opportunity" },
        {
          label: "Available Since",
          field: "pending_review_date",
          sortable: false,
        },
        { label: "Action", field: "action" },
      ],
    };
  },
  methods: {
    redirectURL(url) {
      const pattern = /^(http:\/\/|https:\/\/)/i;
      const hasHttpOrHttps = pattern.test(url);

      let finalUrl = url;
      if (!hasHttpOrHttps) {
        finalUrl = "https://" + url;
      }

      const newTab = window.open(finalUrl, "_blank");
      if (newTab) {
        newTab.focus();
      }
    },
  },
};
</script>
