import apiInstance from "./apiInstance";

export class PackageServices{

    static getAllPackagesAPI(payload){
        return apiInstance.get(`/api/packages-list/${payload.per_page}?page=${payload.page}`,{ params: payload });
    }

    static packageCountDataAPI(){
        return apiInstance.get('/api/get-packages-count');
    }
}