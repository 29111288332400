<template>
  <Datepicker
    id="example-datepicker"
    ref="datepickerMenu"
    v-model="order_date"
    class="mb-2 datepicker-custom-filter"
    :min="minDate"
    :max="maxDate"
    placeholder="mm-dd-yyyy - mm-dd-yyyy"
    :format="dateFormat"
    :config="options"
    range
    :enable-time-picker="false"
    @update:modelValue="selectRange(order_date, 'Custom Range')"
    auto-apply
    :position="position"
    :multi-calendars="!isMobile"
  >
    <template #left-sidebar>
      <div class="ranges-container">
        <button
          v-for="(value, label) in ranges"
          :key="label"
          @click="selectRange(value, label)"
          :class="{
            'range-button': true,
            'selected-range':
              isRangeSelected(value, label) || selectedRange === label,
          }"
        >
          {{ label }}
        </button>
      </div>
    </template>
  </Datepicker>
</template>

<script>
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

export default {
  name: "DatePicker",
  props: ["minDate", "maxDate", "options", "orderDate", "position"],
  components: {
    Datepicker,
  },
  watch: {
    orderDate: function (newVal) {
      this.order_date = newVal;
    },
  },
  async mounted() {
    this.checkIfMobile();
  },
  data() {
    return {
      isMobile: false,
      dateFormat: "MM-dd-yyyy",
      order_date: "",
      selectedRange: "",
      ranges: {
        Today: [
          new Date(
            Date.UTC(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate()
            )
          ),
          new Date(
            Date.UTC(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate()
            )
          ),
        ],
        Yesterday: [
          new Date(
            Date.UTC(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate() - 1
            )
          ),
          new Date(
            Date.UTC(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate() - 1
            )
          ),
        ],
        "Last 7 Days": [
          new Date(
            Date.UTC(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate() - 7
            )
          ),
          new Date(
            Date.UTC(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate()
            )
          ),
        ],
        "Last 30 Days": [
          new Date(
            Date.UTC(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate() - 30
            )
          ),
          new Date(
            Date.UTC(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate()
            )
          ),
        ],
        "This Month": [
          new Date(
            Date.UTC(new Date().getFullYear(), new Date().getMonth(), 1)
          ),
          new Date(
            Date.UTC(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate()
            )
          ),
        ],
        "Last Month": [
          new Date(
            Date.UTC(new Date().getFullYear(), new Date().getMonth() - 1, 1)
          ),
          new Date(
            Date.UTC(new Date().getFullYear(), new Date().getMonth(), 0)
          ),
        ],
        "This Year": [
          new Date(Date.UTC(new Date().getFullYear(), 0, 1)),
          new Date(
            Date.UTC(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate()
            )
          ),
        ],
        "Custom Range": [
          new Date(
            Date.UTC(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate()
            )
          ),
          new Date(
            Date.UTC(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate()
            )
          ),
        ],
      },
    };
  },
  methods: {
    selectRange(value, label) {
      const date = value;
      this.selectedRange =
        label === "" || label === "Custom Range" ? "Custom Range" : "";
      //   const val = this.activeRefVal;
      //   this.$refs.activeRefVal.closeMenu();

      if (this.$refs.datepickerMenu) {
        this.$refs.datepickerMenu.closeMenu();
      }
      this.$emit("getSearchData", date);
    },
    isRangeSelected(value, label) {
      return value === this.order_date || this.selectedRange === label;
    },
    checkIfMobile() {
      // If window width is 768px or less, it's considered mobile
      this.isMobile = window.innerWidth <= 768;
    },
  },
};
</script>
