import { LinkServices } from '@/services/linkServices';
export default {
    namespaced: true,

    actions:{

        async getAllLinksDataAction(_, payload){
            let res = await LinkServices.getAllLinksAPI(payload);
            let data = res.data;
            return data;
        }, 

        async getAllLinkStatusDataAction(_, payload){
            let res = await LinkServices.getAllLinkStatusAPI(payload);
            let data = res.data;
            return data;
        }, 

        async getAgenciesUsersDataAction(_, payload){
            let res = await LinkServices.getAgenciesUsersAPI(payload);
            let data = res.data;
            return data;
        }, 

        async getAllCategoryDataAction (){
            let res = await LinkServices.getCategoryAPI();
            let data = res.data;
            return data;
        }, 

        async getEditLinksDataAction (_, payload){
            let res = await LinkServices.getEditLinksDataAPI(payload);
            let data = res.data;
            return data;
        },
        async sendMessageDataAction (_, payload){
            let res = await LinkServices.sendMessageDataAPI(payload);
            let data = res.data;
            return data;
        },
        async downloadDataAction (_, payload){
            let res = await LinkServices.getdownloadDataAPI(payload);
            let data = res.data;
            return data;
        },
        async revisionDataAction (_, payload){
            let res = await LinkServices.revisionDataAPI(payload);
            let data = res.data;
            return data;
        },
        async approveDataAction (_, payload){
            let res = await LinkServices.approveDataAPI(payload);
            let data = res.data;
            return data;
        },
        
    }
}