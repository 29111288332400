<template>
  <div class="home">
    <LoaderComponent v-show="loading" />
    <HeaderView />
    <section
      class="dashboard-page-main-sec order-page-main-sec track-order-main-sec agency-design-grid-right draft-order-page-sec user-management"
    >
      <div class="row">
        <div class="col-md-12">
          <div class="text-heading-2">User Management</div>

          <div class="editor-dashboard-sec-2 approval-list-sec draft-order-sec">
            <div class="order-list-tabs">
              <div class="order-input-field">
                <!-- <div class="search-box">
                  <div class="form-row">
                    <div class="col-md-12">
                      <label>Search</label>

                      <div class="form-group">
                        <button class="search-button">
                          <i class="fas fa-search"></i>
                        </button>
                        <input type="text" class="form-control" id="formGroupExampleInput" v-model="search.all_invoice"
                          placeholder="Search all invoices" />
                      </div>
                    </div>
                  </div>
                </div> -->
                <div class="date-picker-box">
                  <div class="form-row">
                    <div class="col-md-6">
                      <label>By Name</label>
                      <b-form-input
                        id="input-2"
                        v-model="filter.name"
                        placeholder=""
                      ></b-form-input>
                    </div>
                    <div class="col-md-6">
                      <label>By Email</label>
                      <b-form-input
                        id="input-2"
                        v-model="filter.email"
                        placeholder=""
                      ></b-form-input>
                    </div>
                    <b-col md="12">
                      <div class="btn-box-list">
                        <a
                          href="javascript:;"
                          class="btn-1"
                          @click="getUserListSearch()"
                          >Search
                        </a>
                        <a
                          href="javascript:;"
                          class="btn-1 btn-2"
                          @click="resetFilter()"
                          >Reset
                        </a>
                      </div>
                    </b-col>
                  </div>
                </div>
                <div class="download-icon-list">
                  <div class="per-page-right-box">
                    <div class="select-item">
                      <select
                        @change="changePagination()"
                        v-model="filter.per_page"
                      >
                        <option>50</option>
                        <option>100</option>
                        <option>200</option>
                        <option>500</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="table-responsive">
              <table class="addon-table">
                <tr>
                  <th @click="sortByColumn('first_name')">
                    First Name <span><i class="fas fa-sort"></i></span>
                  </th>
                  <th @click="sortByColumn('last_name')">
                    Last Name <span><i class="fas fa-sort"></i></span>
                  </th>
                  <th @click="sortByColumn('email')">
                    Email <span><i class="fas fa-sort"></i></span>
                  </th>
                  <th @click="sortByColumn('created_at')">
                    Date Created <span><i class="fas fa-sort"></i></span>
                  </th>
                  <th>Actions</th>
                </tr>
                <tr v-for="user in user_list" :key="user.id">
                  <td>{{ user.first_name }}</td>
                  <td>{{ user.last_name }}</td>
                  <td>{{ user.email }}</td>
                  <td>{{ user.created_at }}</td>
                  <td colspan="2" class="action-btns">
                    <router-link :to="`/add-user/${user.user_id}`"
                      >Edit</router-link
                    >
                    <a
                      href="javascript:;"
                      @click="deleteButtonPopupUser(user.user_id)"
                      class="btn-danger"
                      >Remove</a
                    >
                    <!-- <a href="#" class="btn-secondary">Proceed Order</a> -->
                  </td>
                </tr>
              </table>
              <div class="total-records-list">
                <p>Showing {{ rows.length }} of {{ totalRecords }} records</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pagination-list">
        <!-- <ul>
        <li>
          <a href="#"><i class="fas fa-chevron-left"></i> Prev</a>
        </li>
        <li><a href="#" class="active">1</a></li>
        <li><a href="#">2</a></li>
        <li><a href="#">3</a></li>
        <li><a href="#">4</a></li>
        <li><a href="#">5</a></li>
        <li>
          <a href="#">Next <i class="fas fa-chevron-right"></i></a>
        </li>
      </ul> -->
        <paginate
          :click-handler="getUserList"
          :prev-text="'Prev'"
          :next-text="'Next'"
          :page-count="filter.last_page"
          :page-range="3"
          :margin-pages="2"
          :container-class="'className'"
          :records="filter.length"
          v-model="filter.page"
          :per-page="filter.per_page"
        >
        </paginate>
      </div>
    </section>
  </div>
</template>

<script>
import HeaderView from "@/components/layout/HeaderComponent.vue";
import LoaderComponent from "@/components/LoaderComponent.vue";
import Paginate from "vuejs-paginate-next";
// import Datepicker from '@vuepic/vue-datepicker';
import "@vuepic/vue-datepicker/dist/main.css";
import swal from "sweetalert2";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  components: {
    HeaderView,
    LoaderComponent,
    Paginate,
    // Datepicker,
  },
  mounted() {
    if (this.userData.role != "Agency") {
      this.$router.push("/dashboard");
    }
    this.getUserList();
  },
  data() {
    return {
      loading: false,
      date: new Date(),
      dateFormat: "dd-MM-yyyy",
      options: {
        format: "MM/DD/YYYY",
        useCurrent: false,
      },
      filter: {
        columnTo: "id",
        orderBy: "desc",
        per_page: 50,
        page: 1,
        last_page: 1,
        length: "",
        date_form: "",
        date_to: "",
        name: "",
        email: "",
      },
      paginationData: {},
      user_list: [],
      totalRecords: 0,
      rows: [],
    };
  },
  methods: {
    async getUserList() {
      try {
        let res = await this.$store.dispatch(
          "AgencyUsers/getUserListDataAction",
          this.filter
        );
        this.user_list = res.data;
        this.paginationData = res;
        this.totalRecords = res.meta.total;
        this.rows = res.data;
        this.filter.page = res.meta.current_page;
        this.filter.per_page = res.meta.per_page;
        this.filter.last_page = res.meta.last_page;
        this.filter.length = res.meta.total;
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },
    async deleteUser(delete_id) {
      try {
        let res = await this.$store.dispatch(
          "AgencyUsers/deleteUserDataAction",
          { id: delete_id },
          {
            root: true,
          }
        );
        if (res.success) {
          this.getUserList();
          this.loading = false;
          toast.success(res.message, { timeout: 3000 });
          this.$router.push("/users");
        }
      } catch (error) {
        this.loading = false;
        this.errors = error.response.data.errors;
      }
    },
    async deleteButtonPopupUser(id) {
      swal
        .fire({
          title: "Are you sure?",
          text: "On click delete your user will be removed from the list!",
          icon: "warning",
          width: 600,
          showCancelButton: true,
          confirmButtonColor: "#00b6cb",
          cancelButtonColor: "#ff0e52",
          confirmButtonText: "Delete",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.deleteUser(id);
          }
        });
    },
    resetFilter() {
      this.filter.name = "";
      this.filter.email = null;
      this.getUserList();
    },
    sortByColumn(column) {
      this.filter.orderBy = this.filter.orderBy == "asc" ? "desc" : "asc";
      this.getSortedResults(column, this.filter.orderBy);
    },
    getSortedResults(column, order) {
      this.filter.columnTo = column;
      this.filter.orderBy = order;
      this.getUserList();
    },
    changePagination() {
      this.filter.page = 1;
      this.getUserList();
    },
    getUserListSearch() {
      this.filter.page = 1;
      this.getUserList();
    },
  },
  computed: {
    userData() {
      const data = this.$store.getters["Auth/getUser"];
      return {
        role: data.role,
      };
    },
  },
};
</script>
