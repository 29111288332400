<template>
    <div class="home">
      <LoaderComponent v-show="loading" />
      <HeaderView />
      <section
        class="dashboard-page-main-sec order-page-main-sec track-order-main-sec agency-design-grid-right draft-order-page-sec domain-detail-page-sec destination-page-sec"
      >
        <div class="row">
          <div class="col-md-12">
            <div class="order-input-field">
            <div class="reports-heading">
              <div class="text-heading-2">Destination URL Report</div>
              <p>Track all your orders and links</p>
            </div>
          </div>

          </div>
          <div class="col-md-12">
            <label for="">Search by</label>
          <div class="destination-switch-box">
              <label class="switch">
                  <input type="checkbox" id="togBtn" @click="resetFilter"/>
                  <div class="slider round" @click="isVisible = !isVisible">
                    <span class="off" :class="{active: !isVisible}">Domain</span>
                    <span class="on" :class="{active: isVisible}">Destination URL</span>
                  </div>
                </label>
                  <div class="order-input-field ">
                  <div class="reports-heading select" v-if="!isVisible">
                  <!-- <b-form-select 
                    id="input-3" 
                    v-model="filter.domain"
                    :options="domains" 
                    @change="searchDomain"
                  >
                  </b-form-select> -->
                  <VueMultiselect
                    id="input-3"
                    placeholder="Select Domain"
                    v-model="filter.domain"
                    :options="domains"
                    :close-on-select="false" 
                    :clear-on-select="false" 
                    :multiple="true"
                  >
                  <template #selection="{ values, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} options selected</span></template>
                  </VueMultiselect>
                  </div>
                  <div class="search-field">
                    <div class="search-filter" v-if="isVisible">
                        <b-form-input
                              id="input-2"
                              v-model="filter.destination"
                              placeholder="www.example.com"
                              required
                        ></b-form-input>
                    </div>
                    <div class="btn-box-list">
                      <a
                        href="javascript:;"
                        class="btn-1"
                        @click="getDestinationList()"
                        >Search
                      </a>
                      <a
                        href="javascript:;"
                        class="btn-1 btn-2"
                        @click="resetFilter()"
                        >Reset
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            <div class="editor-dashboard-sec-2 approval-list-sec draft-order-sec">
              <div class="table-responsive">
                <table class="addon-table">
                  <tr>
                    <th @click="sortByColumn('domain')">
                      Domain<span><i class="fas fa-sort"></i></span>
                    </th>
                    <th @click="sortByColumn('total_destination_count')">
                      Total Links<span><i class="fas fa-sort"></i></span>
                    </th>
                    <th @click="sortByColumn('unique_destination_count')">
                      Unique URLs<span><i class="fas fa-sort"></i></span>
                    </th>
                    <th>Action</th>
                  </tr>
                  <tr v-for="destination in destinationData" :key="destination.domain">
                    <td class="destination-text">
                      <a @click="redirectURL(destination.domain)"
                        ><span>{{ destination.domain }}</span>
                      </a>
                    </td>
                    <td>{{ destination.total_destination_count }}</td>
                    <td>{{ destination.unique_destination_count }}</td>
                    <td>
                      <div class="btn-box">
                        <router-link
                          :to="`/destination-list/${destination.domain}`"
                          v-b-tooltip.hover.top="'View'"
                          ><i class="fas fa-eye"></i
                        ></router-link>
                      </div>
                    </td>
                  </tr>
                </table>
                <div class="total-records-list">
                  <p>Showing {{ rows.length }} of {{ totalRecords }} records</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pagination-list">
          <paginate
            :click-handler="getDestinationList"
            :prev-text="'Prev'"
            :next-text="'Next'"
            :page-count="last_page"
            :page-range="3"
            :margin-pages="2"
            :container-class="'className'"
            :records="length"
            v-model="page"
            :per-page="per_page"
          >
          </paginate>
      </div>
      </section>
    </div>
  </template>
  
  <script>
  import HeaderView from "@/components/layout/HeaderComponent.vue";
  import LoaderComponent from "@/components/LoaderComponent.vue";
  import VueMultiselect from 'vue-multiselect'
  import Paginate from "vuejs-paginate-next";

  export default {
    components: {
      HeaderView,
      LoaderComponent,
      Paginate,
      VueMultiselect
    },
    mounted() {
      this.domainList();
      this.getDestinationList();
    },
    data() {
      return {
        loading: false,
        isVisible: false,
        rows: [],
        totalRecords: 0,
        per_page: 50,
        page: 1,
        last_page: 1,
        length: "",
        filter: {
          s: "",
          e: "",
          order_date: "",
          columnTo: "domain",
          orderBy: "asc",
          domain: [],
          download: 'no',
          destination: ""
        },
        minDate: new Date(new Date().setMonth(new Date().getMonth() - 1)),
        maxDate: new Date(new Date().setMonth(new Date().getMonth() + 1)),
        options: {
          format: "MM/DD/YYYY",
          useCurrent: false,
        },
        destinationData: [],
        domains: [],
      };
    },
    methods: {
      getSearchData(value) {
        this.filter.order_date = value;
        this.getDestinationList();
      },
      async domainList() {
        try {
          let res = await this.$store.dispatch("Dashboard/getDomainListDataAction");
          res.clients.forEach((item) => {
            this.domains.push(item.text);
          });
        } catch (e) {
          this.loading = false;
        }
      },
      async getDestinationList() {
        this.loading = true;
        this.filter.s = this.filter.order_date ? this.filter.order_date[0] : "";
        this.filter.e = this.filter.order_date ? this.filter.order_date[1] : "";
        try {
          let res = await this.$store.dispatch(
            "AgencyReport/DestinationReportDataAction",
            { 
              filters: this.filter,
              per_page: this.per_page, 
              page: this.page 
            }
          );
          this.destinationData = res.data;
          this.totalRecords = res.total;
          this.rows = res.data;
          this.page = res.current_page;
          this.per_page = res.per_page;
          this.last_page = res.last_page;
          this.length = res.total;
          if(res.data.length == 1 && this.filter.destination != ''){
            localStorage.setItem('destinationLink', this.filter.destination);
            window.location = 'destination-list/' + res.data[0].domain;
          }
          this.loading = false;
        } catch (e) {
          this.loading = false;
        }
      },
      // searchDomain(domain){
      //   console.log(this.filter.domain);
      //   if(domain.length > 0){
      //     console.log('s1');
      //   }else{
      //     console.log('s3');
      //   }
      //   this.filter.domain = domain;
      //   this.getDestinationList();
      // },
      downloadFile() {
        var jsonObject = this.domain_data;
        var array =
          typeof jsonObject != "object" ? JSON.parse(jsonObject) : jsonObject;
  
        var Head = [
          [
            '"Domain Name"',
            '"Number Of Links Ordered Life Time"',
            '"Number Of Links Delivered %"',
            '"Number Of Links Pending %"',
          ],
        ];
  
        var selectedColumns = array.map(function (item) {
          return [
            '"' + item.domain + '"',
            '"' + item.total_links + '"',
            '"' + item.avg_delivered_links + '"',
            '"' + item.avg_pending_links + '"',
          ];
        });
  
        var csvRows = Head.concat(selectedColumns)
          .map(function (row) {
            return row.join(",");
          })
          .join("\n");
  
        var csvFile = new Blob([csvRows], {
          type: "text/csv",
        });
  
        var downloadLink = document.createElement("a");
        downloadLink.download = "Domain.csv";
        downloadLink.href = window.URL.createObjectURL(csvFile);
        downloadLink.style.display = "none";
        document.body.appendChild(downloadLink);
        downloadLink.click();
      },
      sortByColumn(column) {
        this.filter.orderBy = this.filter.orderBy == "asc" ? "desc" : "asc";
        this.getSortedResults(column, this.filter.orderBy);
      },
      getSortedResults(column, order) {
        this.filter.columnTo = column;
        this.filter.orderBy = order;
        this.getDestinationList();
      },
      resetFilter() {
        this.page = 1;
        this.filter.domain = [];
        this.filter.destination = "";
        this.getDestinationList();
      },
    },  
  };
  </script>
  