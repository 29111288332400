import { MissedOpportunityServices } from '@/services/MissedOpportunityServies';
export default {
    namespaced: true,

    actions:{

        async getMissedOpportunityDataAction (_, payload){
            let res = await MissedOpportunityServices.getMissedOpportunityDataAPI(payload);
            let data = res.data;
            return data;
        },

        async getopportunityCountAction (){
            let res = await MissedOpportunityServices.opportunityCountDataAPI();
            let data = res.data;
            return data;
        }, 
        
        async deleteOpportunityOrderAction (_, payload){
            let res = await MissedOpportunityServices.deleteOpportunityOrderAPI(payload);
            let data = res.data;
            return data;
        },

        async getMissedOpportunityStatusDataAction(_, payload){
            let res = await MissedOpportunityServices.getMissedOpportunityStatusAPI(payload);
            let data = res.data;
            return data;
        }, 

        async getAllCategoriesDataAction (){
            let res = await MissedOpportunityServices.getCategoriesAPI();
            let data = res.data;
            return data;
        }, 
    }
}