<template>
    <div class="total-price-table-box proceed-pay-box">
        <div class="summary-box">
            <table style="width: 100%">
                <tr class="bg-text">
                <td colspan="2"></td>
                <td>Qty</td>
                <td>Price</td>
                </tr>
                <tr v-if="da_20_count > 0 && !packageOrder">
                <td colspan="2">DA 20 or Below</td>
                <td>{{ da_20_count }}</td>
                <td>{{ numberFormat(da_20_total, "currency") }}</td>
                </tr>
                <tr v-if="da_30_count > 0 && !packageOrder">
                <td colspan="2">DA 30+</td>
                <td>{{ da_30_count }}</td>
                <td>{{ numberFormat(da_30_total, "currency") }}</td>
                </tr>
                <tr v-if="da_40_count > 0 && !packageOrder">
                <td colspan="2">DA 40+</td>
                <td>{{ da_40_count }}</td>
                <td>{{ numberFormat(da_40_total, "currency") }}</td>
                </tr>
                <tr v-if="da_50_count > 0 && !packageOrder">
                <td colspan="2">DA 50+</td>
                <td>{{ da_50_count }}</td>
                <td>{{ numberFormat(da_50_total, "currency") }}</td>
                </tr>
                <tr v-if="da_60_count > 0 && !packageOrder">
                <td colspan="2">DA 60+</td>
                <td>{{ da_60_count }}</td>
                <td>{{ numberFormat(da_60_total, "currency") }}</td>
                </tr>
                <tr>
                <td colspan="2">Total Links:</td>
                <td>{{ links.length }}</td>
                <td>{{ numberFormat(link_total, "currency") }}</td>
                </tr>
                <tr v-if="!packageOrder">
                <td colspan="2">Addons:</td>
                <td>{{ addon_count }}</td>
                <td>{{ numberFormat(addon_total, "currency") }}</td>
                </tr>
                <tr>
                <td colspan="2">Total:</td>
                <td colspan="2">
                    <b>USD {{ numberFormat(total, "currency") }}</b>
                </td>
                </tr>
                <tr v-if="showProceed">
                <td colspan="4" style="padding: 0">
                    <a
                    v-if="activeProduct"
                    href="javascript:;"
                    class="btn-3 diableButtonTooltip"
                    v-b-tooltip.hover.top="'Please fill all the fields'"
                    >Proceed to Pay</a
                    >
                    <a
                    v-else
                    href="javascript:;"
                    @click="submit"
                    class="btn-3"
                    :class="{ disabled: activeProduct }"
                    >Proceed to Pay</a
                    >
                </td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
import { numberFormat } from "@/prod.js";

export default {
    props: ['packageOrder','da_20_count','da_20_total','da_30_count','da_30_total','da_40_count','da_40_total','da_50_count',
    'da_50_total','da_60_count','da_60_total','links','link_total','addon_count','addon_total','total','activeProduct',
    'showProceed'
    ],
    data() {
        return {
            numberFormat,
        }
    },
    methods: {
        submit() {
            this.$emit("submit");
        },
    }
}
</script>