import apiInstance from "./apiInstance";

export class BillingServices{

    static getBillingDataAPI(payload){
        // return apiInstance.get(`/api/billing-list/${per_page}?page=${page}`,payload);
        return apiInstance.get(`/api/billing-list/${payload.per_page}?page=${payload.page}`,{ params: payload });
    }
    static getInvoiceDataAPI(val){
        if(val.download == 'invoice'){
            return apiInstance.get(`/api/invoice/${val.id}?download=invoice`,{responseType: "blob"});
        }else{
            return apiInstance.get(`/api/invoice/${val.id}`);
        }
    }

    static sendInvoiceEmailDataAPI(id){
        return apiInstance.get(`/api/send-invoice/${id}`);
    }

}