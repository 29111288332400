import apiInstance from "./apiInstance";

export class AuthServices{

    static createSession(){
        return apiInstance.get('/sanctum/csrf-cookie');
    }

    static loginAPI(payload){
        return apiInstance.post('/api/login',payload);
    }

    static signupAPI(payload){
        return apiInstance.post('/api/register',payload);
    }

    static forgetPasswordAPI(payload){
        return apiInstance.post('/api/reset-password-email',payload);
    }

    static resetPasswordAPI(payload){
        return apiInstance.post('/api/reset-password',payload);
    }

    static logoutAPI(){ 
        return apiInstance.post('/api/logout')
    }

    static isEmailVerified(payload){
        return apiInstance.post('/api/email-verification',payload);
    }

    static resendEmailVerificationAPI(payload){
        return apiInstance.post('/api/resend-verification-email',payload);
    }

     // agency user password Api
    static setPasswordAPI(payload){
        return apiInstance.post('/api/user-set-password', payload);
    }

    // agency user password Api
    static setAgencyPasswordAPI(payload){
        return apiInstance.post('/api/agency-set-password', payload);
    }

    static impersonateLoginAPI(payload){
        return apiInstance.post('/api/impersonate-login',payload);
    }

    static getUserDataAPI(payload){
        return apiInstance.get('/api/get-user-data',payload);
    }

    
}